import { assign } from "lodash";

const purple = "#6200EE";
const green = "#00FFC2";
const orange = "#ED6D52";

const barColors = [
  "#F2A93B",
  "#ED6D52",
  "#00007B",
  "#75FBFD",
  "#F9D949",
  "#06D8B2",
];

export const theme = {
  darkGreenFeatured: {
    line: assign({
      style: {
        data: {
          fill: "transparent",
          stroke: green,
          strokeWidth: 3,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "#fffffe" },
        tickLabels: {
          fill: "#fffffe",
          fontFamily: "SFMono",
          fontWeight: 500,
          fontSize: 10,
          padding: 10,
        },
        axisLabel: {
          fontSize: 8,
        },
        grid: {
          stroke: "none",
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontSize: 22,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#fffffe",
        },
      },
    }),
  },
  darkOrangeFeatured: {
    line: assign({
      style: {
        data: {
          fill: "transparent",
          stroke: orange,
          strokeWidth: 3,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "#fffffe" },
        tickLabels: {
          fill: "#fffffe",
          fontFamily: "SFMono",
          fontWeight: 500,
          fontSize: 10,
          padding: 10,
        },
        axisLabel: {
          fontSize: 8,
        },
        grid: {
          stroke: "#fffffe",
          strokeWidth: 0.5,
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontSize: 22,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#fffffe",
        },
      },
    }),
  },
  darkGreenFeaturedNoAxis: {
    line: assign({
      style: {
        data: {
          fill: "transparent",
          stroke: green,
          strokeWidth: 3,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "#fffffe" },
        axisLabel: {
          fontSize: 8,
        },
        grid: {
          stroke: "#fffffe",
          strokeWidth: 0.5,
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontSize: 22,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#fffffe",
        },
      },
    }),
  },
  darkOrangeFeaturedNoAxis: {
    line: assign({
      style: {
        data: {
          fill: "transparent",
          stroke: orange,
          strokeWidth: 3,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "#fffffe" },
        axisLabel: {
          fontSize: 8,
        },
        grid: {
          stroke: "#fffffe",
          strokeWidth: 0.5,
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontSize: 22,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#fffffe",
        },
      },
    }),
  },
  lightDefault: {
    bar: assign({
      style: {
        data: {
          fill: ({ index }: any) => barColors[index],
        },
      },
      labels: {
        fontSize: 12,
        padding: 20,
        fontFamily: "SF Mono",
        fontWeight: 500,
        // fill: "#74839d",
      },
    }),
    line: assign({
      style: {
        data: {
          fill: "transparent",
          stroke: purple,
          strokeWidth: 3,
        },
        labels: {
          fontSize: 12,
          padding: 20,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#74839d",
        },
      },
    }),
    pie: assign({
      style: {
        data: {
          fill: ({ index }: any) => barColors[index],
        },
        labels: {
          fontSize: 12,
          padding: 20,
          fontFamily: "SFMono",
          fontWeight: 500,
          fill: "#74839d",
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontFamily: "SF Mono",
          fontWeight: 500,
          fill: "#74839d",
          fontSize: 15,
          lineHeight: 16,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "none" },
        tickLabels: {
          padding: 10,
          stroke: "#767676",
          // fontFamily: "SF Mono",
          fontWeight: 500,
          fontSize: 12,
          lineHeight: 14,
        },
        axisLabel: {
          fontSize: 8,
        },
        grid: {
          stroke: "none",
        },
      },
    }),
  },

  customStyle: {
    bar: assign({
      style: {
        data: {
          fill: ({ index }: any) => barColors[index],
        },
        labels: {
          fontFamily: "SFPro",
          fontWeight: 500,
          fontSize: 8,
          padding: 15,
          fill: "#74839d",
        },
      },
    }),
    legend: assign({
      style: {
        title: {
          fontFamily: "SFPro",
          fontWeight: 600,
          // fill: "#74839d",
          fontSize: 10,
          padding: 10,
        },
      },
    }),
    axis: assign({
      style: {
        axis: { stroke: "none" },
        tickLabels: {
          padding: 120,
          margin: 0,
          fill: "#74839d",
          fontFamily: "SFPro",
          // fontFamily: "SF Mono",
          fontWeight: 500,
          fontSize: 8,
          // background: "red",
          textAnchor: "start",
          textTransform: "capitalize"
        },
        axisLabel: {
          fontSize: 10,
        },
        grid: {
          stroke: "none",
        },
      },
    }),
  },
};

export const barFlyoutPadding = { top: 3, bottom: 3, left: 8, right: 8 };
export const barFlyoutStyle = {
  stroke: "gray",
  strokeWidth: 1,
  fill: "#F8FAFC",
};