import {
  Button as BPButton,
  ButtonProps as BPButtonProps,
} from "@blueprintjs/core";

import "./Button.css";

export type ButtonProps = IButtonProps;
interface IButtonProps extends BPButtonProps {}

export default function Button(props: ButtonProps) {
  return <BPButton {...props} />;
}
