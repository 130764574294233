import { useState } from "react";
import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { UmojaPaymentWidgetHeader } from "../../__internal/PaymentWidgetHeader";
import { WalletSelectorInput } from "./inputs/WalletSelectorInput";

export type RecipientWallet = {
  projectId: string,
  groupName: string,
  groupTag: string,
  groupWalletCount: string
  groupId: number
};

export interface DisburseFromPanelProps {
  recipientWallets: any[];
  setMode: Function;
}

export const TargetGroupPanel = ({
  recipientWallets,
  setMode,
}: DisburseFromPanelProps) => {
  const { t } = useTranslation();
  const {
    value,
    // meta: { error },
    setValue,
  } = useField("programWalletIds", {
    validate: validateDisbursementToInput,
    defaultIsTouched: false,
  });

  const [activeGroup, setActiveGroup] = useState<RecipientWallet>(value);

  return (
    <>
      <UmojaPaymentWidgetHeader
        setMode={setMode}
        mode="disburse"
        headerTitle={t("select_target_group")}
      />
      <div className={`target-group__selector-wrapper`}>
        {recipientWallets.map((wallet) => {
          return (
            <WalletSelectorInput
              key={wallet.id}
              wallet={wallet}
              setValue={setValue}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
              settings={false}
              groupButton={false}
            />
          );
        })}
      </div>
    </>
  );
};

const validateDisbursementToInput = (value: RecipientWallet) => {
  if (!value) {
    return "A recipient wallet is required for a disbursement";
  }
  return false;
};
