import { useTranslation } from "react-i18next";
import { ResourceSettingCard } from "./resourcesCard/ResourceSettingCard";

export const ResourcesSettings = () => {
  const { t } = useTranslation();
  const platformNewsResource = [
    {
      name: `${t("management_status")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("umoja_commerce")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("umoja_wallet")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("umoja_card")}`,
      link: "https://www.google.com"
    }
  ];

  const legalResource = [
    {
      name: `${t("terms_of_service")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("privacy_policy")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("GDPR_privacy_policy")}`,
      link: "https://www.google.com"
    }
  ];

  const supportResource = [
    {
      name: `${t("emerging_impact_services")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("umoja_brand_kit")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("ecosystem_case_studies")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("help_center_support")}`,
      link: "https://www.google.com"
    },
    {
      name: `${t("careers")}`,
      link: "https://www.google.com"
    }
  ];
  return (
    <div className="setting__resources-card-container">
      <ResourceSettingCard title={`${t("platform_news")}`} resourceLink={platformNewsResource} />
      <ResourceSettingCard title={`${t("legal")}`} resourceLink={legalResource} />
      <ResourceSettingCard title={`${t("company_support")}`} resourceLink={supportResource} />
    </div>
  );
};
