import "./UmojaChartWidget.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { useTranslation } from "react-i18next";
import { formatDecimalNumber } from "../../../utils";

export interface UmojaDemographicInsightChartProps {
    data: any
}

const UmojaDemographicInsightChartV2 = ({ data }: UmojaDemographicInsightChartProps) => {
    const { t } = useTranslation();      
    return(
     <>
    <div className="demographic_insight_chart">
    <h2 style={{ fontSize: "18px", fontWeight: 500, paddingTop: "10px", paddingLeft: "10px" }}>{t("spending_by_gender")}</h2>
      <BarChart
        width={800}
        height={(data?.length * 30) + 50 || 30}
        data={data}
        layout="vertical"
        barGap={6}
        margin={{ top: 10, right: 20, left: 20, bottom: 15 }}
      >
        <XAxis type="number" axisLine={false} tickLine={false} hide />
        <YAxis
          type="category"
          dataKey="name"
          axisLine={false}
          tickLine={false}
          width={180}
          orientation="left"
        />
        <Tooltip formatter={(value: string) => formatDecimalNumber(value)} />
        <Bar
          dataKey="Female"
          stackId="a"
          fill="#28ceae"
          barSize={9}
          legendType="circle"
          isAnimationActive={false}
        />
        <Bar
          dataKey="Male"
          stackId="a"
          fill="#333333"
          barSize={9}
          legendType="circle"
          isAnimationActive={false}
        >
        <LabelList dataKey="total" position="right" formatter={(value: string) => formatDecimalNumber(value) } fontSize="14px" />
        </Bar>
        <Legend align="left" wrapperStyle={{ paddingTop: 20 }}  />
        </BarChart>
       </div>
       </>
    ) 
};

export default UmojaDemographicInsightChartV2;