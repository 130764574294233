import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

interface ConfirmPinInputProps {
  newPinError?: string | null;
 }
export const ConfirmPinInput = ({ newPinError }: ConfirmPinInputProps ) => {
  const { t } = useTranslation();
  const validateConfirmPin = (pin: string) => {
  if (pin.length < 1) {
    return t('pin_required');
  }
  if (pin.length < 4) {
    return t('pin_must_be_at_least');
  }
  if (!isValidPin(pin)) {
    return t('invalid_pin_format');
  }
  return false;
};
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("confirmPin", {
    validate: validateConfirmPin,
    defaultIsTouched: false,
  });

  return (
    <div className="input__wrapper">
      <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "2px" }}>{`${t('confirmPinCode')}`}</div>
      <TextInput
        {...getInputProps()}
        required
        name="confirmPassword"
        type="password"
        placeholder={`${t('confirm_pin')}`}
        style={{ marginBottom: "2px" }}
      />
      {isTouched && <span style={{ color: "red", marginTop: "0", fontSize: "9px", marginBottom: "5px" }}>
        {error}
        {!error && newPinError ? newPinError : ""}
      </span>}
    </div>
  );

 function isValidPin(pin: string) {
  const re = /^(\d{4})$/;
  return re.test(String(pin));
}
};