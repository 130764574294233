import { Intent, Tag } from "@blueprintjs/core";


export interface ITagProp {
    tags: string[];
    onRemove: any
    duplicateIndex: number
    minimal: boolean,
    disableRemove: boolean
}


function Tags({ tags, onRemove, duplicateIndex, minimal, disableRemove }: ITagProp) {
  return (
        <div style={{ width: "100%" }}>
           {tags.map((tag, index) =>

              <Tag
                key={index}
                onRemove={disableRemove ? undefined: () => onRemove(tag) }
                large={true}
                style={{ margin: '3px'}}
                intent={duplicateIndex === index ? Intent.DANGER: Intent.NONE}
                minimal={minimal}
              >
                {tag}
               </Tag>
               )
           }
        </div>
  );
}

export default Tags;
