import "./UmojaDropdownSelector.css";
import { MenuItem, Button } from "@blueprintjs/core";
import { Select, ItemRenderer } from "@blueprintjs/select";
import { PropagateLoader } from "react-spinners";

type Project = {
  id: number;
  name: string;
  code?: string
};

const ProjectSelect = Select.ofType<Project>();

const renderProject: ItemRenderer<Project> = (
  project,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={project.id}
      onClick={handleClick}
      text={project.name}
      className="project__select__menu-list_item"
    />
  );
};

export interface ProjectDropDownSelectProps {
  projects: Project;
}

export const ProjectDropDownSelect = ({ projects }: ProjectDropDownSelectProps) => {
  if (!projects) {
    return (
      <div className="project_select_loader">
        <PropagateLoader color={"#6200ee"} />
      </div>
    );
  }
  
  return (
    <ProjectSelect
      filterable={false}
      popoverProps={{
        minimal: true,
      }}
      items={[projects]}
      activeItem={projects}
      itemRenderer={renderProject}
      onItemSelect={(project, event) => {}}
    >
      <Button
        className="project__select-button"
        text={`${projects?.name} [${projects?.code}]`}
        alignText="left"
        rightIcon="caret-down"
      />
    </ProjectSelect>
  );
};
