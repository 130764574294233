import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

export const LastNameInput = () => {
  const { t } = useTranslation();
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("lastName", {
    validate: validatePassword,
    defaultIsTouched: false,
  });

  return (
    <div className="last-name-input-wrapper">
      <TextInput
        {...getInputProps()}
        required
        name="lastName"
        type="text"
        placeholder={`${t('enter_last_name')}`}
      />
      {isTouched && <p style={{ color: "red" }}>
        {error?.includes("required") && t("last_name_required")}
        {error?.includes("allowed") && t("only_letters_allowed")}
        {error?.includes("character") && t("character_is_too_short")}
      </p>}
    </div>
  );
};

const validatePassword = (lastName: string) => {
  const regex = /^[A-Za-z]+$/;
  if (!lastName) {
    return "Last name is required";
  }

  if (lastName.length < 2) {
    return "character is too short";
  }

  if (!lastName.match(regex)) {
    return "Only letters are allowed without spaces";
  }

  return false;
};
