import { useQuery } from "react-query";

import { getWallet } from "../services";

export default function useWallet(
  organizationId: string | number,
  walletId: string | number
) {
  return useQuery(
    ["wallet", organizationId, walletId],
    () => getWallet(organizationId, walletId),
    {
      initialData: {},
      enabled: !!organizationId && !!walletId,
    }
  );
}
