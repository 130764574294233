import { format, parseISO } from "date-fns";
import { OrganizationWallet } from "../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import { formattedFloatNumberWith2Decimals } from "../../utils";

const statusConfig: Record<string, string> = {
  RESOLVED_SUCCESS: "Completed",
  PENDING: "Pending",
  PROCESSING: "Processing",
  RESOLVED_FAILURE: "Failed",
  };
export const convertTransactionTableData = (
  data: any,
  wallet: OrganizationWallet | null,
  t: (arg0: string) => any | undefined) => {
  return data?.map((item: any) => { 
    if (wallet) {
      return {
        total: wallet.name === item.from_name ? `- ${formattedFloatNumberWith2Decimals(item.total)}` : formattedFloatNumberWith2Decimals(item.total),
        customer_name: wallet.type_id === 1 ? item.to_name : item.from_name,
        status: t(statusConfig[item.status]) || "Unknown",
        tsx_to: t(item.to_name),
        tsx_from: t(item.from_name),
        type: t(item.type) || "Unknown",
        timestamp: format(parseISO(item.timestamp), "P"),
        time: format(parseISO(item.timestamp), "p"),
      };
    }
    return null;
  });
};
