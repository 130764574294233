import { useField } from "react-form";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

export const ProjectTitleInput = () => {
  const { t } = useTranslation()
  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_title_validation");
  }
  return false;
};

  const { meta: { error, isTouched }, getInputProps } = useField("project_name", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  return (
    <li className="organization__form-input-list-item">
      <label>
        <Trans i18nKey="project_title" />
      </label>
       <div className="input_container">
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={t("enter_project_title")}
          name="projectName"
          className="input_field"
        />
        {isTouched && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

