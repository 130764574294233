import React  from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@blueprintjs/core";
import UmojaDialog from "../../components/Popups/UmojaDialog";
import { ReactSVG } from "react-svg";
import { HandlerFunction } from "@storybook/addon-actions";


interface DeleteGroupDialogProps {
  isOpen: boolean;
  handleClose: HandlerFunction;
  deleteGroup: HandlerFunction
}

export const DeleteGroupDialog = ({ isOpen, handleClose, deleteGroup }: DeleteGroupDialogProps) => {
  const { t } = useTranslation();

  return (
    <UmojaDialog
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseButtonShown
      title=""
      className={"delete_dialog_container"}
    >
        <div>
          <div className={"delete_dialog_content"}>
            <ReactSVG src={"/danger.svg"}  />
            <p style={{ width: "270px"}}>
              {t('delete_dialog_message')}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "30px",
            }}
          >
            <Button
              type="button"
              intent="none"
              text={t('cancel')}
              onClick={handleClose}
            />
            <Button
              type="button"
              intent="danger"
              text={t('delete')}
              style={{ marginLeft: "10px"}}
              onClick={deleteGroup}
            />
          </div>
        </div>
    </UmojaDialog>
  );
};