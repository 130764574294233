import { useState } from "react";
import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components";
import { formatDecimalNumber } from "../../../../../utils";

interface TargetParticipantEnrollmentInputProps {
  disabled: boolean;
  readOnly: boolean;
  project: any;
}

export const TargetParticipantEnrollmentInput = ({
  disabled,
  readOnly,
  project,
}: TargetParticipantEnrollmentInputProps) => {
  
  const { t } = useTranslation();
  // validate input as needed
const validateInput = (input: string = "") => {
  const validInput = /^[0-9]*$/;
  if (input.length < 1 || !input.toString().match(validInput)) {
    return t("only_valid_number_validation");
  }
  return false;
};

  
  const {
    meta: { error, isTouched },
    getInputProps,
    setValue,
  } = useField("target_participant_enrollment", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.project_details?.target_participant_enrollment
  });
  const [fieldState, setFieldState] = useState(
    `${project?.project_details?.target_participant_enrollment || ""}`
  );

  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/,/g, "");

    if (isNaN(value)) {
      setFieldState(value);
    } else {
      setFieldState(formatDecimalNumber(value));
    }
    setValue(value);
  };

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="target_participant_enrollment" />
      </label>
      <div>
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={
            readOnly
              ? project?.project_details?.target_participant_enrollment
              : "10000"
          }
          onChange={handleChange}
          value={fieldState}
          disabled={disabled}
          readOnly={readOnly}
          style={{ height: 40, width: 200 }}
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

