import "./OrganizationWallets.css";
import { format } from "date-fns";
import { useMemo, useRef, useEffect } from "react";
import Table from "../../components/Table/TransactionTable";
import { DateRange, DateRangePicker } from "@blueprintjs/datetime";
import { Button, Icon } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { TableInstance } from "react-table";
import {
  RecipientTransactionExplorerHeader,
  VendorTransactionExplorerHeader,
} from "./OrganizationWalletConfig";
import { convertTransactionTableData } from "./util";
import { OrganizationWallet } from "../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import {Trans, useTranslation} from "react-i18next";

export type WalletTransaction = {
  total: number;
  customer_name: string;
  status: string;
  type: string;
  timestamp: any;
};

export interface OrganizationWalletDrawerContentProps {
  wallet: OrganizationWallet | null;
  data: any;
  rowData: any[];
  setRowData: Function;
  timeframe: DateRange;
  setTimeframe: Function;
}

export const OrganizationWalletDrawerContent = ({
  wallet,
  data,
  rowData,
  timeframe,
  setTimeframe,
  setRowData,
}: OrganizationWalletDrawerContentProps) => {
  
  const tableInstance = useRef<TableInstance>(null);
  const transactionColumns = useMemo(
    () =>
      wallet?.type_id === 1
        ? RecipientTransactionExplorerHeader
        : VendorTransactionExplorerHeader,
    [wallet?.type_id]
  );
  const { t } = useTranslation();

  const formattedTransactionColumns = transactionColumns.map( column  => { column.Header = t(column.Header); return column });
  const startDate = timeframe[0];
  const endDate = timeframe[1];
  useEffect(() => {
    const convertedTableData = convertTransactionTableData(data, wallet, t);
    //@ts-ignore "ref can be null"
    const newRows = tableInstance?.current?.filterTypes.customTimeframeFilter(
      convertedTableData,
      "timestamp",
      timeframe
    );
    setRowData(newRows);
  }, [data, setRowData, timeframe, wallet, t]);
  
 
  return (
    <div className="organization__wallet-explorer-table-wrapper">
      <div className="organization__wallet-explorer-table-header">
        <h2>
          <Trans i18nKey="transaction_explorer" />
        </h2>
        <div>
          <Popover2
            disabled={!wallet?.enrolled_at}
            className="wallet__timeframe-select"
            interactionKind="click"
            content={
              <div style={{ padding: "10px"}}>
                <DateRangePicker
                    allowSingleDayRange
                    defaultValue={timeframe}
                    value={timeframe}
                    onChange={(selectedDates: DateRange) => {
                      setTimeframe(selectedDates);
                    }}
                    highlightCurrentDay
                    minDate={wallet?.enrolled_at ? new Date(wallet?.enrolled_at!) : new Date()}
                    maxDate={new Date()}
              />
              <Button text={t("reset")} className={"reset_button"} onClick={() => setTimeframe([null, null])}/>
              </div>
            }
          >
            <Button
              disabled={!wallet?.enrolled_at}
              minimal
              icon={
                <Icon iconSize={20} icon="calendar" title="Select Timeframe" />
              }
              text={
                startDate && endDate
                  ? `${format(startDate, "MM/dd/yyyy")}-${format(
                      endDate,
                      "MM/dd/yyyy"
                    )}`
                  : "Select Timeframe"
              }
            />
          </Popover2>
        </div>
      </div>
      <div className="wallet__table-wrapper">
        <Table
          columns={formattedTransactionColumns}
          data={rowData}
          timeframe={timeframe}
          ref={tableInstance}
          onRowClick={() => null}
        />
      </div>
    </div>
  );
};
