export async function getOrganizations() {
  const response = await fetch(
    process.env.REACT_APP_FIND_ORGANIZATIONS_ENDPOINT as string,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getOrganization(organizationId: string | number) {
  const endpoint = `${process.env.REACT_APP_FIND_ORGANIZATIONS_ENDPOINT}/${organizationId}`;
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

  const data = await response.json();
  return data;
}

export async function getAccounts(organizationId: string | number) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/accounts`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getAccount() {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/me`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getOrganizationPrograms(organizationId: string | number) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/programs`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

