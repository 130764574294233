import "./UmojaChartWidget.css";
import {
  VictoryBar,
  VictoryGroup,
  VictoryLegend,
  VictoryAxis,
  DomainPaddingPropType,
  PaddingProps,
} from "victory";
import { Classes } from "@blueprintjs/core";

export interface UmojaBarChartWidgetProps {
  theme: any;
  data: any;
  title: string;
  padding: PaddingProps;
  domainPadding: DomainPaddingPropType;
  tickValues: any[];
}

export const UmojaBarChartWidget = ({
  theme,
  data,
  title,
  padding,
  domainPadding,
  tickValues,
}: UmojaBarChartWidgetProps) => {
  if (!data) {
    return <div className={`chart__widget-bar-wrapper ${Classes.SKELETON}`} />;
  }

  return (
    <div className={`chart__widget-bar-wrapper`}>
      <VictoryGroup
        data={data}
        theme={theme}
        padding={padding}
        domainPadding={domainPadding}
        horizontal
      >
        <VictoryLegend x={0} y={10} title={title} />
        <VictoryAxis tickValues={tickValues} />
        <VictoryBar
          data={data}
          labels={({ datum }) => datum.y}
          barWidth={({ index }: any) => index * 2 + 20}
        />
      </VictoryGroup>
    </div>
  );
};

export default UmojaBarChartWidget;
