import "./RedeemInvite.css";
import { useState } from "react";
import { useForm } from "react-form";
import { Card } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router";
import * as qs from "query-string";
import { Button } from "../../components";
import { PasswordInput } from "./inputs/PasswordInput";
import { ConfirmPasswordInput } from "./inputs/ConfirmPasswordInput";
import LoginLayout from "../../components/LoginLayout";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { useTranslation } from "react-i18next";
import { FirstNameInput } from "./inputs/FirstNameInput";
import { LastNameInput } from "./inputs/LastNameInput";

const RedeemInviteScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { org, code, email } = qs.parse(location.search);

  const [loading, setLoading] = useState(false);

  const RedeemInviteInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_REDEEM_INVITE_ENDPOINT as string,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              org,
              code,
              email,
              first_name: values.firstName,
              last_name: values.lastName,
              password: values.password
            }),
          }
        );

        if (response.ok) {
          setLoading(false);

          UmojaToast.show({
            message: `${t("successful")}`,
            intent: "success",
          });

          history.push("/");
        } else {
          setLoading(false);

          UmojaToast.show({
            message: `${t("error_submitting_request")}`,
            intent: "danger",
            icon: "error",
          });
        }

      } catch (error) {
        UmojaToast.show({
          message: `${t("error_submitting_request")}`,
          intent: "danger",
          icon: "error",
        });
        setLoading(false);
      }
    },
    // debugForm: true,
  });

  let passwordValue = RedeemInviteInstance.getFieldValue("password");
  let confirmPasswordValue = RedeemInviteInstance.getFieldValue("confirmPassword");

  const { meta } = RedeemInviteInstance;

  return (
    <LoginLayout>
      <Card className="redeem-invite-card">
        <div className="redeem-invite-form-wrapper">
          <RedeemInviteInstance.Form>
            <h1 className="redeem-invite-form-header">{t("redeem_invite")}</h1>
            <div className="redeem-invite-form__container">
              <div className="redeem-invite__input_box">
                <div className="redeem-invite-label">{t("first_name")}</div>
                <FirstNameInput />
              </div>
              <div className="redeem-invite__input_box">
                <div className="redeem-invite-label">{t("last_name")}</div>
                <LastNameInput />
              </div>
            </div>

            <div className="redeem-invite-form__container">
              <div className="redeem-invite__input_box">
                <div className="redeem-invite-label">{`${t('password')}`}</div>
                <PasswordInput />
              </div>
              <div className="redeem-invite__input_box">
                <div className="redeem-invite-label">{`${t('confirm_password')}`}</div>
                <ConfirmPasswordInput
                  passwordError={passwordValue !== confirmPasswordValue ? t("password_must_match") : null }
                />
              </div>
            </div>

            <div>
            </div>
            <div className="redeem-invite-button-wrapper">
              <Button
                className="redeem-invite-button"
                type="submit"
                disabled={
                  !(meta.canSubmit && meta.isValid && meta.isTouched && (passwordValue === confirmPasswordValue))
                }
                loading={loading}
                text={`${t('submit')}`}
                intent="primary"
              />
            </div>
          </RedeemInviteInstance.Form>
        </div>
      </Card>
    </LoginLayout>
  );
};

export default RedeemInviteScreen;
