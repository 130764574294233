import { format, parseISO } from "date-fns";
import { MouseEventHandler, useState } from "react";
import { DateRange } from "@blueprintjs/datetime";
import { useParams } from "react-router-dom";
import useTransactions from "../../hooks/useTransactions";

import { OrganizationWalletDrawerContent } from "./OrganizationWalletDrawerContent";
import { OrganizationWalletDrawerHeader } from "./OrganizationWalletDrawerHeader";
import { OrganizationWalletInfo } from "./OrganizationWalletInfo";
import { Spinner } from "@blueprintjs/core";
import { useCards } from "../../hooks";
import useWallet from "../../hooks/useWallet";
import { Organization } from "./OrganizationWallets";
import { formattedFloatNumberWith2Decimals } from "../../utils/formatNumbers";
import SingleDisbursementDialog from "../SingleDisbursement/SingleDisbursementDialog";
import { useTranslation } from "react-i18next";
import { mapCurrencySign } from "../../utils/currencyMap";

export interface OrganizationWalletDrawerProps {
  organization: Organization;
  dismissModal: MouseEventHandler<HTMLButtonElement>;
}

export const OrganizationWalletDrawer = ({
  organization,
  dismissModal,
}: OrganizationWalletDrawerProps) => {
  const { organizationId, walletId } = useParams<{
    organizationId: string;
    walletId: string;
  }>();
  const transactions = useTransactions(
    parseInt(organizationId, 10),
    parseInt(walletId, 10)
  );
  const wallet = useWallet(organizationId, walletId);
  const cards = useCards(organizationId);
  const [openDisburseDialog, setOpenDialog] = useState(false);

  const participantCard = wallet.data.card_number;

  const { data, isFetching } = useTransactions(
    parseInt(organizationId),
    parseInt(walletId)
  );

  const { t } = useTranslation();

  const [rowData, setRowData] = useState(data);

  const handleCloseDisburseDialog = () => {
    setOpenDialog(!openDisburseDialog);
  }

  const handleOpenDisburseDialog = () => {
    setOpenDialog(true);
  }
  const prettifiedData = prettifyData(data, wallet, t);
  
  const [timeframe, setTimeframe] = useState<DateRange>([null,null]);

  if (!wallet.data || !cards.data || !transactions.data) {
    return null;
  }

  return (
    <>
      <SingleDisbursementDialog
        showSingleDisbursementDialog={openDisburseDialog}
        toggleSingleDisbursementDialog={handleCloseDisburseDialog}
        wallet={wallet}
      />
      <OrganizationWalletDrawerHeader
        rowData={prettifiedData}
        wallet={wallet.data}
        organization={organization}
        dismissModal={dismissModal}
        cards={cards.data}
        card={participantCard}
        handleOpenDisburseDialog={handleOpenDisburseDialog}
      />
      <OrganizationWalletInfo
        transactions={transactions.data}
        wallet={wallet.data}
        card={participantCard}
        currency={mapCurrencySign[organization?.currency!]}
      />
      {isFetching ? (
        <div className="transactions__loading_indicatior">
          <Spinner />
        </div>
      ) : (
        <OrganizationWalletDrawerContent
          wallet={wallet.data}
          data={data}
          rowData={rowData}
          setRowData={setRowData}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
        />
      )}
    </>
  );
};

const statusConfig: Record<string, string> = {
  RESOLVED_SUCCESS: "Completed",
  PENDING: "Pending",
  PROCESSING: "Processing",
  RESOLVED_FAILURE: "Failed",
};

const dateFormat = "MMMM dd, hh:mm a";
const prettifyData = (data: Record<string, any>[], wallet: any, t: (arg0: string) => any | undefined) => {  
  return data.map((tsx) => {
    return {
      total: wallet.data.umoja_wallet_id === tsx.from_umoja_wallet_id ? `-${formattedFloatNumberWith2Decimals(tsx.total)}` : formattedFloatNumberWith2Decimals(tsx.total),
      id: tsx.id,
      customer_name: tsx.to_name,
      tsx_from: tsx.from_name,
      tsx_to: tsx.to_name,
      status: t(statusConfig[tsx.status]) || "Unknown",
      type: t(tsx.type) || "Unknown",
      timestamp: format(parseISO(tsx.timestamp), dateFormat),
    };
  });
};



export default OrganizationWalletDrawer;
