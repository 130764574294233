import "./ActivityExplorer.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { subDays } from "date-fns";

import {
  useProjects,
  useProjectTransactionsHistory,
  useWallets,
} from "../../hooks";
import { theme } from "../../themes/chartTheme";
import {
  findSum,
  selectTimeframe,
  timeframedOfflineTransactions,
  timeframedTransactionAmounts,
} from "./util";
import { FeaturedChartSkeletonLoader } from "./__internal/FeaturedChartSkeletonLoader";
import { ChartData } from "./__internal/ChartData";
import { ChartArea } from "./__internal/ChartArea";
import { ChartSelect } from "./__internal/ChartSelect";
import { useOrganizationId } from "../../hooks/useOrganizationId";
import { mapCurrencySign } from "../../utils/currencyMap";

type stateTimeframeDate = {
  start: Date;
  end: Date;
};

export const FeaturedCharts = () => {
  const selectedOrganizationId = useOrganizationId();
  const { organizationId } = useParams<{
    organizationId: string;
  }>();
  const projects = useProjects();
  const wallets = useWallets(organizationId);
  const projectId = projects?.data?.programs?.find(
    (data: any) => data.organization_id === +selectedOrganizationId
  );
  
  // const spendingPattern = offlineOnline(activityStats?.data)
  const transactionHistory = useProjectTransactionsHistory(
    organizationId,
    projectId?.id
  );

  const [chartId, setChartId] = useState("primary");

  const resolvedTransactions = transactionHistory.data.transactions.filter(
    (data: any) => data.status === "RESOLVED_SUCCESS"
  );

  let sum = findSum(resolvedTransactions);

  let currentTime = new Date();
  let min = subDays(currentTime, 365 * 2);
  const [
    selectedTimeframe,
    setSelectedTimeframe,
  ] = useState<stateTimeframeDate>({
    start: min,
    end: currentTime,
  });

  if (!transactionHistory.isFetched && !wallets.isFetched) {
    return <FeaturedChartSkeletonLoader />;
  }

  const offlineTransactionsGraphData = timeframedOfflineTransactions({
    data: transactionHistory.data,
    timeframe: selectedTimeframe,
  });

  const transactionsAmountGraphData = timeframedTransactionAmounts({
    data: transactionHistory.data,
    timeframe: selectedTimeframe,
  });

  const handleSelectTimeFrame = (timeframeId: string) => {
    let selectedTimeframe = selectTimeframe(timeframeId);
    setSelectedTimeframe(selectedTimeframe);
  };

  return (
    <div className="featured__charts-outer-wrapper">
      <div className="featured__charts-header-area">
        <ChartSelect toggleChart={setChartId} projectId={projectId?.id} />
        <ChartData
          totalProgramVolume={sum}
          totalEnrolled={wallets.data.length}
          totalMerchants={
            wallets.data.filter((wallet: any) => wallet.type_id === 2).length
          }
          selectTimeframe={handleSelectTimeFrame}
          currency={mapCurrencySign[projectId?.project_currency]}
        />
      </div>
      {chartId === "primary" && transactionHistory.status === "success" && (
        <ChartArea
          theme={theme.darkGreenFeatured}
          graphData={transactionsAmountGraphData}
          timeframe={selectedTimeframe}
          currency={mapCurrencySign[projectId?.project_currency]}
        />
      )}
      {chartId === "secondary" && transactionHistory.status === "success" && (
        <ChartArea
          theme={theme.darkOrangeFeatured}
          graphData={offlineTransactionsGraphData}
          timeframe={selectedTimeframe}
          currency={mapCurrencySign[projectId?.project_currency]}
        />
      )}
    </div>
  );
};
