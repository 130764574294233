import { isWithinInterval } from "date-fns";

export const customTimeframeFilterFn = (
  rows: object[],
  id: string,
  filterValue: any
) => {
     if(!filterValue[0] || !filterValue[1]){
      return rows
     }
     if(!filterValue[0] && !filterValue[1]){
      return rows
     }
    const result = rows.filter((row: any) => {
        return isWithinInterval(new Date(row[id]), { start: filterValue[0], end: filterValue[1] ? filterValue[1] : new Date()})
    })
    return result
};

customTimeframeFilterFn.autoRemove = (val: any) => !val;
