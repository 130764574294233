import "./UmojaPaymentWidget.css";
import {  useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-form";
import { UmojaPaymentWidgetFooter } from "./__internal/PaymentWidgetFooter";

import { TransactionHistoryPanel } from "./panels/TransactionHistoryPanel";
import { DisbursementPanel } from "./panels/DisbursementPanel";
import { TargetGroupPanel } from "./panels/TargetGroupPanel";
import { PreviewPanel } from "./panels/PreviewPanel";
import { RecipientWallet, Project } from "../../../screens/Home/GlobalOperations";
import { WalletPanel } from "./panels/WalletPanel";
import { UmojaToast } from "../../Popups/UmojaToast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

export type OrganizationWallet = {
  id: number;
  type: string;
  type_id: number;
  program_code: string;
  phone_number: string;
  name: string;
  enrolled_at: Date;
  umoja_wallet_id: number;
  age_group: number;
  pin_code?: number;
  locked: boolean;
  balance: number;
  usd_balance: number;
  currency: string;
  card_number: string
};

export interface UmojaPaymentWidgetProps {
  wallets: RecipientWallet[];
  disbursementHistory: any[];
  disabled: boolean;
  masterWalletBalance: string;
  project: Project | { id: string};
  disbursementWallets?: number[] | []
  setSelectedGroup: Function;
  currency: string;
}

export type UmojaPaymentWidgetModes =
  | "disburse"
  | "preview"
  | "wallet"
  | "history"
  | "target_group"

const UmojaPaymentWidget = ({
  wallets,
  disbursementHistory,
  disabled,
  masterWalletBalance,
  project,
  disbursementWallets,
  setSelectedGroup,
  currency,
}: UmojaPaymentWidgetProps) => {
  const [mode, setMode] = useState<UmojaPaymentWidgetModes | "">("wallet");
  const [loading, setLoading] = useState(false);
  const { organizationId } = useParams<{ organizationId: string }>();

  const QueryClient = useQueryClient()
  const { t } = useTranslation()

  const defaultValues = useMemo(
    () => ({
      amount: 0,
      programWalletIds: {}
    }),
    []
  );

  const PaymentWidgetForm = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setLoading(true);
      // handle api here
      const response = await fetch(
        `${process.env.REACT_APP_DISBURSEMENT_ENDPOINT}/${organizationId}/project/${project.id}/disbursements/` as string,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            amount: values.amount,
            program_wallet_ids: disbursementWallets
          }),
        }
      );

      if (!response.ok) {
        UmojaToast.show({
          message: t("failed_transaction"),
          intent: "danger",
          icon: "issue",
        });
        setLoading(false);
        return;
      }

      setLoading(false);
      UmojaToast.show({
        message: t("success_transaction"),
        intent: "success",
        icon: "tick",
      });
      await QueryClient.invalidateQueries("wallet")
      await QueryClient.invalidateQueries("transactions")
      await QueryClient.invalidateQueries("organizations")
      await QueryClient.invalidateQueries("programStats")
      instance.reset();
      setMode("disburse");

    },
    defaultValues,
    // debugForm: true,
  });

  const { values } = PaymentWidgetForm;
  setSelectedGroup((values?.programWalletIds as any)?.groupId)

  const showPanelFooter = ["wallet", "disburse", "history"]

  return (
    <PaymentWidgetForm.Form className="payment__widget-wrapper">
      <div className="payment__widget-container">
        {mode === "wallet" && (
          <WalletPanel balance={masterWalletBalance || "0.00"} setMode={setMode} currency={currency} wallets={wallets}/>
        )}
        {mode === "disburse"  &&  (
          <DisbursementPanel
            setMode={setMode}
            values={values}
            balance={masterWalletBalance || "0.00"}
            currency={currency}
            organizationId={organizationId}
          />
        )}
        {mode === "history" &&  (
          <TransactionHistoryPanel listItems={disbursementHistory} currency={currency} />
        )}
        {mode === "target_group" &&  (
          <TargetGroupPanel recipientWallets={wallets} setMode={setMode} />
        )}
        {mode === "preview" &&  (
          <PreviewPanel
            loading={loading}
            setMode={setMode}
            balance={masterWalletBalance || "0.00"}
            currency={currency}
          />
        )}
      </div>
      {showPanelFooter.includes(mode) && <UmojaPaymentWidgetFooter
        mode={mode}
        setMode={setMode}
        disabled={disabled}
      />}
    </PaymentWidgetForm.Form>
  );
};

export default UmojaPaymentWidget;
