import "../../OrganizationWallets.css";
import { MenuItem } from "@blueprintjs/core";
import { useField } from "react-form";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import { useTranslation } from "react-i18next";

export type UmojaCard = {
  id?: number;
  number: string;
  organization_id?: number;
  program_wallet_id?: null | number;
};

const CardSuggest = Suggest.ofType<any>();

export const CardSelectInput = ({ orgCards }: any) => {
  const CardSelectInputFieldInstance = useField("umoja_card_number", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });
   const { t } = useTranslation()
  const { meta, getInputProps, setValue } = CardSelectInputFieldInstance;

  return (
    <>
      <CardSuggest
        {...getInputProps()}
        className="card__registration-number_input"
        noResults={<MenuItem disabled={true} text={t("no_available_cards")} />}
        defaultSelectedItem=""
        resetOnClose
        initialContent={null}
        items={orgCards}
        itemRenderer={renderValidCards}
        itemListPredicate={filterCardsByQuery}
        inputValueRenderer={(item: UmojaCard) => {
          return prettifyCardNumber(item.number);
        }}
        onItemSelect={(item: UmojaCard) => setValue(item.number)}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
        }}
        inputProps={{
           placeholder: t("search")
        }}
      ></CardSuggest>
      {meta.isTouched && (
        <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
          {meta.error}
        </p>
      )}
    </>
  );
};

const validateInput = (input: string) => {
  if (!input) {
    return "Card number is required";
  }
  return false;
};

const renderValidCards: ItemRenderer<any> = (
  card: any,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  const prettifiedCardString = prettifyCardNumber(card.number);

  return (
    <MenuItem
      key={card.id}
      onClick={handleClick}
      text={prettifiedCardString}
      icon="credit-card"
    />
  );
};

const filterCardsByQuery = (query: string, items: UmojaCard[]) => {
  return items
    .filter((item) => {
      return item.number.includes(query);
    })
    .filter((_, index) => {
      return index <= 10;
    });
};

// split string into 4 character chunks for readability
const prettifyCardNumber = (cardNumber: number | string) => {
  const cardString = cardNumber.toString();
  const chunks = new Array(4);
  for (let i = 0, o = 0; i < 4; ++i, o += 4) {
    chunks[i] = cardString.substr(o, 4);
  }

  return chunks.join("-");
};
