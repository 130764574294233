import { useState } from "react";

import { LoginForm } from "./LoginForm";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";
import LoginLayout from "../../components/LoginLayout";

function LoginScreen() {
  const [forgotPasswordState, setForgotPasswordState] = useState(false);

  return (
    <LoginLayout>
      <>
        {!forgotPasswordState &&
          <LoginForm
            setForgotPasswordState={setForgotPasswordState}
          />}
        {forgotPasswordState &&
          <ForgotPassword
            setForgotPasswordState={setForgotPasswordState}
          />}
      </>
    </LoginLayout>
  );
}
export default LoginScreen;
