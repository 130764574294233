import { useField } from "react-form";
import { TextInput } from "../../../components";
import { Trans, useTranslation } from "react-i18next";
import { FormInputType, FormValuesType } from "../../Settings/Settings";


export const ConfirmPasswordInput = () => {
  const { t } = useTranslation();
  const validateInput = (confirmPassword: string, instance: FormInputType) => {
    const formValues = instance.form.values as FormValuesType;
    const { password } = formValues;
      if (password.length < 1) {
        return t("password_required");
      }
      if (password.length < 8) {
        return t("password_length_validation");
      }
      if (password !== confirmPassword) {
        return t("confirm_new_password");
      }
      return false;
    }
  const ConfirmPasswordInputInstance = useField("confirm_password", {
     validate: validateInput ,
     defaultIsTouched: false,
     defaultValue: "",
  });

  const { meta: { error, isTouched }, getInputProps } = ConfirmPasswordInputInstance;

  return (
    <li className="organization__form-input-list-item">
      <label>
        <Trans i18nKey="confirm_password" />
      </label>
      <div className="input_container">
        <TextInput
          {...getInputProps()}
            type="password"
            name="confirm-password"
            placeholder={`${t('confirm_password')}`}
            className="input_field"
        />
        {isTouched && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
        </div>
    </li>
  );
};
