
export const WalletExplorerHeader = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone Number",
    accessor: "phone_number",
  },
  {
    Header: "Project",
    accessor: "project_name",
  },
  {
    Header: "Enrollment Date",
    accessor: "enrolled_at",
  },
];

const TOTAL_HEADER = "Total";
const TOTAL_KEY = "total";
const RECIPIENT_HEADER_TO = "from";
const RECIPIENT_HEADER_FROM = "To"
const VENDOR_HEADER_TO = "from";
const VENDOR_HEADER_FROM = "To";
const CUSTOMER_KEY_TO = "tsx_from";
const CUSTOMER_KEY_FROM = "tsx_to";
const TRANSACTION_STATUS_HEADER = "status";
const TRANSACTION_STATUS_KEY = "status";
const TRANSACTION_TYPE_HEADER = "txn_type";
const TRANSACTION_TYPE_KEY = "type";
const TIMESTAMP_HEADER = "timestamp";
const TIMESTAMP_KEY = "timestamp";
const TIME_HEADER = "time";
const TIME_KEY = "time";

export const RecipientTransactionExplorerHeader = [
  {
    Header: TOTAL_HEADER,
    accessor: TOTAL_KEY,
  },
  {
    Header: VENDOR_HEADER_TO,
    accessor: CUSTOMER_KEY_TO,
  },
  {
    Header: VENDOR_HEADER_FROM,
    accessor: CUSTOMER_KEY_FROM,
  },
  {
    Header: TRANSACTION_STATUS_HEADER,
    accessor: TRANSACTION_STATUS_KEY,
  },
  {
    Header: TRANSACTION_TYPE_HEADER,
    accessor: TRANSACTION_TYPE_KEY,
  },
  {
    Header: TIMESTAMP_HEADER,
    accessor: TIMESTAMP_KEY,
    filter: "customTimeFrameFilterFn",
  },
  {
    Header: TIME_HEADER,
    accessor: TIME_KEY,
  }
];

export const VendorTransactionExplorerHeader = [
  {
    Header: TOTAL_HEADER,
    accessor: TOTAL_KEY,
  },
  {
    Header: RECIPIENT_HEADER_TO,
    accessor: CUSTOMER_KEY_TO,
  },
  {
    Header: RECIPIENT_HEADER_FROM,
    accessor: CUSTOMER_KEY_FROM,
  },
  {
    Header: TRANSACTION_STATUS_HEADER,
    accessor: TRANSACTION_STATUS_KEY,
  },
  {
    Header: TRANSACTION_TYPE_HEADER,
    accessor: TRANSACTION_TYPE_KEY,
  },
  {
    Header: TIMESTAMP_HEADER,
    accessor: TIMESTAMP_KEY,
    filter: "customTimeFrameFilterFn",
  },
  {
    Header: TIME_HEADER,
    accessor: TIME_KEY,
  }
];

export const recipientExcelHeaders = (t: (arg0: string) => any) => [
  {
    label: t(TOTAL_HEADER),
    key: TOTAL_KEY,
  },
  {
    label: t(VENDOR_HEADER_TO),
    key: CUSTOMER_KEY_TO,
  },
  {
    label: t(VENDOR_HEADER_FROM),
    key: CUSTOMER_KEY_FROM,
  },
  {
    label: t(TRANSACTION_STATUS_HEADER),
    key: TRANSACTION_STATUS_KEY,
  },
  {
    label: t(TRANSACTION_TYPE_HEADER),
    key: TRANSACTION_TYPE_KEY,
  },
  {
    label: t(TIMESTAMP_HEADER),
    key: TIMESTAMP_KEY,
  },
  // {
  //   Header: t(TIME_HEADER),
  //   accessor: TIME_KEY,
  // }
];

export const vendorExcelHeaders = (t: (arg0: string) => any) => [
  {
    label: t(TOTAL_HEADER),
    key: TOTAL_KEY,
  },
  {
    label: t(VENDOR_HEADER_TO),
    key: CUSTOMER_KEY_TO,
  },
  {
    label: t(VENDOR_HEADER_FROM),
    key: CUSTOMER_KEY_FROM,
  },
  {
    label: t(TRANSACTION_STATUS_HEADER),
    key: TRANSACTION_STATUS_KEY,
  },
  {
    label: t(TRANSACTION_TYPE_HEADER),
    key: TRANSACTION_TYPE_KEY,
  },
  {
    label: t(TIMESTAMP_HEADER),
    key: TIMESTAMP_KEY,
  },
  // {
  //   Header: t(TIME_HEADER),
  //   accessor: TIME_KEY,
  // }
];
