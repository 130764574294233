import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";


export const ProjectTypeInput = () => {

  const { t } = useTranslation()

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_type_validation");
  }
  return false;
};
  const project_types = [
    { label: t("humanitarian_relief"), value: "humanitarian_relief" },
    { label: t("development"), value: "development" },
    { label: t("microFinance"), value: "microFinance" },
    { label: t("private_sector"), value: "private_sector" },
  ]
  const ProjectTypeInputFieldInstance = useField("project_type", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const { meta, getInputProps } = ProjectTypeInputFieldInstance;

  return (
    <li className="organization__form-input-list-item">
      <label>
        <Trans i18nKey="project_type" />
      </label>
      <div className="input_container">
        <HTMLSelect
          {...getInputProps()}
          options={[
            { label: t('select_type'), value: "" },
            ...project_types,
          ]}
          required
          name="projectType"
          className="input_field"
        />
        {meta.isTouched && (
          <p className="input_error_wrapper">
            {meta.error}
          </p>
        )}
        </div>
    </li>
  );
};


