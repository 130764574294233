import { UmojaCurrencyInput } from "../../../../../Inputs/UmojaCurrencyInput";
import { useField } from "react-form";

export interface CurrencyInputProps {
  readOnly?: boolean;
  disabled?: boolean;
  numberFormatClassName?: string;
  currency: string;
  organizationId?: string;
}

export const CurrencyInput = ({ readOnly, disabled, numberFormatClassName, currency, organizationId }: CurrencyInputProps) => {
  const {
    getInputProps,
    setValue,
    value,
  } = useField("amount", {
    validate: validateCurrencyInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  return (
    <UmojaCurrencyInput
      organizationId={organizationId}
      {...getInputProps()}
      value={value}
      setValue={setValue}
      currencyPrefix={currency}
      readOnly={readOnly || disabled}
      numberFormatClassName={numberFormatClassName}
    />
  );
};

const validateCurrencyInput = (input: string) => {
  if (!input) {
    return "Currency Amount is Required";
  }
  return false;
};
