import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import useCOuntries from "../../../hooks/useCountries";

interface OperatingCountryInputProps {
  disabled?: boolean;
}

export const OperatingCountryInput = ({
  disabled,
}: OperatingCountryInputProps) => {
  const { t } = useTranslation();
  const countries = useCOuntries();

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("operation_country_validation");
  }
  return false;
  };
  
  const { meta: { error, isTouched }, getInputProps, } = useField("operating_country", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });


  if (countries.isLoading) {
    return null;
  }

  let countrySelections = countries.data.map((country: any, index: number) => {
    return {
      key: index,
      label: country.name,
      value: country.code,
    };
  });
  
  return (
    <li className="organization__form-input-list-item">
      <label>{t('operating_country')}</label>
      <div className="input_container">
      <HTMLSelect
          {...getInputProps()}
          options={[
            { label: t("select_country"), value: "" },
            ...countrySelections,
          ]}
          required
          name="projectFocus"
          className="input_field"
        />
        {isTouched && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
        </div>
    </li>
  );
};

