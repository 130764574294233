import "./Button.css";
import { MouseEventHandler } from "react";
import { ReactComponent as Close } from "../Icons/close.svg";
import { Trans } from "react-i18next";

export interface DrawerHeaderButtonProps {
  icon: JSX.Element;
  text: string;
  color: string;
  handleClick: MouseEventHandler<HTMLButtonElement>;
}

export const DrawerHeaderButton = ({
  color,
  handleClick,
}: DrawerHeaderButtonProps) => {
  return (
    <button className="wallet__button" onClick={handleClick}>
      <span>
        <Close />
      </span>{" "}
      <span>
        <Trans i18nKey="dismiss" />
      </span>
    </button>
  );
};

export default DrawerHeaderButton;
