import { useQuery } from "react-query";
import { getProgramActivityStats } from "../services";

export default function useProjectActivityStats(
  organizationId: string,
  projectId: string
) {
  return useQuery(
    ["ProgramActivity", organizationId, projectId],
    () =>
      getProgramActivityStats(parseInt(organizationId), parseInt(projectId)),
    {
      initialData: {},
      enabled: !!projectId && !!organizationId,
    }
  );
}

