import "./OrganizationWallets.css";
import { useHistory, useParams } from "react-router-dom";
import { Row } from "react-table";
import { parseISO, format } from "date-fns";

import {
  useAccount,
  useWallets,
  useProjects,
  useOrganization,
} from "../../hooks";
import { Button, Layout, UmojaHeader } from "../../components";
import { Table } from "./WalletsTable";


import { Drawer, Menu, MenuItem, Spinner } from "@blueprintjs/core";
import { OrganizationWalletDrawer } from "./OrganizationWalletDrawer";
import { Trans, useTranslation } from "react-i18next";
import DefaultProjectView from "../Home/DefaultProjectView";
import { Popover2 } from "@blueprintjs/popover2";
import { DateRangePicker } from "@blueprintjs/datetime";
import React from "react";
import { matchSorter } from "match-sorter";
import { DateRange } from "@blueprintjs/datetime/src/common/dateRange";
import { isWithinInterval } from 'date-fns'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/fr'
import 'moment/locale/es'


export type OrganizationProject = {
  id: string;
  name: string;
  project_type: string;
  project_focus: string;
  operating_country: string;
  project_currency: string;
  project_details: Record<string, any>;
  project_onboarding_step: number;
  published_at: Date | null;
  project_language: string;
  organization_id: number;
};

export type Organization = {
  account_id: number;
  account_role_id: number;
  billing_email: string;
  contact_email: string;
  country_id: number;
  created_at: string;
  id: number;
  name: string;
  organization_id: number;
  shufti_company_number: string;
  shufti_country_code: string;
  stripe_customer_id: string;
  type: string;
  currency?: string
};




function OrganizationWalletsScreen() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useParams<{ organizationId: string; walletId: string }>();
  const { organizationId } = params;

  const account = useAccount();
  const projects = useProjects();
  const wallets = useWallets(organizationId);
  const organization = useOrganization(parseInt(organizationId));

  const project = (progId: number) => {
    const project = projects?.data?.programs?.find((prog: any) => {
      return prog?.id === progId;
    });
    if (!project) {
      return null;
    }
    return project;
  };

  const organizationPrograms = projects?.data?.programs?.filter((project: any) => project.organization_id === Number(organizationId))

  function NameFilter({ column}: any){
    const { label, filterValue, setFilter, toggleSortBy } =  column
    const NameFilterMenu = (
      <Menu style={{ paddingTop: "10px"}}>
        <input style={{ border: "none", marginBottom: "10px", width: "100%"}}
               placeholder={`${t('search')}...`}
               value={filterValue || ''}
               onChange={e => {
                 setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
               }}
                />
        <MenuItem  text={t('sort_A')}  onClick={() => toggleSortBy(false) } />
        <MenuItem  text={t('sort_z')}  onClick={() => toggleSortBy(true)} />
      </Menu>
    )

    return (
      <Popover2 content={NameFilterMenu} placement="right-end" className="filter_pop">
        <Button
          className="wallet_filter_dropdown"
          text={label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function UserTypeFilter({ column }: any){
    const { setFilter } = column
    const UserFilterMenu = (
      <Menu style={{ paddingTop: "10px"}}>
        <MenuItem  text={`${t('merchant')}`} onClick={() => setFilter(`${t('merchant')}`)}  />
        <MenuItem  text={`${t('recipient')}`} onClick={() => setFilter(`${t('recipient')}`)} />
        <MenuItem  text={`${t('reset')}`} onClick={() => setFilter("")} />
      </Menu>
    )

    return (
      <Popover2 content={UserFilterMenu} placement="right-end" className="filter_pop">
        <Button
          className="wallet_filter_dropdown"
          text={column.label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function ProjectFilter({ column }: any){
    const { setFilter } = column
    const UserFilterMenu = (
      <Menu style={{ paddingTop: "10px"}}>
        {
          organizationPrograms.map((program: any, index: number) => <MenuItem key={index} text={program?.name} onClick={() => setFilter(program?.name)}  />)
        }
      </Menu>
    )

    return (
      <Popover2 content={UserFilterMenu} placement="bottom-end" className="filter_pop">
        <Button
          className="wallet_filter_dropdown"
          text={column.label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function PhoneNoFilter({ column }: any) {
    const { label, filterValue, setFilter, toggleSortBy } =  column
    const PhoneNoFilterMenu = (
      <Menu style={{ paddingTop: "10px"}}>
        <input style={{ border: "none", marginBottom: "10px", width: "100%"}}
               placeholder={`${t('search')}...`}
               value={filterValue || ''}
               onChange={e => {
                 setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
               }}
        />
        <MenuItem  text={`${t('sort_asc')}`} onClick={() => toggleSortBy(false)} />
        <MenuItem  text={`${t('sort_desc')}`} onClick={() => toggleSortBy(true)} />
      </Menu>
    )

    return (
      <Popover2 content={PhoneNoFilterMenu} placement="right-end" className="filter_pop">
        <Button
          className="wallet_filter_dropdown"
          text={label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function EnrollmentDateFilter ({ column }: any) {
    const { filterValue = [null, null], setFilter } = column
    const handleChange = (selectedDates: DateRange) => {
      setFilter(selectedDates)
    }

    const DatePickerFilter = (
      <Menu style={{ paddingTop: "10px"}}>
        <DateRangePicker
          allowSingleDayRange
          value={[filterValue[0], filterValue[1]]}
          onChange={handleChange}
          highlightCurrentDay
          minDate={organizationPrograms[0]?.published_at ? new Date(organizationPrograms[0].published_at): new Date()}
          maxDate={new Date()}
          shortcuts={false}
          locale={i18n.language}
          localeUtils={MomentLocaleUtils}
        />
        <Button text={t("reset")} className={""} onClick={() => setFilter([null, null])}/>
      </Menu>
    )

    return (
      <Popover2 content={DatePickerFilter} placement="right-end" className="filter_pop" >
        <Button
          className="wallet_filter_dropdown"
          text={column.label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function AccountStatusFilter({ column }: any){
    const { setFilter } = column

    const AccountStatusMenu = (
      <Menu style={{ paddingTop: "10px"}}>
        <MenuItem  text={`${t('active')}`}  onClick={() => setFilter(`${t('active')}`)}/>
        <MenuItem  text={`${t('paused')}`} onClick={() => setFilter(`${t('paused')}`)} />
        <MenuItem  text={`${t('reset')}`} onClick={() => setFilter("")} />
      </Menu>
    )

    return (
      <Popover2 content={AccountStatusMenu} placement="right-end" className="filter_pop">
        <Button
          className="wallet_filter_dropdown"
          text={column.label}
          alignText="left"
          rightIcon="caret-down" />
      </Popover2>
    )
  }

  function sortWallet(rowA: any, rowB: any, columnId: string, desc: Boolean){
    return rowA.original[columnId] > rowB.original[columnId] ? 1 : -1;
  }

  function filterByProgram (rows: any, columnIds: any, filterValue: any){
    const result = rows.filter((row : any) => {
      return row.original.project_name === filterValue
    })
    return result
  }



function fuzzyTextFilterFn(rows:any, id: any, filterValue: any) {
    // @ts-ignore
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }

// Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val: any) => !val

  function filterDateFn(rows: any, columnIds: any, filterValue: any) {
    if(!filterValue[0] && !filterValue[0]){
      return rows
    }
    const result = rows.filter((row : any) => {
        return isWithinInterval(new Date(row.original[columnIds[0]]), { start: filterValue[0], end: filterValue[1] ? filterValue[1] : new Date()})
    })
    return result
  }

  const columns = [
    {
      label: `${t("name")}`,
      accessor: 'name',
      Filter: NameFilter,
      filter: fuzzyTextFilterFn,
      sortType: sortWallet
    },
    {
      label: `${t("user_type")}`,
      accessor: 'user_type',
      Filter: UserTypeFilter,
      filter: 'includes'
    },
    {
      label: `${t("phone_number")}`,
      accessor: 'phone_number',
      Filter: PhoneNoFilter,
      filter: fuzzyTextFilterFn,
    },
    {
      label: `${t("project")}`,
      accessor: 'project_name',
      Filter: ProjectFilter,
      filter: filterByProgram
    },
    {
      label: `${t("enrollment_date")}`,
      accessor: 'enrolled_at',
      Filter: EnrollmentDateFilter,
      filter: filterDateFn
    },
    {
      label: `${t("account_status")}`,
      accessor: 'locked',
      Filter: AccountStatusFilter,
      filter: 'includes'
    },
  ];




  const dismissModal = () =>
    history.push(`/dashboard/organizations/${organizationId}/wallets`);

  const onRowClick = (row: Row<any>) => {
    history.push(
      `/dashboard/organizations/${organizationId}/wallets/${row}`
    );
  };

  if (wallets.isLoading || organization.isLoading || projects.isLoading) {
    return (
      <Layout.Container>
        <div className="dashboard__spinner">
          <Spinner />
        </div>
      </Layout.Container>
    )
  }

  return projects?.data && wallets?.data  ? (

    <Layout.Container>
      <UmojaHeader
        title={<Trans i18nKey="wallet_explorer" />}
        user={account.data}
      />
      <Table
        columns={columns}
        data={wallets.data.map((data: Record<string, any>) => ({
          id: data.id,
          enrolled_at: format(parseISO(data.enrolled_at), "P"),
          phone_number: data.phone_number,
          name:  data.name,
          user_type: data.type_id === 1 ? t('recipient') : t('merchant'),
          project_name: project(data?.program_id)?.name,
          locked: data.locked ? t('paused') : t('active')
        }))}
        onRowClick={onRowClick}
      />
      <Drawer
        isOpen={params.walletId !== undefined}
        canOutsideClickClose
        onClose={dismissModal}
        style={{ width: "70%", backgroundColor: "#FFFFFE", maxWidth: "1210px" }}
        enforceFocus={false}
      >
        <OrganizationWalletDrawer
          dismissModal={dismissModal}
          organization={organization.data}
        />
      </Drawer>
    </Layout.Container>
  ) :  (
    <Layout.Container>
      <UmojaHeader title={<Trans i18nKey="wallet_explorer" />} user={account.data} />
      <DefaultProjectView displayPaymentWidget={false} displayProjectHeading={false} />
    </Layout.Container>
  );
}
export default OrganizationWalletsScreen;
