import { Card } from "@blueprintjs/core";
import { useState } from "react";
import { useForm } from "react-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../components";
import { ProjectFocusInput } from "./inputs/ProjectFocusInput";
import { ProjectTitleInput } from "./inputs/ProjectTitleInput";
import { ProjectTypeInput } from "./inputs/ProjectTypeInput";
import { OperatingCountryInput } from "./inputs/OperatingCountryInput";
import { ProgramCurrencyInput } from "./inputs/ProgramCurrencyInput";
import { ProgramLanguageInput } from "./inputs/ProgramLanguageInput";
import { ReactComponent as Close } from "../close.svg";
import { DrawerHeaderButton } from "../../components/Button/DrawerHeaderButton";
import { CustomTextInput } from "./inputs/CustomTextInput";
import { NewPasswordInput } from "./inputs/PasswordInput";
import { ConfirmPasswordInput } from "./inputs/ConfirmPasswordInput";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { EmailAddressInput } from "./inputs/EmailAddressInput";
import { useQueryClient } from "react-query";



export const CreateNewOrganizationForm = ({setShowAddOrganizationDrawer}: any) => {
    const { t } = useTranslation();
   const [saving, setSaving] = useState(false);
   const QueryClient = useQueryClient()
    const CreateNewOrganizationFormInstance = useForm({
        onSubmit: async (values) => {
            setSaving(true);
            const data = {
	          org_name: values.organization_name,
              org_type: values.organization_type,
              org_contact_email: values.contact_email,
              org_billing_email: values.billing_email,
              project_name: values.project_name,
              org_country:  values.operating_country,
              project_country: values.operating_country,
              project_type: values.project_type,
              project_focus: values.project_focus,
              project_currency: values.selected_currency,
              project_language: values.project_language,
              accounts : [
                    {
                      email : values.email,
                      password : values.password, 
                      first_name : values.first_name,
                      last_name : values.last_name, 
                      role : "admin"
                    },
                ],
              }
              const response = await fetch(process.env.REACT_APP_CREATE_ORGANIZATION_ENDPOINT as string, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: JSON.stringify(data),
              });
              if (response.status === 200) { 
                  setSaving(false);
                  setShowAddOrganizationDrawer(false);
                  // success
                  UmojaToast.show({
                    message: t("organization_created_successfully"),
                    intent: "success",
                    icon: "tick",
                });
                QueryClient.invalidateQueries("organizations");
            } else {
                setSaving(false);
                // error
                UmojaToast.show({
                  message: t("error_creating_organization"),
                  intent: "danger",
                  icon: "error",
                 });
               }
              }
            });
    
    return (
        <CreateNewOrganizationFormInstance.Form encType="multipart/form-data">
            <div style={{ marginTop: "10px", marginLeft: "10px"}}>
              <DrawerHeaderButton
                 icon={<Close />}
                 text="dismiss"
                 color="#323232"
                 handleClick={() => setShowAddOrganizationDrawer(false)}
               />
            </div>
            <div style={{ padding: "20px" }}>
            <div className="settings__card-header">
                <div className="settings__card-title_wrapper">
                    <h4 className="settings__card-title">
                        {t("create_new_organization")}
                    </h4>
                    <p className="settings__card-subtitle">
                        {t("create_organization")}
                    </p>
                </div>
            </div>
            <Card>
                <h5>{ t("organization_details")}</h5>
                <ul className="settings__card-input-list">
                    <CustomTextInput
                        placeholder={t("organization_name_input_placeholder")}
                        validationError={t("organization_name_input_required")}
                        valueName="organization_name"
                        name="OrganizationName"
                        label={t("organization_name_input_label")}
                    />
                    <CustomTextInput
                        placeholder={t("organization_type_input_placeholder")}
                        validationError={t("organization_type_input_required")}
                        valueName="organization_type"
                        name="OrganizationType"
                        label={t("organization_type_input_label")}
                    />
                    <CustomTextInput
                        placeholder={t("organization_billing_email_input_placeholder")}
                        validationError={t("organization_billing_email_input_required")}
                        valueName="billing_email"
                        name="BillingEmail"
                        label={t("organization_billing_email_input_label")}
                    />
                    <CustomTextInput
                        placeholder={t("organization_contact_email_input_placeholder")}
                        validationError={t("organization_contact_email_input_required")}
                        valueName="contact_email"
                        name="ContactEmail"
                        label={t("organization_contact_email_input_label")}
                    />
               </ul>
            </Card>
            <div>
            <Card>        
                <h5>{t("program_information")}</h5>
                <ul className="settings__card-input-list" >
                    <ProjectTitleInput />
                    <OperatingCountryInput />
                    <ProgramLanguageInput  />
                    <ProgramCurrencyInput />
                    <ProjectTypeInput />
                    <ProjectFocusInput />
                </ul>
            </Card>
            <Card>
                <h5>{t("administrator_information")}</h5>
                <ul className="settings__card-input-list">
                    <CustomTextInput
                        placeholder={t("first_name_input_placeholder")}
                        validationError={t("first_name_input_required")}
                        valueName="first_name"
                        name="firstName"
                        label={t("first_name_input_label")}
                    />
                    <CustomTextInput
                        placeholder={t("last_name_input_placeholder")}
                        validationError={t("last_name_input_required")}
                        valueName="last_name"
                        name="lastName"
                        label={t("last_name_input_label")}
                    />
                    <EmailAddressInput />
                    <NewPasswordInput />
                    <ConfirmPasswordInput />   
                </ul>
                <Button
                    text={t("create_organization_submit")}
                    intent="primary"
                    type="submit"
                    disabled={saving}
                    loading={saving}
                />
            </Card>  
          </div>
        </div>
        </CreateNewOrganizationFormInstance.Form>
    );
}