import { useState } from "react";

import { Classes, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Button, UmojaDialog } from "../../../../../components";
import { UseTableCellProps } from "react-table";
import { UmojaAccount } from "../../../../Organization/Team";
import { UmojaRole } from "..";
import { UmojaToast } from "../../../../../components/Popups/UmojaToast";
import { Trans, useTranslation } from "react-i18next";
import { AtLeast } from "../../../../../hooks/useRole";
import { useQueryClient } from "react-query";

interface RoleSelectorProps {
  data: UseTableCellProps<UmojaAccount, any>;
  userRole: string;
  account: UmojaAccount;
}

export const RoleSelect = Select.ofType<UmojaRole>();

export const RoleSelector = ({ data, userRole, account }: RoleSelectorProps) => {
  
  const { t } = useTranslation();
  const [role, setRole] = useState(data.row.original.role);
  const [newRole, setNewRole] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [oldRole, setOldRole] = useState("")
  const [user, setUser] = useState("")
  const [showSameRoleDialog, setShowSameRoleDialog] = useState(false);
  const QueryClient = useQueryClient()

  const UMOJA_ROLES: UmojaRole[] = [
  {
    id: 1,
    type: t('admin'),
    role: "Admin"
    
  },
  {
    id: 2,
    type: t('organizer'),
    role: "Organizer"
  },
  {
    id: 3,
    type: t('viewer'),
    role: "Viewer"
  },
];

  const toggleRoleChangeDialog = (instance: UmojaRole) => {
    const newRole = t(instance.type);
    const oldRole = t(data.row.original.role);
    const user = `${data.row.original.first_name} ${data.row.original.last_name}`;
    if (oldRole.toLowerCase() === newRole.toLowerCase()) {
       setShowSameRoleDialog(true)
       setNewRole(newRole);
       setUser(user)
    } else {
      setCurrentRole(instance.role);
      setNewRole(newRole);
      setShowDialog(true);
      setOldRole(oldRole)
      setUser(user)
    }
    
  };

  const handleRoleChange = async () => {
    setIsPending(true);
    const response = await fetch(
      process.env.REACT_APP_CHANGE_ROLE_ENDPOINT as string,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          accountId: data.row.original.account_id,
          role: currentRole,
          organizationId: data.row.original.organization_id,
        }),
      }
    );

    setIsPending(false);
    setShowDialog(false);
    if (response.status === 200) {
      setRole(newRole);
      UmojaToast.show({
        message: `${t("role_changed_successfully")}`,
        intent: "success",
        icon: "tick",
      });
       await QueryClient.invalidateQueries("team")
    } else {
      UmojaToast.show({
        message: `${t("request_not_completed")}`,
        intent: "danger",
        icon: "error",
      });
    }
  };
  
  const renderRole: ItemRenderer<UmojaRole> = (
    role,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={role.id}
        onClick={handleClick}
        text={role.type}
        className="sidebar__menu-list_item"
        disabled={!AtLeast.Admin(userRole) || account.demo}
      />
    );
  };

  return (
    <>
      <UmojaDialog isOpen={showDialog} handleClose={() => setShowDialog(false)}>
        <div className={Classes.DIALOG_BODY}>
          <Trans i18nKey="confirm_role_change"  >
            Are you sure you want to change <strong>{{ user: user }}'s</strong> role from <strong>{{ oldRole: oldRole }}</strong> to <strong>{{ newRole: newRole }}</strong>?
          </Trans>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setShowDialog(false)} text={t("back")} />
            <Button
              onClick={handleRoleChange}
              intent="primary"
              text={t("confirm")}
              loading={isPending}
            />
          </div>
        </div>
      </UmojaDialog>
      <UmojaDialog isOpen={showSameRoleDialog} handleClose={() => setShowSameRoleDialog(false)}>
        <div className={Classes.DIALOG_BODY}>
          <Trans i18nKey="same_role_change"  >
            <strong>{{ user: user }} </strong> is already <strong>{{ newRole: newRole }}</strong>
          </Trans>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setShowSameRoleDialog(false)} text={t("back")} />
          </div>
        </div>
      </UmojaDialog>
      <RoleSelect
        items={UMOJA_ROLES}
        itemRenderer={renderRole}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={(instance) => toggleRoleChangeDialog(instance)}
        filterable={false}
      >
        <Button text={t(role)} rightIcon="caret-down" />
      </RoleSelect>
    </>
  );
};
