import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { useTranslation } from "react-i18next";

export const ProjectFocusInput = () => {
  const { t } = useTranslation()

  const focus_types = [
    { label: t("health"), value: "health" },
    { label: t('nutrition'), value: 'nutrition' },
    { label: t('food_security'), value: 'food_security' },
    { label: t('cash_assistance'), value: 'cash_assistance' },
    { label: t('voucher_assistance'), value: 'voucher_assistance' },
    { label: t('housing_shelter'), value: 'housing_shelter' },
    { label: t('water_and_sanitation'), value: 'water_and_sanitation' },
    { label: t('livelihood_and_economic_support'), value:'livelihood_and_economic_support'  },
    { label: t('education'), value: 'education' },
    { label: t('gender_equality'), value: 'gender_equality'}
  ]
 
  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_focus_validation");
  }
  return false;
};
  const ProjectFocusInputFieldInstance = useField("project_focus", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const { meta, getInputProps } = ProjectFocusInputFieldInstance;

  return (
    <li className="organization__form-input-list-item">
      <label>{t('project_focus')}</label>
      <div className="input_container">
        <HTMLSelect
          {...getInputProps()}
          options={[
            {
              label: t('select_focus'),
              value: "",
            },
            ...focus_types
          ]}
          required
          name="projectFocus"
          className="input_field"
        />
        {meta.isTouched && (
          <p className="input_error_wrapper">
            {meta.error}
          </p>
        )}
        </div>
    </li>
  );
};


