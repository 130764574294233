import "./UmojaChartWidget.css";
import { ReactNode, useCallback, useState } from "react";
import {
  VictoryLine,
  VictoryCursorContainer,
  VictoryLegend,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
  LineSegment,
} from "victory";
import { format } from "date-fns";
import { round } from "lodash";

export interface UmojaLineChartWidgetProps {
  title?: ReactNode;
  timeframe: any;
  data: Record<string, string | number>[];
  currency: string;
  height?: number;
  width?: number;
  legend?: string;
  theme: any;
}

const dateFormat = "MMM dd H:mm";

export const UmojaLineChartWidget = ({
  title,
  timeframe,
  data,
  legend,
  currency,
  theme,
}: UmojaLineChartWidgetProps) => {
  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  if (!data.length) {
    // render empty chart, constrained by selected timeframe on x-axis
    return (
      <div className="featured__charts-chart-area" ref={graphRef}>
        <VictoryGroup
          theme={theme}
          height={boundingRect.height}
          padding={{ top: 10, left: 30, bottom: 40, right: 30 }}
          domainPadding={{ x: 40, y: 30 }}
          animate={{
            duration: 1000,
            onLoad: { duration: 500 },
          }}
        >
          {legend && <VictoryLegend x={20} title={`${currency}${legend}`} />}
          <VictoryAxis
            tickValues={[
              format(timeframe.start, dateFormat),
              format(timeframe.end, dateFormat),
            ]}
            padding={{ top: 10, left: 0, bottom: 20, right: 0 }}
            fixLabelOverlap
          />
          <VictoryLine />
        </VictoryGroup>
      </div>
    );
  }
  // render plotted line chart
  return (
    <>
      {title}
      <div className="chart__widget-line-wrapper" ref={graphRef}>
        <VictoryGroup
          theme={theme}
          width={boundingRect.width}
          data={data}
          padding={{ top: 10, left: 30, bottom: 40, right: 30 }}
          domainPadding={{ x: 20, y: 30 }}
          animate={{
            duration: 1000,
            onLoad: { duration: 500 },
          }}
          containerComponent={
            <VictoryCursorContainer
              cursorLabel={({ datum }: Record<string, any>) => {
                return `${currency}${round(datum.y, 2)}`;
              }}
              cursorComponent={
                <LineSegment
                  style={{ fill: "#fffffe", stroke: "#fffffe", opacity: 0.5 }}
                />
              }
              cursorLabelComponent={
                <VictoryLabel style={{ fill: "#fffffe" }} />
              }
              preserveAspectRatio="none"
            />
          }
        >
          {legend && (
            <VictoryLegend x={30} y={20} title={`${currency}${legend}`} />
          )}
          <VictoryAxis
            tickValues={data.map((val) => val.x)}
            padding={{ top: 10, left: 0, bottom: 20, right: 0 }}
            fixLabelOverlap
          />
          <VictoryLine />
        </VictoryGroup>
      </div>
    </>
  );
};

export default UmojaLineChartWidget;
