import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import Tags from "../../../../../components/TagContainer/Tags";
import { useTranslation } from "react-i18next";

interface CategoryInputProps {
  disabled: boolean;
  readOnly: boolean;
  handleChange?: any
  handleSubmit: any
  onRemove: any,
  fieldState: string,
  categoriesState:string[]
  duplicateIndex: number,
  valueError: boolean
}

export const CategoryInput = ({
  disabled,
  readOnly,
  handleChange,
  handleSubmit,
  onRemove,
  fieldState,
  categoriesState,
  duplicateIndex,
  valueError
}: CategoryInputProps) => {

  const { t } = useTranslation();
  
  const validateInput = (categoriesState: any) => {
  if (categoriesState.length < 0) {
    return t("category_validation");
  }
  return false;
  };


  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("project_category", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  return (
    <li className="settings__card-category-input-list-item">
       <div className="setting__card-category-label">
          <label>{t('category_header')}</label>
          <div style={{ width: "300px", height: "100%" }}>{t('category_detail')}</div>
      </div>
      <div style={{ width: "50%", marginLeft: '10px', marginBottom: '20px', paddingLeft: "15px"}}>
      <div style={{ width: "100%" }}>
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={t("category_input_placeholder")}
          onChange={handleChange}
          disabled={disabled || readOnly }
          readOnly={readOnly}
          value={fieldState}
          onKeyPress={handleSubmit}
          style={{ height: 40, width: 280 }}
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
             {error}
          </p>
        )}
        {
          valueError && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {t("category_too_long")}
            </p>
          )
          }
              <div style={{ overflow: "scroll", height: "200px", marginBottom: "10px" }}>
              <Tags tags={categoriesState} onRemove={onRemove} duplicateIndex={duplicateIndex} minimal={false} disableRemove={readOnly} />
          </div>
      </div>
      </div>
    </li>
  );
};


