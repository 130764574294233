import { useForm } from "react-form";
import { useState } from "react";

import { Classes } from "@blueprintjs/core";
import { Button, UmojaDialog } from "../../../../components";
import { UseTableCellProps } from "react-table";
import { UmojaAccount } from "../../../Organization/Team";
import useOrganizationTeam from "../../../../hooks/useOrganizationTeam";

import { TeamMembersTable } from "./TeamMembersTable";
import { TeamMembersEmptyWidget } from "./TeamMembersEmptyWidget";
import { RoleSelector } from "./inputs/RoleSelector";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { EmailInput } from "./inputs/EmailInput";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";

export type UmojaRole = {
  id: number;
  type: "Admin" | "Organizer" | "Viewer";
  role: string
};

export const UMOJA_ROLES: UmojaRole[] = [
  {
    id: 1,
    type: "Admin",
    role: "Admin"
  },
  {
    id: 2,
    type: "Organizer",
    role: "Organizer" 
  },
  {
    id: 3,
    type: "Viewer",
    role: "Viewer"
  },
];

interface ManageTeamMembersFormProps {
  userRole: string;
  account: UmojaAccount;
}

const { REACT_APP_INVITE_USER_ENDPOINT } = process.env;

export const ManageTeamMembersForm = ({
  userRole,
  account
}: ManageTeamMembersFormProps) => {
  const { t } = useTranslation();
  const selectedOrganizationId = localStorage.getItem(
    "selected_organization_id"
  );
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const OrganizationFormSettings = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
      try {
        const response = await fetch(
          `${REACT_APP_INVITE_USER_ENDPOINT}/${selectedOrganizationId}/invite`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify({ email: values.invite_email }),
          }
        );
        const data = await response.json()
        if (data.error) {
          if (data.error === "ORG_INV_ALREADY_IN_ORG") {
            UmojaToast.show({
            message: `${t("email_already_exist")}`,
            intent: "danger",
            icon: "issue",
            });
          }
          else {
          UmojaToast.show({
            message: `${t("error_completing_request")}`,
            intent: "danger",
            icon: "issue",
          });
          }
        } else {
             UmojaToast.show({
            message: `${t("email_sent_successfully")}`,
            intent: "success",
            icon: "tick",
          });
          }
          instance.reset();
          setLoading(false);
          setShowInviteDialog(!showInviteDialog);
      } catch (error) {
        UmojaToast.show({
          message: `${t("error_submitting_request")}`,
          intent: "danger",
          icon: "issue",
        });
        instance.reset();
        setLoading(false);
        setShowInviteDialog(!showInviteDialog);
      }
    },
    // debugForm: true,
  });

  const { data } = useOrganizationTeam(selectedOrganizationId as string);
  
  const hasTeamMembers = true; // temp for dev. toggle between empty team and populated team

  const dateFormat = "MMMM dd, yyyy";

  const columns = [
    {
      Header: <Trans i18nKey="name" />,
      accessor: "last_name",
      Cell: (instance: UseTableCellProps<UmojaAccount>) => {
        return `${instance.row.original.first_name} ${instance.row.original.last_name}`;
      },
    },
    {
      Header: <Trans i18nKey="access_level" />,
      accessor: "role",
      Cell: (instance: UseTableCellProps<UmojaAccount>) => {
        return <RoleSelector data={instance} userRole={userRole} account={account} />;
      },
    },
    {
      Header: <Trans i18nKey="date_added" />,
      accessor: "created_at",
      Cell: (instance: UseTableCellProps<UmojaAccount>) => {
        return format(new Date(instance.row.original.created_at), dateFormat);
      },
    },
  ];

  const toggleInviteDialog = () => {
    setShowInviteDialog(!showInviteDialog);
  };

  return (
    <>
      <UmojaDialog isOpen={showInviteDialog} handleClose={toggleInviteDialog}>
        <OrganizationFormSettings.Form>
          <div className={Classes.DIALOG_BODY}>
            <p>{t("email_to_send_invite")}</p>
            <EmailInput />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={toggleInviteDialog}
                text={<Trans i18nKey="back" />}
              />
              <Button
                type="submit"
                intent="primary"
                text={<Trans i18nKey="confirm" />}
                loading={loading}
              />
            </div>
          </div>
        </OrganizationFormSettings.Form>
      </UmojaDialog>
      <div className="settings__card-header">
        <div className="settings__card-title_wrapper">
          <h4 className="settings__card-title">
            <Trans i18nKey="manage_team_members" />
          </h4>

          <p className="settings__card-subtitle">
            <Trans i18nKey="manage_team_members_subtitle" />
          </p>
        </div>
        {hasTeamMembers && (
          <Button
            type="button"
            intent="primary"
            disabled={account?.demo}
            text={<Trans i18nKey="invite_team_member" />}
            onClick={toggleInviteDialog}
          />
        )}
      </div>
      {hasTeamMembers && data && columns ? (
        <TeamMembersTable data={data} columns={columns} />
      ) : (
        <TeamMembersEmptyWidget toggleInviteDialog={toggleInviteDialog} />
      )}
    </>
  );
};
