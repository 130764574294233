import { useQuery } from "react-query";

import { getGroups } from "../services";

export default function useGroups(
  organizationId: string | number,
  projectId: string | number
) {
  const groupQuery = useQuery(
    ["groups", organizationId, projectId],
    () => getGroups(organizationId, projectId),
    { initialData: [], enabled: !!organizationId && !!projectId, select: (data) => data?.map((group: any) => {
        return {
          projectId: group.project_id,
          groupName: group.name,
          groupTag: group.tag,
          groupWalletCount: group.wallets_count,
          groupId: group.id,
        };
      }) }
  );

  return groupQuery.data
}
