import { useQuery } from "react-query";
import { getTransactions } from "../services";

export default function useTransactions(
  organizationId: number,
  walletId: number
) {
  return useQuery(
    ["transactions", organizationId, walletId],
    () => getTransactions(organizationId, walletId),
    {
      initialData: [],
      enabled: !!walletId && !!organizationId,
    }
  );
}
