import { useQuery } from "react-query";

import { getAccount } from "../services/organizations";

export default function useAccount() {
  return useQuery("me", () => getAccount(), {
    initialData: {
      organizations: [],
    },
  });
}
