import "./OrganizationWallets.css";
import { MouseEventHandler, useState } from "react";
import { CSVLink } from "react-csv";
import { snakeCase } from "snake-case";
import { ReactComponent as Close } from "../close.svg";
import { Button, Icon } from "@blueprintjs/core";
import {
  vendorExcelHeaders,
  recipientExcelHeaders,
} from "./OrganizationWalletConfig";
import { Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useRole } from "../../hooks";
import { DrawerHeaderButton } from "../../components/Button/DrawerHeaderButton";
import { OrganizationWallet } from "../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import { RegisterUmojaCardForm } from "./forms/RegisterUmojaCardForm";
import { UmojaCard } from "./forms/inputs/CardSelectInput";
import { Organization } from "../Dashboard/DashboardSidebar";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { useTranslation } from "react-i18next";
import { UnRegisterUmojaCardForm } from "./forms/UnRegisterUmojaCardForm";
import { AtLeast } from "../../hooks/useRole";
import { useQueryClient } from "react-query";

export interface OrganizationWalletDrawerHeaderProps {
  rowData: any[];
  wallet: OrganizationWallet | null;
  organization: Organization;
  cards: UmojaCard[];
  card: string | null;
  dismissModal: MouseEventHandler<HTMLButtonElement>;
  handleOpenDisburseDialog: any;
}

export const OrganizationWalletDrawerHeader = ({
  rowData,
  wallet,
  organization,
  cards,
  dismissModal,
  handleOpenDisburseDialog,
}: OrganizationWalletDrawerHeaderProps) => {  
  const { t } = useTranslation();
  const role = useRole();

  const participant = {
    type: wallet?.type_id === 1 ? "Recipient" : "Merchant",
    name: wallet?.name,
  };
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUnRegisterModal, setUnRegisterModal] = useState(false);

  const QueryClient = useQueryClient()


  const pauseTransactions = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_PAUSE_WALLETS_ENDPOINT}/${organization.id}/wallets/${wallet?.id}/lock_status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          lock_status: !wallet?.locked,
        }),
      }
    );
    if (response.ok) {
      setLoading(false);
      UmojaToast.show({
        // message: `${t("wallet_updated_successfully")}`,
        message: wallet?.locked ? t("wallet_resumed_successfully") : t("wallet_paused_successfully") ,
        intent: "success",
        icon: "tick",
      });
      await QueryClient.invalidateQueries("wallets")
      await QueryClient.invalidateQueries("wallet")
    } else {
      setLoading(false);
      UmojaToast.show({
        message: `${t("please_try_again")}`,
        intent: "danger",
        icon: "issue",
      });
    }
  };

  const toggleRegisterModal = () => {
    // Only Organizers and Admins can register cards
    if (!AtLeast.Organizer(role)) {
      UmojaToast.show({
        message: `${t("register_cards_permission_error")}`,
        intent: "danger",
        icon: "issue",
      });
    } else {
      setShowModal(!showModal);
    }
  };
  const toggleUnRegisterModal = () => {
    // Only Organizers and Admins can register cards
    if (!AtLeast.Organizer(role)) {
      UmojaToast.show({
        message: `${t("register_cards_permission_error")}`,
        intent: "danger",
        icon: "issue",
      });
    } else {
      setUnRegisterModal(!showUnRegisterModal);
    }
  };

  const WalletMenu = (
    <Menu>
      {
        wallet && wallet?.card_number ?
          <MenuItem text={t("unregister_card")} onClick={() => toggleUnRegisterModal()} /> : <MenuItem text={t("register_card")} onClick={() => toggleRegisterModal()} />
      }
      {/*<MenuItem*/}
      {/*  text={t("edit_data")} />*/}
      <MenuItem
        text={t(`${wallet?.locked ? "resume" : "pause"}_wallet`)}
        onClick={pauseTransactions}
        disabled={!AtLeast.Organizer(role)}
        style={{ backgroundColor: "7B44FC"}}
      />
    </Menu>
  )

  return (
    <div className="drawer__wrapper">
      {wallet && (
        <RegisterUmojaCardForm
          isOpen={showModal}
          handleClose={() => toggleRegisterModal()}
          wallet={wallet}
          organization={organization}
          cards={cards}
        />
      )}
      {wallet && (
        <UnRegisterUmojaCardForm
          isOpen={showUnRegisterModal}
          handleClose={() => toggleUnRegisterModal()}
          wallet={wallet}
          organization={organization}
        />
      )}
      <div>
        {wallet && (
          <DrawerHeaderButton
            icon={<Close />}
            text="dismiss"
            color="#323232"
            handleClick={dismissModal}
          />
        )}
      </div>
      <div className="participant__title-wrapper">
        <div className="participant__info-wrapper">
          <h2 className="participant__info-name">{participant.name}</h2>
          <h2 className="participant__info-type">{t(participant.type)}</h2>
        </div>
        <div className="participant__info-actions">
          <Button
            minimal
            text={t("Disburse")}
            className="participant__info-disburse-wallet-button"
            onClick={handleOpenDisburseDialog}
            loading={loading}
            disabled={!AtLeast.Admin(role) || wallet?.locked || wallet?.id ? false: true}
          />
          <CSVLink
            filename={snakeCase(
              `participant_transactions-${new Date().toLocaleString()}`
            )}
            data={rowData}
            headers={
              wallet?.type_id === 1 ? recipientExcelHeaders(t) : vendorExcelHeaders(t)
            }
          >
            <Button
              minimal
              text={t("export_data")}
              className="participant__info-export-button"
              icon={
                <Icon
                  icon="add"
                  iconSize={15}
                  color="#BFCCD6"
                  style={{ paddingBottom: 2 }}
                />
              }
             disabled={wallet?.id ? false: true}
            />
          </CSVLink>
          <Popover2 content={WalletMenu} placement="bottom">
            <Button minimal text={t("more")} className="participant__info-actions-button" disabled={wallet?.id ? false: true} />
          </Popover2>
        </div>
      </div>
    </div>
  );
};
