
type FieldError = {
   code: string,
   message: string
}



function formatErrorArray(errors: FieldError[]) {
    return errors.map((error) => {
      return error.code
    })
}



export {
    formatErrorArray
}