import { useState } from "react";
import { Tag } from "@blueprintjs/core";
import { useForm } from "react-form";
import { Button } from "../../../../components";
import { ProjectTitleInput } from "./inputs/ProjectTitleInput";
import { ProjectTypeInput } from "./inputs/ProjectTypeInput";
import { ProjectFocusInput } from "./inputs/ProjectFocusInput";
import { Trans, useTranslation } from "react-i18next";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";
import { OrganizationProject } from "../../../Wallets/OrganizationWallets";

interface CreateProjectFormProps {
  project: OrganizationProject;
  activeStep: any,
  setActiveStep: any,
  account: any
}

export const CreateProjectForm = ({ project, activeStep, setActiveStep, account }: CreateProjectFormProps) => {
  const { t } = useTranslation();
  const selectedOrganizationId = localStorage.getItem(
    "selected_organization_id"
  );

  const [saving, setSaving] = useState(false);

  const activateStep = () => {
    setActiveStep(1);
  };

  const onboardingStep = 1;

  const readOnly = Boolean(project?.published_at);

  const CreateProjectFormInstance = useForm({
    onSubmit: async (values) => {
      setSaving(true);

      const data = new FormData();
      data.append("project_name", values.project_name);
      data.append("project_type", values.project_type);
      data.append("project_focus", values.project_focus);
      data.append("project_organization_id", String(selectedOrganizationId));

      let endpoint;
      let method;

      if (!project) {
        // unpublished project doesn't exist, create new project
        endpoint = process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT;
        method = "POST";
      } else {
        // unpublished project exists, update project
        endpoint = `${process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT}/${project.id}/onboarding/${onboardingStep}`;
        method = "PUT";
      }

      const response = await fetch(endpoint as string, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      });
      // network/server failure
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_creating_updating_project")}`,
          intent: "danger",
          icon: "issue",
        });
        setSaving(false);
      }
      // success
      UmojaToast.show({
        message: `${t('creating_updating_project_successful')}`,
        intent: "success",
        icon: "tick",
      });
      account.refetch(); // refactor to useMutation
      setActiveStep(2);
      setSaving(false);
    },
    // debugForm: true,
  });

  const { meta } = CreateProjectFormInstance;

  return (
    <>
      <CreateProjectFormInstance.Form encType="multipart/form-data">
        <div className="settings__card-header">
          <div className="settings__card-title_wrapper">
            <h2 className="settings__card-step">
              <Trans i18nKey="step_one" />
            </h2>
            <h4 className="settings__card-title">
              <Trans i18nKey="create_a_project" />
              <SettingsFormStatusIndicator
                project={project}
                onboardingStep={onboardingStep}
              />
            </h4>

            <p className="settings__card-subtitle">
              <Trans i18nKey="create_a_project_subtitle" />
            </p>
          </div>
          <Button icon="double-caret-vertical" onClick={activateStep} />
        </div>
        {activeStep === 1 && (
          <>
            <ul className="settings__card-input-list">
              <ProjectTitleInput
                defaultValue={project?.name}
                readOnly={readOnly}
              />
              <ProjectTypeInput
                defaultValue={project?.project_type}
                readOnly={readOnly}
              />
              <ProjectFocusInput
                defaultValue={project?.project_focus}
                readOnly={readOnly}
              />
            </ul>
            <div className="settings__card-step_button_wrapper">
              <p>{t("continue_when_ready")}</p>
              <Button
                type="submit"
                intent="primary"
                text={
                  project?.project_onboarding_step > onboardingStep ? (
                    t('update')
                  ) : (
                    <Trans i18nKey="next_step" />
                  )
                }
                loading={saving}
                disabled={!(meta.canSubmit && !project?.published_at)}
              />
            </div>
          </>
        )}
      </CreateProjectFormInstance.Form>
    </>
  );
};

interface SettingsFormStatusIndicatorProps {
  project: OrganizationProject;
  onboardingStep: number;
}

/** As support grows for additional project, this logic will need to be revisted.
 * Rather than relying on checking the length of the projects array (ie, if a user has created a project),
 * we need to instead write functionality to allow a user to have a "selected project"
 * In the pilots, we are only supporting one project, so checking the length of the array is okay.
 */
export const SettingsFormStatusIndicator = ({
  project,
  onboardingStep,
}: SettingsFormStatusIndicatorProps) => {
  const { t } = useTranslation();
  if (!project) {
    return (
      <Tag
        className="settings__status_indicator"
        htmlTitle="incomplete section"
        intent="warning"
      >
        Start By Creating a Project
      </Tag>
    );
  }

  return project.project_onboarding_step > onboardingStep ? (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="success"
    >
      {t("section_complete")}
    </Tag>
  ) : (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="warning"
    >
      {t("section_incomplete")}
    </Tag>
  );
};
