import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";

interface TeamMembersEmptyWidgetProps {
  toggleInviteDialog: Function;
}

export const TeamMembersEmptyWidget = ({
  toggleInviteDialog,
}: TeamMembersEmptyWidgetProps) => {
  const { t } = useTranslation()
  return (
    <div className="settings__card-team_management--empty_wrapper">
      <h4>You're the first one here.</h4>
      <p>Let's get the reset of you team on-board.</p>
      <Button
        intent="primary"
        text={ t("invite_team_member")}
        onClick={() => toggleInviteDialog()}
      />
    </div>
  );
};
