import { useQuery } from "react-query";
import { getDemographicInsightStats } from "../services";

export default function useDemographicInsightStats(
  organizationId: string,
  projectId: string
) {
  return useQuery(
    ["demographicInsight", organizationId, projectId],
    () =>
      getDemographicInsightStats(parseInt(organizationId), parseInt(projectId)),
    {
      initialData: {},
      enabled: !!projectId && !!organizationId,
    }
  );
}
