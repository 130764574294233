import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks";

interface PrivateRouteProps {
  exact?: boolean;
  component?: any;
  path: string;
  render?: (props: any) => JSX.Element;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
