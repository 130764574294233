import { Card } from "@blueprintjs/core";
import { CreateProjectForm } from "./forms/CreateProjectForm";
import { ProjectDetailsForm } from "./forms/ProjectDetailsForm";
import { ParticipantEnrollmentForm } from "./forms/ParticipantEnrollmentForm";
import { ReviewAndDeployForm } from "./forms/ReviewAndDeployForm";
import { UseQueryResult } from "react-query";
import { OrganizationProject } from "../Wallets/OrganizationWallets";
import NewProjectView from "./NewProjectView";

interface AccountSettingsProps {
  account: UseQueryResult<any, unknown>;
  openNewProjectView: boolean;
  setOpenNewProjectView: Function;
  activeStep: boolean;
  setActiveStep: Function;
  project: any;
  role: string;
  latestPublishedProject: any;
  unpublishedProject: any;
}

export const ProjectManagement = ({
  account,
  openNewProjectView,
  setOpenNewProjectView,
  activeStep,
  setActiveStep,
  project,
  role,
  latestPublishedProject,
  unpublishedProject,
}: AccountSettingsProps) => {
  const handleClick = () => {
    setOpenNewProjectView(false);
  };

  // get published project for an organization

  return openNewProjectView && project?.length === 0 ? (
    <NewProjectView role={role} openProject={handleClick} />
  ) : (
    <ProjectManagementView
      project={
        (unpublishedProject && unpublishedProject[0]) ||
        (latestPublishedProject && latestPublishedProject[0])
      }
      activeStep={activeStep}
      setActiveStep={setActiveStep}
        account={account}
        role={role}
    />
  );
};

interface ProjectManagementProp {
  project: OrganizationProject;
  activeStep: any;
  setActiveStep: any;
  account: any;
  role: string
}

export const ProjectManagementView = ({
  project,
  activeStep,
  setActiveStep,
  account,
  role
}: ProjectManagementProp) => {
  return (
    <div>
      <Card
        className={`settings__card-wrapper ${activeStep === 1 && "active"}`}
      >
        <CreateProjectForm
          project={project}
          account={account}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Card>
      <Card
        className={`settings__card-wrapper ${activeStep === 2 && "active"}`}
      >
        <ProjectDetailsForm
          project={project}
          account={account}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Card>
      <Card
        className={`settings__card-wrapper ${activeStep === 3 && "active"}`}
      >
        <ParticipantEnrollmentForm
          project={project}
          account={account}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          role={role}
        />
      </Card>
      <Card
        className={`settings__card-wrapper ${activeStep === 4 && "active"}`}
      >
        <ReviewAndDeployForm
          project={project}
          account={account}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Card>
    </div>
  );
};
