import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

interface PinInputProps { currentPinError?: string | null; }
export const PinInput = ({ currentPinError } : PinInputProps) => {
  const { t } = useTranslation();
  const validatePin = (pin: string) => {
  if (pin.length < 1) {
    return  t('pin_required');
  }
  if (pin.length < 4) {
    return t('pin_must_be_at_least')
  }
  if (!isValidPin(pin)) {
    return t('invalid_pin_format');
  }
  return false;
};

  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("pin", {
    validate: validatePin,
    defaultIsTouched: false,
  });

  return (
    <div className="input__wrapper">
      <div style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "2px" }}>{`${t('new_pin_code')}`}</div>
      <TextInput
        {...getInputProps()}
        required
        name="pin"
        type="password"
        placeholder={`${t('pin_code')}`}
        style={{ marginBottom: "2px" }}
      />
      {isTouched && <span style={{ color: "red", margin: "0", fontSize: "9px" }}>
        {error}
         {!error && currentPinError ? currentPinError : ""}
      </span>}
    </div>
  );

function isValidPin(pin: string) {
  const re = /^(\d{4})$/;
  return re.test(String(pin));
}
};