import React from "react";
import { H2, H4 } from "@blueprintjs/core";
import UmojaImage from "../../assets/umoja.png";
import { Button } from "../index";
import { useTranslation } from "react-i18next";
import "./DefaultView.css"

interface DefaultViewProp {
  displayProjectHeading: boolean
}


function DefaultView({ displayProjectHeading }: DefaultViewProp ) {
  const { t } = useTranslation()

  return (
    <div className="default-view_container">
      <H2 style={{ padding: "20px",  display: displayProjectHeading ? "block": "none"}}>{t("project_title")}</H2>
      <H4 style={{ paddingBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}>{t("getting_started")}</H4>
      <div style={{ height: "400px", width: "100%", justifyContent: "center", alignItems: "flex-end", display: "flex", backgroundColor: "#E5E5E5", marginBottom: "50px" }}>
        <img src={UmojaImage} style={{ height: "380px", width: "650px"}} alt=""/>
      </div>
      <div className="empty_project_text_container">
        <H2>{t("welcome_to_umoja")}</H2>
        <H4 className="empty_project_body">{t("project_info")}</H4>
        <Button text={t('help_center')} active={true} type={"button"} className="u-button--brand" large={true} onClick={() => {
          window.open(`${process.env.REACT_APP_HELP_CENTER}`,'_blank')
        }} />
      </div>
    </div>
  );
}

export default DefaultView;