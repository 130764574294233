// @ts-nocheck - may need to be at the start of file
import React, { FunctionComponent } from "react";
import RecipientTable from "./RecipientTable";
import { IParticipantWallet } from "./WalletGroup";
import { useTranslation } from "react-i18next";

interface RecipientInfoProps {
  groupRecipient: any[]
  setSelectedWallets: Function
  creatView: boolean,
  editView: boolean,
  searchView: boolean,
  searchResult: IParticipantWallet[]
  finalSelectedWallet: IParticipantWallet[]
}

const ParticipantInfo: FunctionComponent<RecipientInfoProps> = (
                            { groupRecipient,
                             setSelectedWallets,
                             creatView,
                             searchView,
                             finalSelectedWallet,
                             searchResult,
                             editView
                          }) => {

  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        id: "selection",
        Cell: ({ row }) => (
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} style={{ border: "1px solid black"}} />
          </div>
        )
      },
      {
        Header: t('name'),
        accessor: 'name'
      },
      {
        Header: t('phone_number'),
        accessor: 'phone_number'
      },
      {
        Header: t('user_type'),
        accessor: 'user_type'
      },
    ],[t]
  )

  let  tableData = [];
  if(creatView && searchView){
    tableData = searchResult
  }

  if(creatView && !searchView){
    tableData = finalSelectedWallet
  }

  if(!creatView && !searchView){
    tableData = groupRecipient
  }

  if(editView && searchView){
    tableData = searchResult
  }

  if(editView && !searchView){
    tableData = groupRecipient
  }

  return (
    <RecipientTable columns={columns} data={tableData} setSelectedWallets={setSelectedWallets} />
  );
};

export default ParticipantInfo;

