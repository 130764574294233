import "./UmojaHeaderStyles.css";
import { ReactNode } from "react";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { MenuItem, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { UmojaAvatarIcon } from "../Icons/UmojaAvatarIcon";
import { Button } from "..";
import { useAuth, useRole } from "../../hooks";
import { useTranslation } from "react-i18next";

type Organization = {
  id: number;
  account_role_id: number;
  organization_id: number;
};

export type UmojaHeaderProps = {
  title: string | ReactNode;
  user: {
    first_name: string;
    last_name: string;
    avatarSrc?: any;
    organizations: Organization[];
  };
};

const UmojaHeader = ({ title, user }: UmojaHeaderProps) => {
  const { t } = useTranslation();
  const role = useRole();
  const { logout } = useAuth();

  const admin = `${t("admin")}`;
  const organizer = `${t("organizer")}`;
  const viewer = `${t("viewer")}`;
  const superAdmin = "Super Admin";

  const roleName =
    role === "Admin"
      ? admin
      : role === "Organizer"
      ? organizer
      : role === "Viewer"
      ? viewer
      : role === "SuperAdmin"
      ? superAdmin
      : "User";

  if (user) {
    return (
      <div className="umoja__header__outer-wrapper">
        <h1 className="umoja__header__title">{title}</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover2
            popoverClassName="umoja__header__popover"
            interactionKind="click"
            position="bottom"
            minimal
            content={
              <div>
                <Button text={`${t("logout")}`} onClick={() => logout()} />
              </div>
            }
          >
            <div className="umoja__header__profile-indicator">
              <UmojaAvatarIcon
                alt={`Avatar of ${user?.first_name}`}
                fallbackLetter={user?.first_name[0] || ""}
                fallbackTextColor="#ffffff"
                fallbackBgColor="#6200ee"
              />
              <div className="umoja__header__name">
                {user.first_name} {user.last_name}
              </div>
              <Tag className="umoja__header__role" htmlTitle={roleName} large>
                {roleName}
              </Tag>
            </div>
          </Popover2>
          <LanguageSelector />
        </div>
      </div>
    );
  }
  return null;
};

export default UmojaHeader;

const LanguageSelect = Select.ofType<any>();

type Language = {
  language: string;
  flag: any;
  code: string;
};

const renderLanguage: ItemRenderer<Language> = (
  option,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={option.language}
      onClick={handleClick}
      text={`${option.flag} ${option.language}`}
      icon={option.flag}
    />
  );
};

// export interface LanguageSelectorProps {}

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const languageConfig = [
    {
      language: `${t("english")}`,
      flag: "🇺🇸",
      code: "en-US",
    },
    // {
    //   language: `${t("creole")}`,
    //   flag: "🇭🇹",
    //   code: "hat",
    // },
    {
      language: `${t("french")}`,
      flag: "🇫🇷",
      code: "fr",
    },
    {
      language: `${t("spanish")}`,
      flag: "🇪🇸",
      code: "es",
    },
  ];

  const languagePreference = localStorage.getItem("i18nextLng");

  let language = languageConfig.find((item) => {
    return item.code === languagePreference;
  });

  return (
    <LanguageSelect
      filterable={false}
      popoverProps={{
        minimal: true,
      }}
      items={languageConfig}
      activeItem={null}
      itemRenderer={renderLanguage}
      onItemSelect={(option) => {
        i18n.changeLanguage(option.code);
        window.location.reload(); // needed to load large translation file
      }}
    >
      <Button
        minimal
        text={language?.flag}
        alignText="left"
        rightIcon="caret-down"
      />
    </LanguageSelect>
  );
};
