import { useForm, useField, UseFormValues } from "react-form";
import { Button, TextInput } from "../components";
import { Card } from "@blueprintjs/core";

type LoginScreenProps = {};

async function sendToServer(values: UseFormValues<string>) {
  try {
    const response = await fetch(
      process.env.REACT_APP_SIGNUP_ENDPOINT as string,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    throw new Error("There was a problem issuing your request.");
  }
}

function EmailInput() {
  const { getInputProps } = useField("credentials.email", {});
  return (
    <TextInput
      {...getInputProps()}
      required
      autoFocus
      name="email"
      type="email"
    />
  );
}

function FirstNameInput() {
  const { getInputProps } = useField("firstName", {});
  return (
    <TextInput {...getInputProps()} required name="firstName" type="text" />
  );
}

function LastNameInput() {
  const { getInputProps } = useField("lastName", {});
  return (
    <TextInput {...getInputProps()} required name="lastName" type="text" />
  );
}

function PasswordInput() {
  const { getInputProps } = useField("credentials.password", {});
  return (
    <TextInput {...getInputProps()} required name="password" type="password" />
  );
}

function ConfirmPasswordInput() {
  const { getInputProps } = useField("credentials.confirmPassword", {});
  return (
    <TextInput
      {...getInputProps()}
      required
      name="confirmPassword"
      type="password"
    />
  );
}

function SignUpForm() {
  const {
    Form,
    meta: { canSubmit, isSubmitted },
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToServer(values);
    },
    // debugForm: true,
  });

  if (!isSubmitted) {
    return (
      <>
        <h1 style={{ textAlign: "center" }}>Sign Up</h1>
        <Card>
          <Form>
            <div>
              <label>First Name</label>
            </div>
            <div>
              <FirstNameInput />
            </div>
            <div>
              <label>Last Name</label>
            </div>
            <div>
              <LastNameInput />
            </div>
            <div>
              <label>Email</label>
            </div>
            <div>
              <EmailInput />
            </div>
            <div>
              <label>Password</label>
            </div>
            <div>
              <PasswordInput />
            </div>
            <div>
              <label>Confirm</label>
            </div>
            <div>
              <ConfirmPasswordInput />
            </div>
            <div>
              <Button
                type="submit"
                text="Sign Up"
                disabled={!canSubmit}
                intent="primary"
              />
            </div>
          </Form>
        </Card>
      </>
    );
  }

  return (
    <>
      <p>
        Please check email for a verification link to activate your account.
      </p>
    </>
  );
}

function SignUpScreen(props: LoginScreenProps) {
  return (
    <div>
      <div style={{ maxWidth: 320, margin: "auto" }}>
        <SignUpForm />
      </div>
    </div>
  );
}
export default SignUpScreen;
