import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { OrganizationProject } from "../../../../Wallets/OrganizationWallets";
import { useTranslation } from "react-i18next";

interface ProgramLanguageInputProps {
  disabled: boolean;
  readOnly: boolean;
  project: OrganizationProject;
}

export const ProgramLanguageInput = ({
  disabled,
  readOnly,
  project,
}: ProgramLanguageInputProps) => {

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("program_language_validation");
  }
  return false;
  };
  
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("project_language", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.project_language || "",
  });
  const { t } = useTranslation();
  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>{t('project_language')}</label>
        <div style={{ height: 50, width: 200 }}>
          <TextInput
            {...getInputProps()}
            type="text"
            placeholder={project?.project_details?.project_language}
            name="projectName"
            readOnly={readOnly}
          />
          {isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            </p>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className="settings__card-input-list-item">
      <label>{t('project_language')}</label>
      <div>
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: "Select Language", value: "" },
            { label: "English", value: "English" },
            { label: "French", value: "French" },
            { label: "Spanish", value: "Spanish" },
            { label: "Portuguese", value: "Portuguese" },
            { label: "Creole", value: "Creole" },
            { label: "Swahili", value: "Swahili" },
          ]}
          required
          name="projectFocus"
          disabled={disabled}
          style={{ height: 30, width: 200 }}
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};


