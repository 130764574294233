import { Tab, Tabs } from "@blueprintjs/core";
import CountUp from "react-countup";
import { Trans } from "react-i18next";

interface ChartDataProps {
  totalProgramVolume: number;
  selectTimeframe: Function;
  totalEnrolled: number;
  totalMerchants: number;
  currency: string
}

export const ChartData = ({
  totalProgramVolume,
  selectTimeframe,
  totalEnrolled,
  totalMerchants,
  currency
}: ChartDataProps) => {
  let localizedSum = totalProgramVolume?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <div className="featured__charts-chart-data">
        <div className="featured__charts-chart-data-card">
          <CountUp
            className="card-title"
            start={0}
            end={parseFloat(localizedSum)}
            duration={1}
            decimals={2}
            prefix={currency}
          />
          <p className="card-label">
            <Trans i18nKey="total_volume" />
          </p>
        </div>
        <div className="featured__charts-chart-data-card">
          <CountUp
            className="card-title"
            start={0}
            end={totalEnrolled}
            duration={1}
          />
          <p className="card-label">
            <Trans i18nKey="total_enrolled" />
          </p>
        </div>
        <div className="featured__charts-chart-data-card">
          <CountUp
            className="card-title"
            start={0}
            end={totalMerchants}
            duration={1}
          />
          <p className="card-label">
            <Trans i18nKey="merchants" />
          </p>
        </div>
      </div>
      <Tabs
        id="featured__charts-timeframe-select-list"
        className="featured__charts-timeframe-select-list"
        onChange={(timeframe) => selectTimeframe(timeframe)}
        defaultSelectedTabId="timeframe-max"
      >
        <Tab id="timeframe-1h" className="featured__charts-chart-label">
          1H
        </Tab>
        <Tab id="timeframe-24h" className="featured__charts-chart-label">
          24H
        </Tab>
        <Tab id="timeframe-1w" className="featured__charts-chart-label">
          1W
        </Tab>
        <Tab id="timeframe-1y" className="featured__charts-chart-label">
          1Y
        </Tab>
        <Tab id="timeframe-max" className="featured__charts-chart-label">
          ALL
        </Tab>
      </Tabs>
    </div>
  );
};
