import "./UmojaChartWidget.css";
import {
  VictoryLegend,
  VictoryGroup,
  VictoryPie,
  PaddingProps,
  DomainPaddingPropType,
  VictoryTooltip,
} from "victory";

export interface UmojaPieChartWidgetProps {
  title: string;
  data: { x: string; y: number }[];
  padding: PaddingProps;
  domainPadding: DomainPaddingPropType;
  theme: any;
}

export const UmojaPieChartWidget = ({
  title,
  data,
  padding,
  domainPadding,
  theme,
}: UmojaPieChartWidgetProps) => {
  return (
    <div className="chart__widget-line-wrapper">
      <VictoryGroup
        theme={theme}
        padding={padding}
        domainPadding={domainPadding}
      >
        <VictoryPie
          data={data}
          labels={({ datum }) => {
            if (datum.y > 0) {
              return datum.x;
            }
          }}
        />
        <VictoryTooltip
          x={200}
          y={250}
          orientation="top"
          pointerLength={0}
          cornerRadius={50}
          flyoutWidth={100}
          flyoutHeight={100}
          flyoutStyle={{ fill: "black" }}
        />
        <VictoryLegend x={0} y={10} title={title} />
      </VictoryGroup>
    </div>
  );
};

export default UmojaPieChartWidget;
