import "./UmojaPaymentWidget.css";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-form";

import { DisbursementPanel } from "./panels/SingleDisbursementPanel";
import { PreviewPanel } from "./panels/SinglePreviewPanel";
import { Project } from "../../../screens/Home/GlobalOperations";
import { UmojaToast } from "../../Popups/UmojaToast";
import { useTranslation } from "react-i18next";

export type OrganizationWallet = {
  id: number;
  type: string;
  type_id: number;
  program_code: string;
  phone_number: string;
  name: string;
  enrolled_at: Date;
  umoja_wallet_id: number;
  age_group: number;
  pin_code?: number;
  locked: boolean;
  balance: number;
  usd_balance: number;
  currency: string;
};

export interface UmojaPaymentWidgetProps {
  wallet: any;
  // disabled: boolean;
  masterWalletBalance: string;
  project: Project;
  handleCloseDialog: Function;
  currency: string;
}

export type UmojaPaymentWidgetModes =
  | "disburse"
  | "preview"
  | "history"
  | "target_group";

const UmojaSinglePaymentWidget = ({
  wallet,
  // disabled,
  masterWalletBalance,
  project,
  handleCloseDialog,
  currency
}: UmojaPaymentWidgetProps) => {
  const [mode, setMode] = useState<UmojaPaymentWidgetModes>("disburse");
  const [loading, setLoading] = useState(false);
  const { organizationId } = useParams<{ organizationId: string }>();
  const { t } = useTranslation()

  const defaultValues = useMemo(
    () => ({
      amount: 0,
      walletId: ''
    }),
    []
  );

  const PaymentWidgetForm = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setLoading(true);
      // handle api here
      const response = await fetch(
        `${process.env.REACT_APP_DISBURSEMENT_ENDPOINT}/${organizationId}/project/${project.id}/disbursements/` as string,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            amount: values.amount,
            program_wallet_ids: [wallet?.data?.umoja_wallet_id]
          }),
        }
      );

      if (!response.ok) {
        UmojaToast.show({
          message: t("failed_transaction"),
          intent: "danger",
          icon: "issue",
        });
        setLoading(false);
        return;
      }

      setLoading(false);
      UmojaToast.show({
        message: t("success_transaction"),
        intent: "success",
        icon: "tick",
      });
      instance.reset();
      setMode("disburse");
      handleCloseDialog()
    },
    defaultValues,
    // debugForm: true,
  });

  const { values } = PaymentWidgetForm;

  return (
    <PaymentWidgetForm.Form className="payment__widget-wrapper">
      <div className="payment__widget-container">
        {mode === "disburse" && (
          <DisbursementPanel
            setMode={setMode}
            values={values}
            balance={masterWalletBalance || "0.00"}
            wallet={wallet}
            currency={currency}
          />
        )}
        {mode === "preview" && (
          <PreviewPanel
            loading={loading}
            setMode={setMode}
            balance={masterWalletBalance || "0.00"}
            wallet={wallet}
            currency={currency}
          />
        )}
      </div>
    </PaymentWidgetForm.Form>
  );
};

export default UmojaSinglePaymentWidget;