export const french = {
  // languages
  english: "Anglais",
  creole: "Créole",
  french: "Français",
  spanish: "Espagnol",
  settings: "Paramètres",
  good_day: "Bonne journée",
  good_evening: "Bonne soirée",
  step_one: "Etape 1",
  step_two: "Etape 2",
  step_three: "Etape 3",
  step_four: "Etape 4",
  next_step: "Suivant",
  section_complete: "Section terminée",
  section_incomplete: "Section incomplète",
  update: "Mettre à jour",
  save: "Sauvegarder",
  dismiss: "Rejeter",
  phone_number: "Numéro de téléphone",
  // Account Settings
  account_information: "Informations sur le compte",
  account_information_subtitle:
    "Voir les informations sur votre compte et son statut actuel",
  name: "Nom",
  email_address: "Adresse électronique",
  edit: "Modifier",
  change_your_password: "Modifier votre mot de passe",
  current_password: "Mot de passe actuel",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmer le mot de passe",
  confirm_new_password:
    "Le mot de passe de confirmation doit être le même que le nouveau mot de passe",
  change_password_any_time: "Changez votre mot de passe à tout moment",
  new_project_description:
    "Les utilisateurs du tableau de bord Umoja disposant de droits d'administration peuvent créer de nouveaux projets et débourser des fonds sur le réseau Umoja.",
  ok : "D'accord",
  // Project Management
  create_a_project: "Créer un projet",
  create_a_project_subtitle:
    "Définir les localités et les devises du programme",
  project_title: "Titre du projet",
  project_type: "Type de projet",
  project_focus: "Focus sur le projet",
  project_language: "Pays d'opération",
  add_more_participants: "Ajouter d'autres participants",
  enter_project_title: "introduzca el título del proyecto",
  select_type: "Sélectionner le type",
  select_focus: "Sélectionner la focalisation",
  continue_when_ready: "Kontinye lè w pare",
  complete_previous_steps: "Terminer les étapes précédentes",
  project_details: "Projet Détails",
  project_details_subtitle:
    "Définissez le nom et l'objectif de votre nouveau projet",
  project_localization: "Localisation du projet",
  operating_country: "Pays d'opération",
  project_currency: "Monnaie du projet",
  project_goals: "Objectifs du projet",
  target_disbursement_amount: "Montant cible des décaissements",
  target_merchant_enrollment: "Objectif d'inscription des commerçants",
  target_participant_enrollment: "Objectif d'inscription des participants",
  target_percent_expenditure: "Pourcentage cible des fonds à dépenser",
  enroll_participants: "Inscrire Participants",
  enroll_participants_subtitle:
    "Ajouter des bénéficiaires et des commerçants à votre nouveau programme",
  upload_from_kobo: "Importer des données de Kobotoolbox",
  upload_data: "Télécharger des données",
  upload_custom_excel: "Télécharger excel personnalisé",
  import_data_from_koboToolbox: "Importer des données depuis KoboToolbox",
  select_data_source: "Sélectionnez une Source de Données",
  browse: "Feuilleter",
  participant_group: "Groupe de bénéficiaires",
  merchant_group: "Groupe de marchands",
  ready_to_deploy: "Prêt à déployer",
  ready_to_deploy_subtitle:
    "Passez en revue les détails et préparez l'exécution de votre projet",
  ready_to_launch: "Prêt pour le lancement",
  everything_checks_out: "Tout a été vérifié",
  launch_project: "Projet de lancement",
  category_header: "Créez jusqu’à 16 catégories",
  category_detail:
    "Les commerçants utilisent des catégories pour créer des articles dans leurs boutiques. Umoja utilise ces données pour révéler des informations sur le projet",
  category_input_placeholder: "Entrez une catégorie d'article",
  cash_out_day: "Jour d'encaissement",
  select_cash_out: "Sélectionnez le jour de l'encaissement",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  cash_out_validation_error: "Le jour de l'encaissement est requis",
  wallet_groups: "Groupes de portefeuilles",
  select_wallet: "Sélectionner un groupe de portefeuilles",
  create_new_wallet: "Créer un groupe",
  manage_wallet_group: "Gérer les groupes de portefeuilles",
  group_name: "Nom du groupe",
  wallet_group: "Groupes de portefeuilles",
  search_participants: "Recherche de participants à ajouter aux groupes",
  delete_group: "Ajouter à la liste",
  add_to_list: "Ajouter à la liste",
  create_group: "Créer un groupe",
  create_group_inner_text: "Rechercher des participants pour les ajouter à ce groupe",
  selected: "Sélectionné",
  select: "Sélectionnez",
  remove_from_list: "Retirer de la liste",
  program_localization: "Localisation du programme",
  project_categories: "Catégories de programmes",
  create_group_success: "Groupe créé avec succès",
  create_group_error: "Erreur de création de groupe",
  delete_group_success: "Groupe supprimé avec succès",
  delete_group_error: "Erreur lors de la suppression d'un groupe",
  update_group_success: "Les portefeuilles ont été mis à jour avec succès",
  update_group_error: "Erreur lors de la mise à jour du groupe de portefeuilles",
  save_changes: "sauvegarder les changements",
  FIRST_NAME_REQUIRED : "Le prénom est obligatoire",
  LAST_NAME_REQUIRED : "Le nom de famille est obligatoire",
  AGE_GROUP_BETWEEN_0_6 : "La tranche d'âge doit être comprise entre 0 et 6",
  PHONE_NUMBER_REQUIRED : "Le numéro de téléphone est obligatoire",
  PHONE_NUMBER_INVALID : "Le numéro de téléphone n'est pas valide pour ce pays",
  PHONE_NUMBER_ALREADY_IN_PROJECT : "Le numéro de téléphone existe déjà dans ce projet",
  PHONE_NUMBER_ALREADY_IN_DB : "Le numéro de téléphone existe déjà dans un autre projet",
  PHONE_NUMBER_FILE_DUP : "Le numéro de téléphone est dupliqué dans le fichier",
  PHONE_NUMBER_ALREADY_IN_MONEY_API : "Le numéro de téléphone est déjà enregistré dans l'API Money",
  age_group : "Groupe d'âge",
  gender : "Sexe",
  delete_dialog_message: "Êtes-vous sûr de vouloir supprimer ce groupe ? Ceci ne peut être annulé",
  cancel : "Annuler",
  delete : "Supprimer",
  group_name_error: "Le nom du groupe ne peut pas dépasser 20 caractères",
  clear: "Clair",
  category_too_long: "La catégorie ne peut avoir que 20 caractères de long",
  operation_country_validation: "Se requiere el país de operación",
  category_validation: "Introduzca al menos 1 categoría",
  program_currency_validation: "Se requiere la moneda del programa",
  program_language_validation: "Se requiere el idioma del programa",
  only_valid_number_validation: "Sólo se requiere un número válido",
  only_valid_number_btn_one_and_hundred: "Sólo se permite un número válido entre 1 y 100",
  valid_email_validation: "se requiere un correo electrónico válido",
  project_focus_validation: "Se requiere el enfoque del proyecto",
  project_title_validation: "Se requiere el título del proyecto",
  project_type_validation: "Se requiere el tipo de proyecto",
  email_required_validation: "Se requiere un correo electrónico",
  valid_email_required_validation: "Por favor, introduzca una dirección de correo electrónico válida",
  password_required_validation: "Por favor, introduzca una contraseña válida",
  confirm_password_validation: "La contraseña confirmada debe ser la misma que la nueva contraseña",
  new_password_confirmation_validation: "La nueva contraseña no puede ser la misma que su contraseña actual",
  recipient_upload_template : "Modèle de téléchargement du destinataire",
  merchant_upload_template : "Modèle de téléchargement pour le commerçant",
  
  // Organization Settings
  organization_information: "Informations sur l'organisation",
  organization_information_subtitle:
    "Voir les informations sur votre organisation et votre équipe",
  organization_name: "Nom de l'organisation",
  permission_level: "Niveau de permission",
  manage_team_members: "Gérer les membres de l'équipe",
  manage_team_members_subtitle:
    "Voir les membres actuels de l'équipe, ajouter de nouveaux membres et mettre à jour le statut des membres.",
  invite_team_member: "Invitez un membre de l'équipe",
  access_level: "Niveau d'accès",
  date_added: "Date d'ajout",
  health: "Sante",
  nutrition: "Nitrisyon",
  food_security: "Sekirite Alimantè",
  cash_assistance: "Asistans Lajan Kach",
  voucher_assistance: "Asistans pou Koupon",
  housing_shelter: "Lojman/Abri",
  water_and_sanitation: "Dlo ak Ijyèn",
  livelihood_and_economic_support: "Mwayen Sibzistans/Sipò Ekonomik",
  education: "Edikasyon",
  gender_equality: "Ekite Seksyèl",
  humanitarian_relief: "Programme humanitaire/de secours",
  development: "Programme de développement",
  microFinance: "Programme de microfinancement",
  private_sector: "Programme du secteur privé (entreprises)",
  create_first_project: "Créer votre premier espace de projet",
  setup_locality: "Définir les localités et les devises du programme",
  define_product: "Définir les catégories de produits",
  enroll_participant: "Inscrire les participants et les marchands",
  create_project: "Créer un projet",
  email_sent_successfully: "Email envoyé avec succès",
  email_already_exist : "Le compte est déjà dans l'organisation",
  error_completing_request: "Erreur dans le traitement de votre demande",
  email_to_send_invite:
    "À quelle adresse électronique souhaitez-vous envoyer une invitation à l'équipe?",
  valid_email_required: "Une adresse électronique valide est requise",
  confirm: "Confirmer",
  back: "Retour",

  // Settings Sidebar
  your_account_settings: "Paramètres de votre compte",
  organization_settings: "Paramètres de l'organisation",
  project_management: "Gestion de projet",
  additional_resources: "Ressources supplémentaires",
  support: "Support",
  help_center: "Centre d’Aide",
  // Team
  team: "Équipe",
  role: "Rôle",
  // Wallet Explorer
  wallet_explorer: "Explorateur de portefeuilles",
  merchant: "Marchand",
  Merchant: "Marchand",
  recipient: "Destinataire",
  Recipient: "Destinataire",
  from: "De",
  to: "À",
  status: "Statut",
  txn_type: "Taper Txn",
  program_code: "Code du projet",
  enrollment_date: "Date d'inscription",
  account_status: "Statut du compte",
  last_active: "Dernière activité",
  user_type: "Type de Utilisateur",
  total_volume: "Volume total du projet",
  total_transactions: "Total des transactions",
  card_reference: "Carte",
  pin_code: "Code PIN",
  transaction_explorer: "Explorateur des Transactions",
  edit_data: "Modifier les Données",
  register_card: "Enregistrer Carte",
  unregister_card: "Désenregistrer la carte",
  export_data: "Exporter des données",
  resume_wallet: "Reprendre portefeuille",
  pause_wallet: "Pause portefeuille",
  activity_explorer: "Explorateur d'activité",
  Total: "Le total",
  transaction_status: "Statut de la transaction",
  transaction_type: "Type de transaction",
  timestamp: "Date",
  time: "Heure",
  Disburse_to: "Verser à",
  Disburse: "Verser",
  no_record: "Pas d'enregistrement",
  average_transactions: "Transactions moyennes",
  offline_transactions: "Transactions hors ligne",
  total_enrolled: "Total des inscrits",
  merchants: "Marchands",
  Completed: "Complété",
  Pending: "En attente",
  Processing: "Traitement",
  Failed: "Échec",
  DISBURSEMENT: "DÉBOURSEMENT",
  WITHDRAWAL: "RETRAIT",
  PURCHASE: "ACHAT",
  REFUNDED: "REMBOURSÉ",
  wallet_search: "Rechercher un nom ou un numéro de téléphone",
  // Dashboard
  enrollments: "Inscription",
  track_activity: "Suivi de l'activité",
  view_wallets: "Afficher les portefeuilles",
  activity: "Activité",
  wallets: "Portefeuille",
  transaction_volume: "Volume des transactions",
  global_operations: "Opérations mondiales",
  project: "Projet",
  offline_online_transaction: "Transactions hors ligne et en ligne",
  online: "en ligne",
  offline: "hors ligne",
  age_group_breakdown: "Répartition par groupe d'âge",
  admin: "Administración",
  viewer: "Visualisateur",
  organizer: "Organisateur",
   Admin: "Administración",
  Viewer: "Visualisateur",
  Organizer: "Organisateur",
  logout: "Se déconnecter",
  transactions: "Transactions",
  participant_pulse: "Pouls du participant",
  flow_of_funds: "Flux de fonds",
  merchant_wallet_balance: "Solde moyen du portefeuille du commerçant",
  total_disbursed: "Total des décaissements",
  recipient_wallet_balance: "Solde moyen du portefeuille du bénéficiaire",
  total_spent: "Total des dépenses",
  demographic_insight: "Aperçu démographique",
  spending_by_gender: "Catégorie de produits (dépenses par sexe)",
  no_product_category : "Catégorie de produit non ajoutée au projet",
  spending_pattern: "Schéma des dépenses",
  volume_transacted: "Volume transigé",
  total_txns: "Total Txns",
  average_txn: "Moyenne Txn",
  getting_started: "Pour commencer",
  welcome_to_umoja: "Bienvenue dans la suite de gestion Umoja",
  project_info:
    "Il n'y a pas encore de projets actifs dans votre espace de travail. En attendant, nous vous invitons à en savoir plus en visitant le centre d'aide.",
  last_7_days: "Dernier 7 jours",
  programme_home: "Accueil gestion",
  register_program_card: "Enregistrer la carte du programme",
  register_card_confirm:
    "Cliquez sur Enregistrer la carte pour lier une carte Umoja au portefeuille de <strong>{{ wallet }}</strong>",
  unregister_card_confirm:
    "Êtes-vous sûr de vouloir désenregistrer cette carte Umoja du portefeuille de <strong>{{ wallet }}</strong>?",
  confirm_role_change: "Êtes-vous sûr de vouloir changer le rôle de <strong>{{ user }}</strong> d' <strong>{{ oldRole }}</strong> à <strong>{{newRole}}</strong>?",
  same_role_change:  "<strong>{{ user }}</strong> a déjà le privilège d' <strong>{{newRole}}</strong>",
  successfully_unregistered_card: "Réussir à se désinscrire de",
  more: "Plus",
  search: "Recherche",
  no_available_cards: "Aucune carte disponible",
  no_data: "Pas de données",
  volume: "volume",
  // Payment Widget
  disburse: "Débourser",
  disbursement_amount: "Montant du décaissement",
  disburse_to: "Débourser à",
  funded_by: "Financé par",
  all_wallets: "Toutes les portefeuilles",
  primary_wallet: "Portefeuille principal",
  total_disbursement: "Total des décaissements",
  total_refunded: "Total remboursé",
  preview_disbursement: "Aperçu du décaissement",
  all_recipients: "Total des Bénéficiaires",
  recipients: "Bénéficiaires",
  all_merchants: "Total des Marchands",
  wallet_balance: "Solde du portefeuille",
  disburse_funds: "Débourser des fonds",
  latest_disbursements: "Derniers décaissements",
  amount: "Montant",
  send_cash_to_wallet: "Envoyer de l'argent dans des portefeuilles",
  master_wallet: "Portefeuille principal",
  master_wallet_balance: "Solde du portefeuille principal",
  select_target_group: "Sélectionnez le groupe cible",
  no_funds : "Aucun fonds n'est disponible pour le décaissement",
  please_endure : "Veuillez vous assurer que le portefeuille principal de votre projet a été financé par votre organisation",
  get_started : "Commencez par créer votre premier projet",
  sent: "Envoyé",
  received: "A reçu",
  wallet_received: "Portefeuille reçu",
  sent_to: "Envoyé à",
  send_to: "Envoyer à",
  sending: "Envoi en cours",
  total_send: "Envoi total",
  new_master_wallet_balance: "Nouveau solde du portefeuille principal",
  send_funds: "Envoyez de l'argent",
  each: "chaque",
  no_recent_activity: "Aucune activité récente",
  success_transaction : "Transaction terminée avec succès",
  failed_transaction: "Erreur lors de la réalisation de votre transaction",
  // sidebar
  home: "Accueil",
  alerts: "Alertes",
  // breadcrumb
  account_settings: "Paramètres Compte",
  // Additional resource page
  platform_news: "Nouvelles de la plate-forme",
  legal: "Mentions légales",
  company_support: "Entreprise et soutien",
  management_status: "Statut de la gestion",
  umoja_commerce: "Commerce Umoja",
  umoja_wallet: "Portefeuille Umoja",
  umoja_card: "Carte Umoja",
  terms_of_service: "Conditions d'utilisation",
  privacy_policy: "Politique de confidentialité",
  GDPR_privacy_policy: "GDPR & Politique de confidentialité",
  emerging_impact_services: "Services d'impact émergent",
  umoja_brand_kit: "Kit de marque Umoja",
  ecosystem_case_studies: "Études de cas de l'écosystème",
  help_center_support: "Centre d'aide et support",
  careers: "Carrières",
  // Non logged-in page
  login_page_description:
    "Umoja est une plateforme unifiée pour les paiements et les versements numériques sur les marchés émergents.",
  sign_into_your_account: "Connectez-vous à votre compte",
  learn_more_about_umoja: "En savoir plus sur Umoja",
  contact_support: "Contacter le support",
  having_trouble_signin: "Vous avez des difficultés à vous connecter ?",
  sign_in: "S'identifier",
  your_email: "Votre e-mail",
  password: "Mot de passe",
  forgot_password: "Mot de passe oublié",
  submit: "Soumettre",
  email: "Courriel",
  email_required: "Un courriel est requis",
  valid_email: "Veuillez saisir une adresse électronique valide",
  password_required: "Mot de passe requis",
  password_length_validation: "Mot de passe doit être d'au moins 8 caractères",
  reset_password: "Réinitialiser le mot de passe",
  // Umoja toast and alerts
  problem_making_request: "Il y a eu un problème lors de la demande",
  disbursement_permission_error:
    "Vous devez être au moins un organisateur pour débloquer des fonds.",
  invalid_credentials_error:
    "L'email et le mot de passe fournis sont invalides",
  signin_error: "Il y a eu un problème pour se connecter",
  password_changed_successfully: "Le mot de passe a été modifié avec succès",
  error_submitting_request:
    "Il y a eu un problème pour soumettre votre demande",
  problem_creating_updating_project: "Il y a eu un problème lors de la création ou de la mise à jour de votre projet",
  creating_updating_project_successful: "Le projet a été mis à jour avec succès !",
  role_changed_successfully: "Le rôle a été modifié avec succès",
  request_not_completed: "Cette demande n'a pas pu être complétée, réessayez",
  problem_adding_details:
    "Un problème est survenu lors de l'ajout de détails à votre projet",
  successfully_enrolled_users: "Utilisateurs inscrits avec succès",
  error_uploading_excel_file: "Erreur lors du téléchargement du fichier excel",
  review_imported_data: "Examiner les données importées",
  imported_data: "Données importées",
  verify_imported_data_text:
    "Veuillez vérifier que ce tableau de données affiche les colonnes correctes. Umoja utilisera ce format pour générer des analyses avec les données de votre programme.",
  remove: "Supprimer",
  yes_correct: "Oui, c'est exact !",
  merchants_uploaded_successfully: "Les commerçants ont téléchargé avec succès",
  participants_uploaded_successfully: "Participants téléchargés avec succès",
  problem_publishing_project: "Il y a eu un problème pour publier votre projet",
  wallet_updated_successfully: "Le portefeuille a été mis à jour avec succès",
  wallet_paused_successfully: "Portefeuille mis en pause avec succès",
  wallet_resumed_successfully: "Reprise du portefeuille avec succès",
  please_try_again: "Veuillez réessayer",
  register_cards_permission_error:
    "Désolé, seuls les organisateurs de projets et les administrateurs peuvent enregistrer des cartes.",
  problem_registering_umoja_card:
    "Il y a eu un problème lors de l'enregistrement de cette carte Umoja.",
  successfully_registered_card: "Enregistrement réussi de la carte Umoja",
  error_updating_email_address:
    "Une erreur s'est produite lors de la mise à jour de votre adresse électronique",
  successfully_updated_password:
    "Le mot de passe a été mis à jour avec succès !",
  successfully_updated_email:
    "L'e-mail a été mis à jour avec succès ! Vérifiez votre boîte de réception pour confirmer",
  error_updating_password:
    "Une erreur s'est produite lors de la mise à jour du mot de passe de votre adresse électronique",

  // demographics insight data
  female_total_transactions: "FEMELLE",
  male_total_transactions: "HOMMES",
  lgbtq_total_transactions: "LGBTQ",
  other_total_transactions: "AUTRES",

  // redeem invite
  first_name: "Prénom",
  last_name: "Nom de famille",
  redeem_invite: "Rédiger l'invitation",
  successful: "Réussi",
  enter_first_name: "Entrez votre prénom",
  enter_last_name: "Entrez votre nom de famille",
  first_name_required: "Prénom requis",
  last_name_required: "Nom de famille requis",
  only_letters_allowed: "Seules les lettres sont autorisées sans espace",
  password_must_match: "Les mots de passe doivent correspondre",
  character_is_too_short: "Le caractère est trop court",

  next : "Suivant",
  previous : "Précédent",
  first : "Premier",
  page : "Page",
  of : "de",
  last : "Dernier",
  paused: "Pausé",
  active: "Actif",
  reset: "Réinitialiser",
  sort_asc: "Tri croissant",
  sort_desc: "Tri décroissant",
  sort_A:"Trier de A à Z",
  sort_z:"Trier Z-A",


  //Forget Password
  forgot_password_header : "Réinitialisation du mot de passe",
  email_containing_a_link : "Un email contenant un lien pour réinitialiser votre mot de passe vous a été envoyé",
  forgot_password_wrapper_text :  "Vous n'avez pas reçu le courrier ?",
  forgot_password_wrapper_option_header : "Si vous n'avez pas reçu le courrier dans les 5 minutes, l'une des situations suivantes a pu se produire :",
  forgot_password_wrapper_option_text_one : "L'e-mail se trouve dans votre dossier spam/junk",
  forgot_password__wrapper__option_text_two : "Nous ne pouvons pas livrer l'e-mail à l'adresse fournie. (Généralement à cause du pare-feu ou du filtrage de l'entreprise)",
  close: "fermer",
  
  //whatsapp Login
  pin_required : "Le code PIN est requis",
  pin_must_be_at_least : "Le code PIN doit comporter au moins 4 caractères numériques",
  pin_validated_successfully : "Pin Umoja validé avec succès",
  pin_invalid : "PIN invalide" ,
  error_validating_pin: "Erreur de validation du code PIN",
  invalid_pin : "Ce code PIN est invalide. Veuillez saisir le code PIN actuel pour continuer.",
  pin_validated_successfully_continue_in_chat: "Le code PIN Umoja a été validé avec succès, veuillez continuer à chatter avec Whatsapp",
  
  enter_pin_code : "Entrez votre code PIN pour accéder",
  confirm_pin_code : "Confirmez votre code PIN",
  
  confirmPinCode : "Confirmer le code PIN",
  confirm_pin : "Confirmer le code PIN",
  reset_pin : "Réinitialiser le code PIN",
  new_pin_code: "Nouveau code PIN",
  invalid_or_expired : "Jeton invalide ou expiré",
  invalid_pin_format: "Format de PIN invalide",
  current_pin_code: "Current PIN",
 

  //changement du code PIN sur whatsapp
  current_pin_error : "Le PIN actuel et le nouveau PIN ne peuvent pas être les mêmes",
  new_pin_error : "Le nouveau code PIN doit correspondre au code PIN de confirmation",
  pin_change_successfully : "Modification du code PIN Umoja réussie",
  error_submitting_pin_change : "Erreur lors de la modification du code PIN, veuillez réessayer",
  pin_change_successfully_continue_in_chat : "Changement de PIN Umoja réussi, veuillez continuer dans le chat whatsapp",

  //Organizations
  organizations:"Les organisations",
  revoke_access: "Révoquer un accès",
  revoke_access_confirmation: "Are you sure you want to revoke access to <strong> {{ organization }} </strong>?",
  activate_organization: "Activate Organization",
  activate_organization_confirmation: "Are you sure you want to enable access for <strong> {{ organization }} </strong>?",
  add_demo_disbursement: "Add Disbursement",
  add_demo_transactions: "Add Transactions",
  organization_activated_successfully: "Organization access activated successfully",
  organization_revoked_successfully: "Organization Access revoked successfully",
  organization_revoke_failed: "Revoking organization access failed",
  organization_activate_failed: "Activating organization access failed",
  activate: "Activate",
  add_money: "Ajoutez de l'argent",
  organization_type: "Type d'organisation",
  action: "Action",
  customer_email: "Email du client",
  create_new_organization: "Créez une nouvelle organisation",
  create_organization : "Créer une nouvelle organisation pour Umoja",
  create_organization_submit : "Créer une organisation",
  organization_details : "Détails de l'organisation",
  program_information : "Informations sur le programme",
  administrator_information : "Informations sur l'administrateur",
  organisation_créée_successfully : "Organisation créée avec succès",
  error_creating_organization : "Il y a eu un problème lors de la création de votre organisation",
  organization_name_input_placeholder : "Entrez le nom de l'organisation",
  organization_name_input_label : "Nom de l'organisation",
  organization_name_input_required : "Le nom de l'organisation est obligatoire",
  organization_type_input_placeholder : "Saisissez le type d'organisation",
  organization_type_input_label : "Type d'organisme",
  organization_type_input_required : "Organization Type is required",
  organization_billing_email_input_placeholder : "Enter Billing Email",
  organization_billing_email_input_label : "Courriel de facturation",
  organization_billing_email_input_required : "L'adresse électronique de facturation est obligatoire", 
  organization_contact_email_input_placeholder : "Saisissez l'adresse électronique du contact",
  organization_contact_email_input_label : "Courriel du contact",
  organization_contact_email_input_required : "L'adresse électronique du contact est obligatoire",
  first_name_input_placeholder : "Entrer le prénom",
  first_name_input_label : "Prénom",
  first_name_input_required : "FirstName Name est obligatoire",
  last_name_input_placeholder : "Entrer le nom de famille",
  last_name_input_label : "Nom",
  last_name_input_required: "Le nom de famille est requis",
  select_country : "Sélectionner le pays",
  select_language : "Sélectionner la langue",
  select_currency: "Sélectionner la devise",
  account_deactivated_error: "Votre compte a été désactivé. Veuillez contacter l'équipe de vente.",
  suspended : "Suspendu",
  add_organization : "Ajouter une organisation",
};
