import "./Login.css"
import { useForm } from "react-form";
import { Button } from "../../components";
import { PinInput } from "./inputs/PinInput";
import { useMemo, useState } from "react";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { Trans, useTranslation } from "react-i18next";
import * as qs from "query-string";
import { useLocation } from "react-router-dom";

export const LoginForm = (props: any) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [success, setSuccess] = useState(false)

  const { token, platform } = qs.parse(location.search);  
  const defaultValues = useMemo(
    () => ({
      credentials: {
        pin: ""
      },
    }),
    []
  );

  const LoginFormInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
    try {
        const response = await fetch(
          process.env.REACT_APP_WHATSAPP_LOGIN_ENDPOINT as string,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pin_code: values.credentials.pin,
              token: token,
              platform: platform
            }),
          }
        );
        const json = await response.json();
        if (!json.ok) {
          setLoading(false);
          if (json.error === "TOKEN_NOT_FOUND") {
            UmojaToast.show({
            message: t('invalid_or_expired'),
            intent: "danger",
            icon: "error",
            });
             setLoading(false)
             return;
           }
          UmojaToast.show({
            message: t('pin_invalid'),
            intent: "danger",
            icon: "error",
          });
          return;
        } else {
          setLoading(false);
          setSuccess(true);
          UmojaToast.show({
            message: t('pin_validated_successfully'),
            intent: "success",
          });
        }
      } catch (error) {
        UmojaToast.show({
          message: t('error_validating_pin'),
          intent: "danger",
          icon: "error",
        });
        setLoading(false);
      }
    },
    defaultValues,
    // debugForm: true,
  });

  let pinValue = LoginFormInstance.getFieldValue("credentials.pin");

  const { meta } = LoginFormInstance;

  return (
      <LoginFormInstance.Form className="login__form">
        {
        success ? (
          <div className="login__success__container">
            <h3>
              {
                t('pin_validated_successfully_continue_in_chat')
              }
            </h3> 
          </div>  
          ): (
            <div>
             <h3>
               <Trans i18nKey="enter_pin_code" />
            </h3>
            <div>
              <PinInput />
            </div>
            <div className="login__card-input-wrapper">
            <Button
              className="login__button"
              type="submit"
              minimal
              disabled={
                !(meta.canSubmit && meta.isTouched && pinValue)
              }
              loading={loading}
              text={`${t('sign_in')}`}
              intent="primary"
            />
          </div> 
          </div>
          )
        }
      </LoginFormInstance.Form>
  );
};
