import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components";

interface PasswordInputProps {
  disabled: boolean;
  email: string
}

export const OrganizationEmailInput = ({ disabled, email }: PasswordInputProps) => {
  const { t } = useTranslation();

  const validateInput = (email: string) => {
  if (!email) {
    return t("email_required_validation");
  }
  if (!isValidEmail(email)) {
    return t("valid_email_required_validation");
  }
  return false;
}
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("settings.email", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: email,
  });

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="email_address" />
      </label>
      <div style={{ height: 50 , width: 250 }}>
        <TextInput
          {...getInputProps()}
          type="email"
          placeholder="c.hale@weyland-industries.io"
          // disabled={true}
          value={email}
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};



// https://codesandbox.io/s/react-form-demo-q9mgm?file=/src/index.js:4838-5082
function isValidEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
