import { useQuery } from "react-query";
import { getProgramDisbursementHistory } from "../services";

export default function useProjectDisbursementsHistory(organizationId: string) {
  return useQuery(
    ["disbursements", organizationId],
    () => getProgramDisbursementHistory(parseInt(organizationId)),
    {
      initialData: { disbursements: [] },
    }
  );
}
