import { useField } from "react-form";
import { TextInput } from "../../../components";
import { Trans, useTranslation } from "react-i18next";


export const NewPasswordInput = () => {
  const { t } = useTranslation();

  const validateInput = (password: string) => {
      if (password.length < 1) {
        return t("password_required");
      }
      if (password.length < 8) {
        return t("password_length_validation");
      }
      return false;
    }
  const NewPasswordInputInstance = useField("password", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const {  meta: { error, isTouched }, getInputProps } = NewPasswordInputInstance;

  return (
    <li className="organization__form-input-list-item">
      <label>
        <Trans i18nKey="password" />
      </label>
      <div className="input_container">
        <TextInput
          {...getInputProps()}
            type="password"
            placeholder={t("new_password")}
            // name="password"
            className="input_field"
        />
        {isTouched  && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
        </div>
    </li>
  );
};
