import "./DashboardHome.css";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizations } from "../../services";
import { useAccount, useOrganizations, useProjects, useProjectStats, useProjectTransactionsHistory } from "../../hooks";
import { Button, Layout, UmojaHeader } from "../../components";
import { Spinner } from "@blueprintjs/core";
import { GlobalOperations } from "./GlobalOperations";
import useProjectDisbursementsHistory from "../../hooks/useProgramDisbursementHistory";
import useWalletAvgBalance from "../../hooks/useWalletAvgBalance";
import DefaultProjectView from "./DefaultProjectView";
import { useTranslation } from "react-i18next";
import useDemographicInsightStats from "../../hooks/useDemographicInsightStats";
import useProjectActivityStats from "../../hooks/useProgamActivityStats";

function EmptyOrganizations() {
  const account = useAccount();
  return (
    <Layout.Container>
      <UmojaHeader title="DashboardHome" user={account.data} />
      <div>
        <p>It looks like you are not part of any organizations.</p>
        <div className="empty__dashboard_create-org-wrapper">
          <Link
            className="bp3-button bp3-intent-primary"
            to="/dashboard/settings/organizations/create"
          >
            Create a new organization
          </Link>
          <Button text="View pending invites" />
        </div>
      </div>
    </Layout.Container>
  );
}

// @ts-ignore
function OrganizationsHomeScreen({ projects }) {
  const params = useParams<{ organizationId: string }>();
  const account = useAccount();
  const orgDetails = useOrganizations();
  // const walletsQuery = useWallets(params.organizationId);

  const { t } = useTranslation()


  // get the selected organization details
  const selectedOrganization = orgDetails?.data?.find(
    (data: any) => data.id === +params?.organizationId
  );

  const project = projects?.data?.programs?.find(
    (data: any) => data.organization_id === +params?.organizationId
  );

  const { data } = useProjectTransactionsHistory(
    params?.organizationId,
    project?.id
  );

  // get project main stats
  const projectStats = useProjectStats(params?.organizationId, project?.id);

  // get demographics insight data
  const demographicInsightStats = useDemographicInsightStats(params?.organizationId, project?.id);
  const activityStats = useProjectActivityStats(params?.organizationId, project?.id);
  
  
  // get average wallet balance
  const averageWalletBalance = useWalletAvgBalance(params?.organizationId, project?.id);

  // filter out transactions that are not disbursements
  const transactions = data?.transactions?.filter((transaction: any) => transaction.type !== "DISBURSEMENT");

  const disbursementHistory = useProjectDisbursementsHistory(
    params?.organizationId
  );

  // combine both disbursement history and transactions
  const disbursements = disbursementHistory?.data.disbursements;

  for (let i = 0; i < disbursements?.length; i++) {
    disbursements[i].type = "Disbursement";
  }

  const history = [...disbursements || [], ...transactions];

  // if (walletsQuery.isLoading) {
  //   return null;
  // }

  return  project ? (
    <Layout.Container>
      <UmojaHeader title={selectedOrganization?.name} user={account.data} />
      <GlobalOperations
        programWallet={selectedOrganization}
        transactionsHistory={history}
        project={project}
        averageBalance={averageWalletBalance}
        projectStats={projectStats?.data}
        projects={projects?.data?.programs}
        demographicInsightStats={demographicInsightStats?.data}
        activityStats={activityStats?.data}
      />
    </Layout.Container>
  ): (
    <Layout.Container>
      <UmojaHeader title={t('programme_home')} user={account.data} />
      <DefaultProjectView displayPaymentWidget={true} displayProjectHeading={true} programWallet={selectedOrganization} />
    </Layout.Container>
  );
}

function DashboardHomeScreen() {
  const { data: organizations } = useQuery("organizations", getOrganizations, {
    placeholderData: null,
  });

  const projects = useProjects();


  if (organizations === null || projects === null) {
    return (
      <Layout.Container>
        <div className="dashboard__spinner">
          <Spinner />
        </div>
      </Layout.Container>
    );
  }

  return organizations?.length > 0 ? (
    <OrganizationsHomeScreen projects={projects} />
  ) : (
    <EmptyOrganizations />
  );
}

export default DashboardHomeScreen;
