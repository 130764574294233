import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks";

interface PublicRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, ...rest } = props;
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Redirect to="/dashboard" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
