import { Card, Classes } from "@blueprintjs/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";
import { Button } from "../../components";
import { useAuth } from "../../hooks";
import { AccountInformationForm } from "./forms/AccountInformationForm";
import { ChangePasswordForm } from "./forms/ChangePasswordForm";

interface AccountSettingsProps {
  account: UseQueryResult<any, unknown>;
}

export const AccountSettings = ({ account }: AccountSettingsProps) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { isLoading } = account;
  const [editEmailMode, setEditEmailMode] = useState(false);
  const [editPasswordMode, setEditPasswordMode] = useState(false);
  return (
    <div>
      <Card
        className={`settings__card-wrapper ${isLoading && Classes.SKELETON}`}
      >
        <AccountInformationForm account={account} editEmailMode={editEmailMode} setEditEmailMode={setEditEmailMode} setEditPasswordMode={setEditPasswordMode} />
      </Card>
      <Card className="settings__card-wrapper">
        <ChangePasswordForm account={account} editPasswordMode={editPasswordMode } setEditPasswordMode={setEditPasswordMode} setEditEmailMode={setEditEmailMode} />
      </Card>
      <div className="setting_logout_btn_container">
        <Button
          className="setting_logout_btn"
          intent="primary"
          text={`${t("logout")}`}
          onClick={() => logout()}
        />
      </div>
    </div>
  );
};
