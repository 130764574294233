import "./ResetPassword.css";
import { useMemo, useState } from "react";
import { useForm } from "react-form";
import { Card } from "@blueprintjs/core";
import { useHistory, useLocation } from "react-router";
import * as qs from "query-string";
import { Button } from "../../components";
import { PasswordInput } from "./inputs/PasswordInput";
import { ConfirmPasswordInput } from "./inputs/ConfirmPasswordInput";
import LoginLayout from "../../components/LoginLayout";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { useTranslation } from "react-i18next";

const PasswordResetScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { token, email } = qs.parse(location.search);

  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(
    () => ({
      password: "",
      confirmPassword: "",
    }),
    []
  );

  const PasswordResetInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_REDEEM_RESET_PASSWORD as string,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token, email, password: values.password }),
          }
        );

        if (!response.ok) {
          throw new Error("Error submitting your request, try again");
        }
        setLoading(false);
        localStorage.removeItem('access_token');
        UmojaToast.show({
          message: `${t("password_changed_successfully")}`,
          intent: "success",
        });
        history.push("/");
      } catch (error) {
        UmojaToast.show({
          message: `${t("error_submitting_request")}`,
          intent: "danger",
          icon: "error",
        });
        setLoading(false);
      }
    },
    defaultValues,
    // debugForm: true,
  });

  let passwordValue = PasswordResetInstance.getFieldValue("password");
  let confirmPasswordValue = PasswordResetInstance.getFieldValue("confirmPassword");

  const { meta } = PasswordResetInstance;

  return (
    <LoginLayout>
      <Card className="reset-password-card">
        <div className="reset-password-form-wrapper">
          <PasswordResetInstance.Form>
            <h1 className="reset-password-form-header">{`${t('reset_password')}`}</h1>
            <div>
              <div className="reset-password-label">{`${t('password')}`}</div>
              <PasswordInput />
            </div>
            <div>
              <div className="reset-password-label">{`${t('confirm_password')}`}</div>
              <ConfirmPasswordInput  passwordError={passwordValue !== confirmPasswordValue ? t("password_must_match") : null } />
            </div>
            <div>
            </div>
            <div className="reset-password-button-wrapper">
              <Button
                className="reset-password-button"
                type="submit"
                disabled={
                  !(meta.canSubmit && meta.isTouched && passwordValue && (passwordValue === confirmPasswordValue))
                }
                loading={loading}
                text={`${t('submit')}`}
                intent="primary"
              />
            </div>
          </PasswordResetInstance.Form>
        </div>
      </Card>
    </LoginLayout>
  );
};

export default PasswordResetScreen;
