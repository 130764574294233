import "./ErrorScreen.css";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// TODO: pass in from ErrorBoundary
let defaultError = new Error("The page you're looking for could not be found.");
defaultError.name = "Page not found";

interface NotFoundScreenProps {
  error?: Error;
}

function NotFoundScreen({ error = defaultError }: NotFoundScreenProps) {
  return (
    <div className="error_screen__wrapper">
      <div>
        <div className="error_screen__icon_wrapper">
          <ReactSVG
            src="UmojaDarkSvg.svg"
            beforeInjection={(svg) => {
              svg.classList.add("error_screen__icon_svg");
            }}
          />
          <h1 className="error_screen__icon_text">umoja</h1>
        </div>
        <h1 className="error_screen__title">Oops! {error.name}.</h1>
        <h2 className="error_screen__subtitle">{error.message}</h2>
        <Link
          to="/dashboard"
          className="error_screen__link"
        >
          Back to Home
        </Link>
      </div>
      <div className="error_screen__content_wrapper">
        <h1 className="error_screen__status_code">404</h1>
      </div>
    </div>
  );
}
export default NotFoundScreen;
