import { useQuery } from "react-query";

import { getGroupWallet } from "../services";

export default function useGroupWallets(organizationId: string | number  , projectId: string | number, groupId: string | number  ) {

  const queryData =  useQuery(
    ["groupWallets", organizationId, projectId, groupId],
    () => getGroupWallet(organizationId, projectId, groupId),
    { initialData: [], enabled: !!groupId && !!organizationId && !!projectId}
  );

  return queryData.data.map((wallet: any) => {
    return wallet.umoja_wallet_id
  })
}
