import { Classes } from "@blueprintjs/core";

export const FeaturedChartSkeletonLoader = () => {
  return (
    <div className={Classes.SKELETON}>
      <div className="featured__charts-outer-wrapper">
        <div className="featured__charts-header-area" />
        <div className="featured__charts-chart-area">
          <span />
        </div>
      </div>
    </div>
  );
};
