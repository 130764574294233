import "./ResetPin.css";
import { useMemo, useState } from "react";
import { useForm } from "react-form";
import { useLocation } from "react-router";
import * as qs from "query-string";
import { Button } from "../../components";
import { PinInput } from "./inputs/PinInput";
import { CurrentPinInput } from "./inputs/CurrentPinInput";
import { ConfirmPinInput } from "./inputs/ConfirmPinInput";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { useTranslation } from "react-i18next";
import { Layout } from "../WhatsappLogin/Layout";

const WhatsappPinResetScreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { token, platform } = qs.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)

  const defaultValues = useMemo(
    () => ({
      current_pin: "",
      pin: "",
      confirmPin: "",
    }),
    []
  );
  
  const PinResetInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_WHATSAPP_CHANGE_PIN_ENDPOINT as string,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token,
              old_pin_code: values.current_pin,
              new_pin_code: values.pin,
              platform: platform
            }),
          }
        );
        const json = await response.json();
        if (!json.ok) {
            if (json.error === "TOKEN_NOT_FOUND") {
               UmojaToast.show({
                  message: t('invalid_or_expired'),
                  intent: "danger",
                  icon: "error",
               });
              setLoading(false)
             return;
            }
          if (json.error === "INVALID_PIN") {
               UmojaToast.show({
                  message: t('invalid_pin'),
                  intent: "danger",
                  icon: "error",
               });
              setLoading(false)
             return;
          }
          UmojaToast.show({
          message: t('error_submitting_pin_change'),
          intent: "danger",
          icon: "error",
          });
          return
        } else {
           setLoading(false);
           setSuccess(true)
           UmojaToast.show({
               message: t('pin_change_successfully'),
               intent: "success",
            });
        }
      } catch (error) {
        UmojaToast.show({
          message: t('error_submitting_pin_change'),
          intent: "danger",
          icon: "error",
        });
        setLoading(false);
      }
    },
    defaultValues,
    // debugForm: true,
  });

  let pinValue = PinResetInstance.getFieldValue("pin");
  let confirmPinValue = PinResetInstance.getFieldValue("confirmPin");
  let currentPinValue = PinResetInstance.getFieldValue("current_pin");

  const { meta } = PinResetInstance;

  return (
    <Layout>
      <PinResetInstance.Form className="pin__reset__form">
        {
          success ? (
               <div className="pin__reset__success">
                   <h3>
                   {
                      t('pin_change_successfully_continue_in_chat')
                   }
                   </h3> 
               </div>
              ) : 
                (
                <div className="pin__reset__input_wrapper">
                    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>{`${t('reset_pin')}`}</div>
                    <CurrentPinInput />
                    <PinInput currentPinError={currentPinValue === pinValue ? t('current_pin_error'): null} />
                    <ConfirmPinInput newPinError={pinValue !== confirmPinValue ? t('new_pin_error') : null} />
                    <Button
                      className="reset-password-button"
                      type="submit"
                      disabled={
                            !(meta.canSubmit && meta.isTouched && pinValue && (pinValue === confirmPinValue))
                          }
                      loading={loading}
                      text={`${t('submit')}`}
                      intent="primary"
                      />                 
                </div>
              )
            }
          </PinResetInstance.Form>
    </Layout>
  );
};

export default WhatsappPinResetScreen;
