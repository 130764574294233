import { Button } from "@blueprintjs/core";
import { ReactSVG } from "react-svg";
import { Trans, useTranslation } from "react-i18next";
import { useFormContext } from "react-form";
import { CurrencyInput } from "../../../components/Widgets/PaymentWidget/panels/DisbursementPanel/inputs/CurrencyInput";

export interface UmojaDisbursementInputsProps {
  organizationName: any
  currency: string;
}

export const AddMoneyDialog = ({
  organizationName,
  currency
}: UmojaDisbursementInputsProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const { amount } = values;
  return (
    <div className="payment__widget-content_wrapper">
      <h2 className="master-wallet__panel-title" style={{ marginTop: "10px"}}>
        {`Disburse Funds (${currency})`}
      </h2>
      <div className='disbursement__panel-section_wrapper'>
        <div className={
          `${amount
            ? "disbursement__panel-wallet_indicator_wrapper--active"
            : ""
          } disbursement__panel-wallet_indicator_wrapper`}
        >
          <div className="disbursement__panel-wallet_indicator--inner">
            <ReactSVG
              src="/disburseAmount.svg"
              className={`${amount
                ? "disbursement__panel-wallet_indicator--active"
                : ""}`
              }
            />
            <div className={`${amount
              ? "disbursement__panel-wallet_indicator--active-text"
              : "disbursement__panel-wallet_indicator--text"}`}
            >
              <p className="no-bottom-margin">{`${t("disburse")}`}</p>
              <p className="no-bottom-margin">{`${t("amount")}`}</p>
            </div>
          </div>
          <CurrencyInput
            numberFormatClassName={`${amount ? "currency__input--filled" : ""}`}
            currency={currency}
          />
        </div>
        <div className={
          `${organizationName
            ? "disbursement__panel-wallet_indicator_wrapper--active"
            : ""
          } disbursement__panel-wallet_indicator_wrapper`}
          onClick={() => {}}
          style={{ pointerEvents: 'none' }}
        >
          <div className="disbursement__panel-wallet_indicator--inner">
            <ReactSVG
              src={"/disburseTo.svg"}
              className={`${organizationName
                ? "disbursement__panel-wallet_indicator--active"
                : ""
                }`
              }
            />
            <div className={`${organizationName
              ? "disbursement__panel-wallet_indicator--active-text"
              : "disbursement__panel-wallet_indicator--text"}`}
            >
              <p className="no-bottom-margin">
                {`${organizationName
                  ? organizationName
                  : t("disburse_to")}`
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <Button
        text={<Trans i18nKey="Submit" />}
        fill
        minimal
        className="disbursement__details-button"
        onClick={() => {}}
      />
    </div>
  );
};
