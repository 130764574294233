import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

export const EmailInput = () => {
  const { t } = useTranslation();
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("credentials.email", {
    validate: (email: string) => validateEmail(email),
    defaultIsTouched: false,
  });
  return (
    <div className="login__card-input-wrapper">
      <TextInput
        {...getInputProps()}
        required
        autoFocus
        name="email"
        type="email"
        placeholder={`${t('email')}`}
        height={5}
      />
      {isTouched && <p style={{ color: "red" }}>
        {error?.includes("required") && t("email_required")}
        {error?.includes("valid") && t("valid_email")}
      </p>}
    </div>
  );
};

const validateEmail = (email: string) => {
  if (!email) {
    return "An email is required";
  }
  if (!isValidEmail(email)) {
    return "Please enter a valid email address";
  }
  return false;
};

// https://codesandbox.io/s/react-form-demo-q9mgm?file=/src/index.js:4838-5082
const isValidEmail = (email: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
