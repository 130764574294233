import { useHistory } from "react-router-dom";

const useAuth = () => {
  const user = localStorage.getItem("access_token");
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("selected_organization_id");

    history.push("/");
  };
  return { user, logout };
};

export default useAuth;
