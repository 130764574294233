import { useField } from "react-form";
import { Trans } from "react-i18next";
import { TextInput } from "../../../../../components";
import { useTranslation } from "react-i18next";

interface ProjectTitleInputProps {
  defaultValue: string;
  readOnly: boolean;
}

export const ProjectTitleInput = ({
  defaultValue,
  readOnly,
}: ProjectTitleInputProps) => {
 
  const { t } = useTranslation()
  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_title_validation");
  }
  return false;
};

  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("project_name", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: defaultValue || "",
  });

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="project_title" />
      </label>
      <div style={{ height: 50, width: 200 }}>
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={defaultValue ? defaultValue : "Enter Project Title"}
          name="projectName"
          readOnly={readOnly}
        />
        {isTouched && !readOnly && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

