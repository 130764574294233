import "./DashboardSidebar.css";
import { Icon, Menu } from "@blueprintjs/core";
import { SidebarLinks } from "./SidebarLinks";
import { UmojaDropdownSelector } from "../../components/Dropdown/UmojaDropdownSelector";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { AtLeast } from "../../hooks/useRole";

export type Organization = {
  id: number;
  name: string;
  type: string;
  account_id: number;
  account_role_id: number;
  billing_email: string;
  contact_email: string;
  country_id: number;
  created_at: string;
  organization_id: number;
  shufti_company_number: string;
  shufti_country_code: string;
  stripe_customer_id: string;
};

type DashboardSidebarProps = {
  organizations: any;
  selectedOrganization: Organization;
  role: any
};

export function DashboardSidebar({
  organizations,
  selectedOrganization,
  role
}: DashboardSidebarProps) {
  const { t } = useTranslation();
  return (
    <div className="dashboard__sidebar">
      <Menu className="sidebar__menu">
        <UmojaDropdownSelector
          organizations={organizations}
          selectedOrganization={selectedOrganization}
        />
      </Menu>
      <div className="sidebar__menu_secondary-wrapper">
        <div className="sidebar__menu_secondary-subwrapper">
          <Menu className="sidebar__menu">
            <SidebarLinks
              selectedOrganization={selectedOrganization}
              config={[
                {
                  link: "home",
                  activeStyle: "#6200EE",
                  icon: "home",
                  text: `${t("home")}`,
                },
                {
                  link: "activity",
                  activeStyle: "#6200EE",
                  icon: "exchange",
                  text: `${t("activity")}`,
                },
                {
                  link: "wallets",
                  activeStyle: "#6200EE",
                  icon: "credit-card",
                  text: `${t("wallets")}`,
                },
              ]}
            />
          </Menu>
          <Menu className="sidebar__menu">
            <SidebarLinks
              selectedOrganization={selectedOrganization}
              config={[
                {
                  link: "team",
                  activeStyle: "#6200EE",
                  icon: "people",
                  text: `${t("team")}`,
                },
              ]}
            />
            <div className="sidebar__item">
              <NavLink
                to="/dashboard/settings"
                activeStyle={{ color: "#6200EE" }}
              >
                <Icon icon="cog" />
                <Trans i18nKey="settings" />
              </NavLink>
            </div>
            {
              AtLeast.SuperAdmin(role) && (
              <div className="sidebar__item">
                <NavLink
                  to="/dashboard/organizations/list"
                  activeStyle={{ color: "#6200EE" }}
                >
                  <Icon icon="office" />
                  <Trans i18nKey="organizations" />
                </NavLink>
              </div>
              )
            }
          </Menu>
        </div>
        <Menu className="sidebar__menu">
          <div className="sidebar__item">
            <a
              href="https://www.notion.so/emergingimpact/Umoja-Help-Center-6761c61df7a44a8db726fe0148a0a629"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="help" />
              {t("support")}
            </a>
          </div>
        </Menu>
      </div>
    </div>
  );
}

export default DashboardSidebar;
