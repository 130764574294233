export async function getApiVersion() {
    const response = await fetch(
      `${process.env.REACT_APP_VERSION_ENDPOINT}/version`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const data = await response.json();
    return data;
  }