import React, { FunctionComponent } from "react";
import { RecipientWallet } from "../../components/Widgets/PaymentWidget/panels/TargetGroupPanel";
import WalletGroupButton from "./WalletGroupButton";


interface GroupInfoProps {
  groups: RecipientWallet[],
  setCreateView: Function
  activeGroup: RecipientWallet | null,
  setActiveGroup: Function
  setListModified: Function
  setGroupNameEdit:Function
}

const GroupInfo: FunctionComponent<GroupInfoProps> = ({ groups, setCreateView, activeGroup, setActiveGroup, setListModified, setGroupNameEdit}: GroupInfoProps) => {
  return (
     <div style={{ height: "79%", overflow: 'scroll', width: "400px", paddingBottom: "20px"}}>
      { groups?.map(( group: RecipientWallet ) => (
        <WalletGroupButton
          key={group.groupId}
          group={group}
          value={activeGroup}
          // setValue={setValue}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          setCreateView={setCreateView}
          setListModified={setListModified}
          setGroupNameEdit={setGroupNameEdit}
        />
      ))}
    </div>
  );
};

export default GroupInfo;
