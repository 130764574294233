import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components";
import { OrganizationProject } from "../../../../Wallets/OrganizationWallets";

interface TargetPercentFundsInputProps {
  disabled: boolean;
  readOnly: boolean;
  project: OrganizationProject;
}

export const TargetPercentFundsInput = ({
  disabled,
  readOnly,
  project,
}: TargetPercentFundsInputProps) => {

  const { t } = useTranslation();

  // validate input as needed
  const validateInput = (input: string = "") => {
    if (input.length < 1 || !input.toString().match(/^[1-9][0-9]?$|^100$/)) {
      return t("only_valid_number_btn_one_and_hundred");
    }
    return false;
  };

  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("target_percent_funds", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.project_details?.target_percent_funds || "",
  });

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="target_percent_expenditure" />
      </label>
      <div>
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={
            readOnly ? project?.project_details?.target_percent_funds : "95"
          }
          maxLength={3}
          disabled={disabled}
          readOnly={readOnly}
          style={{ height: 40, width: 200 }}
          rightElement={
            <span className="settings__card-input-list-item__input-icon">
              %
            </span>
          }
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

