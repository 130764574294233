import { useMemo, forwardRef, useImperativeHandle } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  HeaderGroup,
  TableOptions,
  Row,
  Cell,
} from "react-table";

import { ReactComponent as UpArrow } from "./sort-asc.svg";
import { ReactComponent as DownArrow } from "./sort-desc.svg";
import { ReactComponent as Placeholder } from "./placeholder.svg";
import { customTimeframeFilterFn } from "./CustomFilters";

export type WalletRow = {
  id: number;
  name: string;
  phone_number: string;
  enrolled_at: string;
};

export type WalletTransactionRow = {
  id: number;
  name: string;
  description: string;
  type: string;
  status: "RESOLVED_SUCCESS" | "PENDING";
  category: string;
  timestamp: Date | string;
  transaction_id: string;
  total: number;
};

export type TableColumnHeader = {
  header: string;
  accessor: string;
  autoResetSortBy: boolean;
};

export type TableProps = {
  columns: Array<Partial<TableColumnHeader>>;
  data: Array<WalletRow | any>;
  onRowClick?: (rowProps?: any) => void;
  handleRoleChange?: Function;
  timeframe?: [Date | null, Date | null];
  filterValue?: string;
  filterTypes?: any;
};

export interface TableOptionsSortable extends TableOptions<any> {
  autoResetSortBy?: boolean;
  filterTypes?: any;
  defaultColumn?: any;
}

export interface ColumnHeader extends HeaderGroup<any> {
  getSortByToggleProps: any;
  isSorted: boolean;
  isSortedDesc: boolean;
}

export interface ColumnHeaderSortableOpts {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

export interface TableRowProps {
  row: Row<any>;
  tableProps: any;
}

export const TransactionTable = forwardRef((props: TableProps, ref) => {
  const { columns, data } = props;

  const filterTypes = useMemo(
    () => ({
      customTimeframeFilter: customTimeframeFilterFn,
    }),
    []
  );

  const instance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      filterTypes,
      manualFilters: true,
    } as TableOptionsSortable,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = instance;

  // return table instance to parent ref for executing react-table utilities
  useImperativeHandle(ref, () => instance);

  return (
    <>
      <table {...getTableProps()} cellSpacing={0}>
        <thead>
        {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps() as any)}
                    style={{ paddingLeft: 20 }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            )
          }
        )}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row: Row<any>) => {
          prepareRow(row);
          return <TableRow row={row} tableProps={props} />;
        })}
        </tbody>
      </table>
    </>
  );
});

export const TableRow = ({ row, tableProps }: TableRowProps) => {
  return (
    <tr
      {...row.getRowProps()}
      onClick={() => {
        // const selectedText = window.getSelection()?.getRangeAt(0)?.toString();
        // if (selectedText) {
        //   return;
        // } else {
        if (tableProps?.onRowClick) {
          tableProps?.onRowClick(row);
        }
        // }
      }}
    >
      {row.cells.map((cell: Cell<any, any>) => {
        return (
          <td {...cell.getCellProps()} style={{ paddingLeft: 20 }} >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

// Sort direction indicator to indicate order of column cells
export const SortableDirectionIndicator = ({
                                             isSorted,
                                             isSortedDesc,
                                           }: ColumnHeaderSortableOpts): JSX.Element => {
  if (isSorted) {
    return isSortedDesc ? <DownArrow /> : <UpArrow />;
  }
  return <Placeholder />;
};

export default TransactionTable;
