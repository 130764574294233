import "../OrganizationWallets.css";
import { useState } from "react";

import { Button } from "@blueprintjs/core";
import { OrganizationWallet } from "../../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import { UmojaDialog } from "../../../components";
import { useForm } from "react-form";
import { useParams } from "react-router-dom";
import { useCards } from "../../../hooks";
import { UmojaToast } from "../../../components/Popups/UmojaToast";
import { Organization } from "../OrganizationWallets";
import { Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

const { REACT_APP_WALLET_REGISTRATION_ENDPOINT } = process.env;

interface UnRegisterUmojaCardFormProps {
  isOpen: boolean;
  handleClose: Function;
  wallet: OrganizationWallet;
  organization: Organization;
}

export const UnRegisterUmojaCardForm = ({
  isOpen,
  handleClose,
  wallet,
  organization,
}: UnRegisterUmojaCardFormProps) => {
  const { t } = useTranslation();
  const { organizationId, walletId } = useParams<{
    organizationId: string;
    walletId: string;
  }>();
  const cardsQuery = useCards(organizationId);
  const [saving, setSaving] = useState(false);
  const QueryClient = useQueryClient()
  const UnRegisterUmojaCardFormInstance = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setSaving(true);
      // handle api here
      const response = await fetch(
        `${REACT_APP_WALLET_REGISTRATION_ENDPOINT}/${organizationId}/wallets/${walletId}/unregister`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            card_number: values.umoja_card_number,
          }),
        }
      );
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_registering_umoja_card")}`,
          intent: "danger",
          icon: "issue",
        });
      } else {
        UmojaToast.show({
          message: `${t("successfully_unregistered_card")} -> ${wallet.name}!`,
          intent: "success",
          icon: "tick",
        });
        await QueryClient.invalidateQueries("wallet")
        // it's nice to have a little pause to read the toast, before closing the dialog
        // and mutating the cache
        setTimeout(() => {
          handleClose();
          cardsQuery.refetch();
        }, 200);
      }
      setSaving(false);
    },
    // debugForm: true,
  });


  return (
    <UmojaDialog
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseButtonShown
      title={t('unregister_card')}
    >
      <UnRegisterUmojaCardFormInstance.Form>
        <div className="register_wallet__modal_wrapper">
          <p>
            <Trans i18nKey="unregister_card_confirm">
              Are you sure you want to unregister this Umoja Card from <strong>{{ wallet: wallet?.name }}</strong> wallet?
            </Trans>
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="submit"
              intent="success"
              text={t("unregister_card")}
              loading={saving}
              disabled={!walletId}
            />
          </div>
        </div>
      </UnRegisterUmojaCardFormInstance.Form>
    </UmojaDialog>
  );
};

