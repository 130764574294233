import "./Login.css";
import { LoginForm } from "./LoginForm";
import { Layout } from './Layout';

function WhatsappLoginScreen() {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  )
  
}
export default WhatsappLoginScreen;
