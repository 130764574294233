import './Layout.css';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
// import { LanguageSelector } from '../../components/Header/UmojaHeader';
import { Card } from '@blueprintjs/core';
import * as qs from "query-string";
import { useLocation } from "react-router-dom";

type LayoutProp = {
    children?: React.ReactChild | React.ReactChild[];
}

export const Layout = ({ children }: LayoutProp) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { lang } = qs.parse(location.search);  

    const languageCode = useMemo(() => {
      const language: Record<string, string> = {
          en: "en-US",
          fr: "fr",
          es: "es",
       }
    const { lang } = qs.parse(location.search);
    return language[String(lang)] || "en-US";
    }, [location]);
  
  useEffect(() => {
    if (lang) {
       i18n.changeLanguage(languageCode);
     }
  }, [i18n, lang, languageCode]);
  return (
    <div className='container'>
          <div className='box'>
              <div className='box__container'>
                  <div className='box_container_header'>
                  <div className='Umoja_Lang_container'>
                     {/* <div>
                        <LanguageSelector />
                      </div>  */}
                  </div>
                  <div className='logo__container'>
                      <div className='logo'>
                        <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.6767 4.28325V11.0362C20.6767 13.4105 18.7196 15.3195 16.3128 15.3195C13.8938 15.3195 11.9489 13.3986 11.9489 11.0362V4.28325C11.9368 1.9209 13.8938 0 16.3006 0C18.7196 0 20.6767 1.9209 20.6767 4.28325ZM0 0V11.0362C0 19.3403 6.41815 26.2126 14.7447 26.9881V18.3261C11.2804 17.6103 8.73987 14.5798 8.73987 11.0362V4.28325C8.73987 1.9209 6.78282 0 4.36386 0H0ZM23.9222 4.28325V11.0362C23.9222 14.5798 21.3817 17.5983 17.9173 18.3261V26.9881C26.2439 26.2006 32.6621 19.3403 32.6621 11.0362V0H28.2982C25.8914 0 23.9222 1.9209 23.9222 4.28325Z" fill="url(#paint0_linear)"/>
                           <defs>
                             <linearGradient id="paint0_linear" x1="16.3332" y1="2.81633" x2="16.3332" y2="23.1123" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00558088" stopColor="white"/>
                                <stop offset="1" stopColor="#E6E7E8"/>
                             </linearGradient>
                           </defs>
                        </svg>  
                      </div>
                      <h1>Umoja</h1>
                  </div>
                  <p className='box_container_header--content'>
                   {`${t('login_page_description')}`}
                 </p>
                  </div>
                  <div className='wrapper-infobox-display'>
                      <Card className="wrapper-infobox" interactive>
                        <div className="login__form-wrapper-infobox_title_wrapper">
                            <div className="activity__dot" />
                            <h4 className="login__form-wrapper-infobox-title-text">
                            {`${t('learn_more_about_umoja')}`}
                            </h4>
                       </div>
                    </Card>
                  </div>
              </div>
               <div className="private__policy__container">
                <a href="/#" className="">
                    {`${t('privacy_policy')}`}
                </a>
                <br />
                <a href="/#" className="">
                {`${t('terms_of_service')}`}
                </a>    
              </div>
          </div> 
          <div className='box__left'>
              <div className='box__container__left'>
                  {children}
              </div>
              <div className='support__link'>
                <div className="login__page-link-text">
                    {`${t('having_trouble_signin')}`}
                </div>
                <a href="/#" className="login__page-link-text--purple">
                {`${t('contact_support')}`}
                </a>
           </div>
          </div>  
    </div>
  )
}