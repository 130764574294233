import { useField } from "react-form";
import { FormInputType } from "../../../Settings";
import { TextInput } from "../../../../../components";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

interface PasswordInputProps {
  disabled: boolean;
  forgotPassword: Function;
  loading: boolean;
}

export const CurrentPasswordInput = ({
  disabled,
  forgotPassword,
  loading,
}: PasswordInputProps) => {
  const { t } = useTranslation();
  const validateInput = (password: string, instance: FormInputType) => {
       if (password.length < 1) {
           return t("password_required");
        }
       if (password.length < 8) {
          return t("password_length_validation");
        }
       return false;
     }
  const CurrentPasswordInputInstance = useField("settings.current_password", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const { meta: { error, isTouched }, getInputProps } = CurrentPasswordInputInstance;

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="current_password" />
      </label>
      <div style={{ width: 200 }}>
        <TextInput
          {...getInputProps()}
          type="password"
          placeholder={t("current_password")}
          disabled={!disabled}
        />
        {isTouched && disabled && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
      {/*<Button*/}
      {/*  text={`${t("forgot_password")}?`}*/}
      {/*  onClick={() => forgotPassword()}*/}
      {/*  className="forgot__password-text"*/}
      {/*  loading={loading}*/}
      {/*  minimal*/}
      {/*/>*/}
    </li>
  );
};
