import { useTranslation } from "react-i18next";
import "../../../UmojaPaymentWidget.css";
import { formatDecimalNumber } from "../../../../../../utils";

interface UmojaMasterWalletPanelProps {
  balance: string;
  title: string;
  currency: string;
}

export const MasterWalletPanel = ({ balance, title, currency }: UmojaMasterWalletPanelProps) => {
  const { t } = useTranslation();
  const walletBalanceFontSize = balance?.length > 10 ? "40px" : "25px"
  return (
    <div className="payment__widget-master-wallet_wrapper">
      <h2 className="master-wallet__panel-title">
        {`${title} (${currency})`}
      </h2>
      <h4 className="master-wallet__balance" style={{ fontSize: walletBalanceFontSize }}>
        {formatDecimalNumber(balance) || '0.00'}
      </h4>
      <p className="master-wallet__balance-description">
        {t("master_wallet_balance")}
      </p>

    </div>
  );
};
