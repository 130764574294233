import React from "react";
import DefaultView from "../../components/DefaultView/DefaultView";
import { OrganizationWalletProps } from "./GlobalOperations";
import { mapCurrencySign } from "../../utils/currencyMap";
import UmojaEmptyPaymentWidget from "../../components/Widgets/PaymentWidget/UmojaEmptyPaymentWidget";
interface ProjectDefaultView {
  displayPaymentWidget : boolean,
  displayProjectHeading: boolean,
  programWallet?: OrganizationWalletProps;
}

function DefaultProjectView({ displayPaymentWidget, displayProjectHeading, programWallet }: ProjectDefaultView) {
  return (
    <div style={{ display: "flex", flexDirection: "row"}}>
     <DefaultView  displayProjectHeading={displayProjectHeading} />
      <div style={{ display: displayPaymentWidget ? "flex": "none", paddingLeft: "50px", paddingRight: "50px" }}>
        <UmojaEmptyPaymentWidget
          disabled={true}
          masterWalletBalance={programWallet ? programWallet.balance : "0"}
          currency={mapCurrencySign[programWallet?.currency!]!}
        />
      </div>
    </div>
  );
}

export default DefaultProjectView;
