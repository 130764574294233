import { useForm, useField } from "react-form";
import { Card, FormGroup, HTMLSelect, Label } from "@blueprintjs/core";

import { Button, Layout, TextInput } from "../components";

type OrganizationCreateScreenProps = {};

const ORGANIZATION_TYPES = [
  "Non-Profit",
  "International NGO",
  "Local NGO",
  "United Nations Agency",
  "Multilateral Organization",
  "Government",
  "Civil Society Organization (CSO)",
  "Faith-Based Organization",
  "Foundation",
  "Charity",
  "Small and Mid-size Enterprise (SME)",
  "Charity (other)",
  "Company",
  "Private Sector (other)",
  "Humanitarian Program",
  "Development Program",
  "Business/Private Sector/SME Support",
];

async function validateOrganizationName(name: string) {
  if (!name) {
    return "Name is required.";
  }

  // TODO: Check if name is already taken
  const data: string[] = await new Promise((resolve, reject) => {
    resolve([]);
  });

  if (data.length > 0) {
    return "Name already is taken.";
  }

  return undefined;
}

function validateOrganizationType(type: string) {
  return ORGANIZATION_TYPES.indexOf(type) > -1 ? undefined : "Invalid type";
}

function OrganizationNameInput() {
  const { getInputProps } = useField("organization.name", {
    validate: validateOrganizationName,
  });

  return (
    <FormGroup>
      <Label>
        Name
        <TextInput
          {...getInputProps()}
          required
          name="organizationName"
          type="text"
          placeholder="Organization Name"
        />
      </Label>
    </FormGroup>
  );
}

function OrganizationTypeInput() {
  const { getInputProps } = useField("organization.type", {
    validate: validateOrganizationType,
  });
  return (
    <FormGroup>
      <Label>
        Type
        <HTMLSelect
          {...getInputProps()}
          options={ORGANIZATION_TYPES}
          required
          name="organizationType"
        />
      </Label>
    </FormGroup>
  );
}

function CreateOrganizationForm() {
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    onSubmit: async (values: any) => {
      const response = await fetch(
        process.env.REACT_APP_CREATE_ORGANIZATION_ENDPOINT as string,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(values),
        }
      );
      if (!response.ok) {
        throw new Error("Please make sure your credentials are correct.");
      }
    },
    debugForm: true,
  });

  return (
    <Card>
      <Form>
        <OrganizationNameInput />
        <OrganizationTypeInput />
        <Button
          type="submit"
          disabled={!canSubmit}
          icon="plus"
          text="Create"
          intent="primary"
        />
      </Form>
    </Card>
  );
}

function OrganizationCreateScreen(props: OrganizationCreateScreenProps) {
  return (
    <Layout.Container>
      <h1>Create an Organization</h1>
      <CreateOrganizationForm />
    </Layout.Container>
  );
}

export default OrganizationCreateScreen;
