import "../../UmojaPaymentWidget.css";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

interface UmojaPaymentWidgetHistoryPanelProps {
  listItems: any[];
  currency: string
}

export const TransactionHistoryPanel = ({
  listItems,
  currency
}: UmojaPaymentWidgetHistoryPanelProps) => {
  const { t } = useTranslation();

  return (
    <div className="payment__widget-transaction_history_wrapper">
      <h2 className="transaction-history__panel-title">
        <Trans i18nKey="transactions" />
      </h2>
      {listItems.length ? <div className="payment__widget-activity_list-list-wrapper">
        {listItems.map((item: any, idx: number) => {
          return (
            <div className="payment__widget-activity_list-list_item" key={idx}>
              <div className="payment__widget-activity_list-wrapper">
                <ReactSVG src={item.type === "Disbursement" ? "/sent.svg" : "/received.svg"} />
                <div className="payment__widget-activity_list-wallet_group">
                  {item.type === "Disbursement"
                    ? `${t("sent_to")} ${item.recipient_count} ${t("wallets")}`
                    : `${t("wallet_received")}`
                  }
                  {/* <p className="group-text">Individual Wallet</p> */}
                </div>
              </div>
              <div className={`${item.type === "Disbursement" ? "payment__widget-activity_sent-transaction" : "payment__widget-activity_received-transaction"}`}>
                <p className="amount-text">
                  {item.type === "Disbursement"
                    ? `-${currency}${(item.amount).toFixed(2)}`
                    : `+${currency}${(item.amount).toFixed(2)}`
                  }
                </p>
                <p className="transaction-text">
                  {`${item.type === "Disbursement"
                    ? t("sent")
                    : t("received")} . ${format(new Date(item.received_timestamp || item.created_at), "MMM dd H:mm")}`
                  }
                </p>
              </div>
            </div>
          );
        })}
      </div>
        : <div>{t("no_recent_activity")}</div>
      }
    </div>
  );
};
