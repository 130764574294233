import "./OrganizationWallets.css";
import { format, parseISO } from "date-fns";
import { OrganizationWallet } from "../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import { formatDecimalNumber, formattedFloatNumberWith2Decimals } from "../../utils/formatNumbers";
import { WalletTransaction } from "./OrganizationWalletDrawerContent";
import { Trans, useTranslation } from "react-i18next";

export interface OrganizationWalletInfoProps {
  wallet: OrganizationWallet | null;
  card: string | null;
  transactions: WalletTransaction[];
  currency: string
}

export const OrganizationWalletInfo = ({
  wallet,
  card,
  transactions,
  currency
}: OrganizationWalletInfoProps) => {
  
  const { t } = useTranslation()

  const walletType = wallet?.type_id === 1 ? t("Recipient") : t("Merchant");
  const totalTransactions = transactions.length || "-";

  const lastActive =
    transactions.length > 0
      ? format(parseISO(transactions[0].timestamp), "P")
      : "-";

  const enrolled_at = wallet?.enrolled_at
    ? format(parseISO(wallet.enrolled_at?.toString()), "P")
    : "-";

  return (
    <>
      <div className="organization__wallet-info-box">
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="program_code" />
          </div>
          <div className="wallet__info-text">{wallet?.program_code || "-"}</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="enrollment_date" />
          </div>
          <div className="wallet__info-text">{enrolled_at || "-"}</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="last_active" />
          </div>
          <div className="wallet__info-text">{lastActive || "-"}</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="user_type" />
          </div>
          <div className="wallet__info-text">{walletType || "-"}</div>
        </div>
      </div>
      <div className="organization__wallet-info-box">
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="wallet_balance" />
          </div>
          <div className="wallet__info-text">{wallet?.balance ? `${currency}${formatDecimalNumber(String(wallet?.balance))}` : "-" }</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="total_transactions" />
          </div>
          <div className="wallet__info-text">{totalTransactions}</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="card_reference" />
          </div>
          <div className="wallet__info-text">{formatCardNumber(card)}</div>
        </div>
        <div>
          <div className="wallet__info-title">
            <Trans i18nKey="pin_code" />
          </div>
          <div className="wallet__info-text">{wallet?.pin_code || "-"}</div>
        </div>
      </div>
    </>
  );
};

const formatCardNumber = (card: string | null) => {
  return card ? card.slice(-4).padStart(card.length, "*") : "-";
};
