import { Card } from "@blueprintjs/core";
import { ManageTeamMembersForm } from "./forms/ManageTeamMembersForm";
import { OrganizationInformationForm } from "./forms/OrganizationInformationForm";
// import { BillingForm } from "./forms/BillingForm";
import { Organization } from "../Wallets/OrganizationWallets";

interface OrganizationSettingsProps {
  organization: Organization;
  userRole: string;
  account: any
}

export const OrganizationSettings = ({
  organization,
  userRole,
  account
}: OrganizationSettingsProps) => {
  return (
    <div>
      <Card className="settings__card-wrapper">
        <OrganizationInformationForm organization={organization} userRole={userRole} />
      </Card>
      <Card className="settings__card-wrapper">
        <ManageTeamMembersForm userRole={userRole} account={account?.data} />
      </Card>
      {/* <Card className="settings__card-wrapper">
        <BillingForm />
      </Card> */}
    </div>
  );
};
