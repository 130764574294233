import { useForm } from "react-form";
import { useState } from "react";

// import { Button } from "../../../../components";
import { OrganizationEmailInput } from "./inputs/OrganizationEmailInput";
// import { PermissionLevelInput } from "./inputs/PermissionLevelInput";
import { Trans } from "react-i18next";
import { Organization } from "../../../Wallets/OrganizationWallets";

interface OrganizationSettingsProps {
  organization: Organization;
  userRole: any
}

export const OrganizationInformationForm = ({
  organization,
  userRole
}: OrganizationSettingsProps) => {
  const [editMode, setEditMode] = useState(false);
  // const [saving, setSaving] = useState(false);

  const AccountSettingsFormInstance = useForm({
    onSubmit: async (values, instance) => {
      setEditMode(!editMode);
      if (editMode) {
        // setSaving(true);
        // // handle api here
        // await new Promise((resolve) => setTimeout(resolve, 1500));
        // setSaving(false);
      }
    },
    // debugForm: true,
  });
  
  return (
    <AccountSettingsFormInstance.Form>
      <div className="settings__card-header">
        <div className="settings__card-title_wrapper">
          <h4 className="settings__card-title">
            <Trans i18nKey="organization_information" />
          </h4>

          <p className="settings__card-subtitle">
            <Trans i18nKey="organization_information_subtitle" />
          </p>
        </div>
        {/* <Button
          type="submit"
          intent="primary"
          text={editMode ? <Trans i18nKey="save" /> : <Trans i18nKey="edit" />}
          loading={saving}
        /> */}
      </div>
      <ul className="settings__card-input-list">
        <li className="settings__card-input-list-item">
          <label>
            <Trans i18nKey="organization_name" />
          </label>
          <p>{organization.name}</p>
        </li>
        <OrganizationEmailInput disabled={true} email={organization.contact_email}   />
        {/* <PermissionLevelInput disabled={true} userRole={userRole}  /> */}
      </ul>
    </AccountSettingsFormInstance.Form>
  );
};
