import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

export const FirstNameInput = () => {
  const { t } = useTranslation();
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("firstName", {
    validate: validatePassword,
    defaultIsTouched: false,
  });

  return (
    <div className="first-name-input-wrapper">
      <TextInput
        {...getInputProps()}
        required
        name="firstName"
        type="text"
        placeholder={`${t('enter_first_name')}`}
      />
      {isTouched && <p style={{ color: "red" }}>
        {error?.includes("required") && t("first_name_required")}
        {error?.includes("allowed") && t("only_letters_allowed")}
        {error?.includes("character") && t("character_is_too_short")}
      </p>}
    </div>
  );
};

const validatePassword = (firstName: string) => {
  const regex = /^[A-Za-z]+$/;
  if (!firstName) {
    return "First name is required";
  }

  if (firstName.length < 2) {
    return "character is too short";
  }

  if (!firstName.match(regex)) {
    return "Only letters are allowed without spaces";
  }

  return false;
};
