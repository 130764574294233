import {
  format,
  isWithinInterval,
  subDays,
  subHours,
  subYears,
} from "date-fns";

const dateFormat = "MMM dd H:mm";

interface TimeframeSelectProps {
  data: any;
  timeframe: any;
}

export const timeframedOfflineTransactions = ({
  data,
  timeframe,
}: TimeframeSelectProps) => {
  if (!data.transactions.length) {
    // handle empty dataset
    // return [
    //   { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
    //   { x: format(new Date(), dateFormat), y: 0 },
    // ];
    return []
  }
  return data.transactions
    .filter((transaction: any) => {
      return (
        isWithinInterval(new Date(transaction.received_timestamp), {
          start: new Date(timeframe.start),
          end: new Date(timeframe.end),
        }) && transaction.is_offline
      );
    })
    .map((filteredTransaction: any) => {
      return {
        x: format(new Date(filteredTransaction.received_timestamp), dateFormat),
        y: filteredTransaction.amount,
      };
    });
};

export const timeframedTransactionAmounts = ({
  data,
  timeframe,
}: TimeframeSelectProps) => {
  if (!data.transactions.length) {
    // handle empty dataset
    // return [
    //   { x: format(subDays(new Date(), 1), dateFormat), y: 0 },
    //   { x: format(new Date(), dateFormat), y: 0 },
    // ];
    return []
  }
  return data.transactions
    .filter((transaction: any) => {
      return isWithinInterval(new Date(transaction.received_timestamp), {
        start: new Date(timeframe.start),
        end: new Date(timeframe.end),
      });
    })
    .map((filteredTransaction: any) => {
      return {
        x: format(new Date(filteredTransaction.received_timestamp), dateFormat),
        y: filteredTransaction.amount,
      };
    });
};

export const selectTimeframe = (timeframe: any) => {
  let currentTime = new Date();
  let min = subDays(currentTime, 365 * 2);
  let startTime;
  let endTime;

  switch (timeframe) {
    // the past hour
    case "timeframe-1h": {
      startTime = subHours(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // the past 2 hours
    case "timeframe-24h": {
      startTime = subHours(currentTime, 24);
      endTime = currentTime;
      break;
    }
    // the past week
    case "timeframe-1w": {
      startTime = subDays(currentTime, 7);
      endTime = currentTime;
      break;
    }
    // past year
    case "timeframe-1y": {
      startTime = subYears(currentTime, 1);
      endTime = currentTime;
      break;
    }
    // all data
    case "timeframe-max": {
      startTime = min;
      endTime = currentTime;
      break;
    }
    // fallback
    default: {
      startTime = min;
      endTime = currentTime;
      break;
    }
  }

  return {
    start: startTime,
    end: endTime,
  };
};

export const findSum = (data: [] = []) => {
  let sum = data.reduce(
    (total: number, transaction: any) => total + transaction.amount,
    0
  );

  return sum;
};

export const spendingPatternData = (data: any) => {

 const spending =  data?.map((values: any) => ({
    date: format(new Date(values?.datetime), 'MM/dd/yy' ),
    Volume: Number(values?.total),
  }));

  return spending?.reverse()
};

export const formatDemographicData = (data: any, currency: string) => {
  const newData = data?.map((category: any) => { 
    return {
      name: category.name,
      Male: category.male.total,
      Female: category.female.total,
      total: category.total
     };
   })
  return newData;
};


export const offlineOnline = (data: any) => {
  return {
    
  }
};


