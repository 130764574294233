import { useQuery } from "react-query";

import { getGroupWallet } from "../services";

export default function useGroups(
  organizationId: string | number,
  projectId: string | number | undefined,
  groupId: string | number | undefined,
) {
  const groupRecipientsQuery = useQuery(
    ["groupRecipients", organizationId, projectId, groupId],
    () => getGroupWallet(organizationId, projectId, groupId),
    { initialData: [], enabled: !!organizationId && !!projectId && !!groupId, select: (data) => data?.map((group: any) => {
        return {
          name: group.name,
          phone_number: group.phone_number,
          user_type: group.wallet_type_id === 1 ?  "Recipient" : "Merchant",
          id: group.project_wallet_id,
          ...group
        };
      })}
  );

  return groupRecipientsQuery.data
}
