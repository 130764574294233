import { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";

import { useForm, useField, UseFormValues } from "react-form";
import { Layout, TextInput } from "../components";

type VerifyAccountScreenProps = {
  token?: string | null;
  email?: string | null;
};

function EmailInput() {
  const { getInputProps } = useField("credentials.email", {});
  return (
    <TextInput
      {...getInputProps()}
      required
      autoFocus
      name="email"
      type="email"
    />
  );
}

async function sendToServer(values: UseFormValues<string>) {
  try {
    const response = await fetch(
      process.env.REACT_APP_VERIFY_ACCOUNT_ENDPOINT as string,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message);
    }
  } catch (err) {
    throw new Error("There was a problem issuing your request.");
  }
}

function ReverificationForm() {
  const {
    Form,
    meta: { isSubmitted },
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToServer(values);
    },
  });
  if (!isSubmitted) {
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <h1>Please verify your account.</h1>
        <p>There was a problem verifying your account.</p>
        <p>
          You may request access again by providing the email address used to
          create the account.
        </p>
        <Form>
          <EmailInput />
        </Form>
      </div>
    );
  }
  return (
    <div style={{ maxWidth: 500, margin: "auto" }}>
      <h1>Request Approved</h1>
      <p>
        Please check email for a verification link to activate your account.
      </p>
    </div>
  );
}

function VerifyAccountScreen(props: VerifyAccountScreenProps) {
  const { token, email } = props;
  const [isVerified, setIsVerified] = useState<boolean | null>(null);

  useEffect(() => {
    async function redeemVerificationToken(token?: string | null) {
      if (!token) {
        return;
      }

      const response = await fetch(
        process.env.REACT_APP_VERIFY_ACCOUNT_ENDPOINT as string,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, email }),
        }
      );

      return setIsVerified(response.ok);
    }

    redeemVerificationToken(token);
  }, [token, email]);

  if (!token) {
    return <Redirect to="/" />;
  }

  if (isVerified === null) {
    return (
      <div>
        <h1>Please wait while we verify your account.</h1>
      </div>
    );
  }

  if (!isVerified) {
    return (
      <Layout.Container>
        <div style={{ margin: "auto", maxWidth: 900 }}>
          <ReverificationForm />
        </div>
      </Layout.Container>
    );
  }

  return (
    <div style={{ maxWidth: 320, margin: "auto" }}>
      <h1>Account Activated</h1>
      <p>Your account has be verified.</p>
      <p>
        You may now <Link to="/">log in</Link> to your account.
      </p>
    </div>
  );
}

export default VerifyAccountScreen;
