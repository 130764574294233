import { Layout } from "../components";

import "./Dashboard.css";

import DashboardOrganizationScreen from "../screens/Dashboard/OrganizationHome";
import { DashboardSidebar } from "../screens/Dashboard/DashboardSidebar";
import { useAccount, useRole } from "../hooks";
import { Spinner } from "@blueprintjs/core";
import { useOrganizationId } from "../hooks/useOrganizationId";
import { useEffect } from "react";
import { createHmac } from "crypto";

type Organization = {
  account_role_id: number
  billing_email: string
  contact_email: string
  country_id: number
  id: number
  name: string
  role: number
  type: string
  umoja_wallet_id: number | null
}

function DashboardContainer() {
  const account = useAccount();
  const role = useRole();
  const selectedOrganizationId = useOrganizationId();
  let selectedOrganization;

  useEffect(() => {
    if (account?.data?.email) {
      // @ts-ignore: Unreachable code error
      window.Intercom("boot", {
        api_base: process.env.REACT_APP_INTERCOM_API_BASE,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: `${account?.data?.first_name} ${account?.data?.last_name}`, // Full name
        email: `${account?.data?.email}`,
        user_hash: createHmac("sha256", process.env.REACT_APP_USER_HASH_SECRET || '', ).update(account?.data?.email).digest("hex"),
      });
    }
  }, [account])

  if (!account.isFetchedAfterMount) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Spinner />
      </div>
    );
  }

  

  const organizations = account.data.organizations;

  if (!selectedOrganizationId) {
    selectedOrganization = account.data.organizations[0];

    // store selected organization id in local storage
    localStorage.setItem("selected_organization_id", selectedOrganization.id);
  } else {
    selectedOrganization = organizations.find(
      (organization: Organization) => organization.id === Number(selectedOrganizationId)
    );
  }


  return (
    <Layout.Container>
      <div className="dashboard__container">
        <DashboardSidebar
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          role={role}
        />
        <div className="dashboard__content">
          <DashboardOrganizationScreen organization={selectedOrganization} />
        </div>
      </div>
    </Layout.Container>
  );
}

export default DashboardContainer;
