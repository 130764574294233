import React, { FunctionComponent, useEffect } from "react";
import {
  useRowSelect, useTable
} from "react-table";


interface RecipientTableProps {
  columns: any[],
  data: Array<Object>
  setSelectedWallets: Function
}

const RecipientTable: FunctionComponent<RecipientTableProps> = ({ columns, data, setSelectedWallets }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data
    },
    useRowSelect,
  )
  useEffect(() => {
    if(selectedFlatRows.length >= 0 ) {
      setSelectedWallets(selectedFlatRows.map(d => d.original))
    }
  }, [selectedFlatRows, setSelectedWallets]);


  return (
    <>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{ color: "black"}} >{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, 10).map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </>
  );
};

export default RecipientTable;
