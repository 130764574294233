import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { Trans, useTranslation } from "react-i18next";

interface PasswordInputProps {
  disabled: boolean;
  newPasswordError: boolean
}

export const NewPasswordInput = ({ disabled, newPasswordError }: PasswordInputProps) => {
  const { t } = useTranslation();

  const validateInput = (password: string) => {
      if (password.length < 1) {
        return t("password_required");
      }
      if (password.length < 8) {
        return t("password_length_validation");
      }
      return false;
    }
  const NewPasswordInputInstance = useField("settings.new_password", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const {  meta: { error, isTouched }, getInputProps } = NewPasswordInputInstance;

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="new_password" />
      </label>
      <div style={{ width: 200 }}>
        <TextInput
          {...getInputProps()}
          type="password"
          placeholder={t("new_password")}
          disabled={!disabled}
        />
        {isTouched && disabled && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
        {
          newPasswordError && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {t("new_password_confirmation_validation")}
          </p>
        )
        }
      </div>
    </li>
  );
};
