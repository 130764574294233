import { useEffect, useState } from "react";
import { Tag } from "@blueprintjs/core";
import { useForm } from "react-form";
import { Button } from "../../../../components";
import { OperatingCountryInput } from "./inputs/OperatingCountryInput";
import { ProgramCurrencyInput } from "./inputs/ProgramCurrencyInput";
import { TargetDistributionAmountInput } from "./inputs/TargetDistributionAmountInput";
import { TargetMerchantEnrollmentInput } from "./inputs/TargetMerchantEnrollmentInput";
import { TargetParticipantEnrollmentInput } from "./inputs/TargetParticipantEnrollmentInput";
import { TargetPercentFundsInput } from "./inputs/TargetPercentFundsInput";
import { Trans, useTranslation } from "react-i18next";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";
import { OrganizationProject } from "../../../Wallets/OrganizationWallets";
import { ProgramLanguageInput } from "./inputs/ProgramLanguageInput";
import { CategoryInput } from "./inputs/CategoryInput";
import { ProjectCashOutInput } from "./inputs/CashoutDayInput";

interface ProjectDetailsFormProps {
  project: OrganizationProject;
  activeStep: any,
  setActiveStep: any,
  account: any
}

export const ProjectDetailsForm = ({ project, setActiveStep, activeStep, account }: ProjectDetailsFormProps) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const [duplicateIndex, setDuplicateIndex] = useState(-1);
  const onboardingStep = 2;

  const disabled = project
    ? Boolean(project.project_onboarding_step < onboardingStep)
    : true;

  const readOnly = Boolean(project?.published_at);

  const [fieldState, setFieldState] = useState("");
  const [categoriesState, setCategoriesState] = useState<Array<string>>([]);
  const [valueError, setValueError] = useState(false);

  useEffect(() => {
    if(project?.project_details?.categories){
      setCategoriesState(project?.project_details?.categories)
    }
  }, [project]);



  const handleChange = (event: any) => {
    let { value } = event.target;
    const duplicate = categoriesState.findIndex((category) => {
      return category.toLowerCase() === value.toLowerCase()
    })
    if(value.length > 20){
      setValueError(true)
    }else{
      setValueError(false)
    }

    setDuplicateIndex(duplicate)
    setFieldState(value);
  };

  const handleSubmit = (event: any) => {
    if(duplicateIndex === -1 && event.code === "Enter" && valueError === false){
       const newCategories = [fieldState.trim(), ...categoriesState];
       setCategoriesState(newCategories)
       setFieldState("")
    }
  }

  const onRemove = (tag: string) => {
     const newCategories = categoriesState.filter(t => t !== tag)
     setCategoriesState(newCategories)
  }


  const ProgramDetailsFormInstance = useForm({
    onSubmit: async (values, instance) => {
      setSaving(true);

      const data = new FormData();
      data.append("operating_country", values.operating_country);
      data.append("project_language", values.project_language);
      data.append("selected_currency", values.selected_currency);
      data.append(
        "target_distribution_amount",
        values.target_distribution_amount
      );
      data.append(
        "target_merchant_enrollment",
        values.target_merchant_enrollment
      );
      data.append(
        "target_participant_enrollment",
        values.target_participant_enrollment
      );
      data.append("target_percent_funds", values.target_percent_funds);
      if(categoriesState.length > 0){
        data.append("categories", categoriesState.join(','));
      };
      data.append(
        "cash_out_day",
        values.cash_out_day
      );

      // handle api here
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT}/${project.id}/onboarding/${onboardingStep}` as string,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: data,
        }
      );
      // network/server failure
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_adding_details")}`,
          intent: "danger",
          icon: "issue",
        });
        setSaving(false);
      }
      // success
      UmojaToast.show({
        message: `${t('creating_updating_project_successful')}`,
        intent: "success",
        icon: "tick",
      });
      account.refetch(); // refactor to useMutation
      setActiveStep(3);
      setSaving(false);
    },
    // debugForm: true,
  });

  // get the values of operating country and selected currency
  const {
    operating_country: country,
    selected_currency: currency,
  } = ProgramDetailsFormInstance.values;
  return (
    <ProgramDetailsFormInstance.Form encType="multipart/form-data">
      <div className="settings__card-header">
        <div className="settings__card-title_wrapper" >
          <h2 className="settings__card-step">
            <Trans i18nKey="step_two" />
          </h2>
          <h4 className="settings__card-title">
            <Trans i18nKey="project_details" />
            <ProjectDetailsFormStatusIndicator
              project={project}
              onboardingStep={onboardingStep}
            />
          </h4>

          <p className="settings__card-subtitle">
            <Trans i18nKey="project_details_subtitle" />
          </p>
        </div>
        <Button icon="double-caret-vertical" onClick={() => setActiveStep(2)} />
      </div>
      {activeStep === 2 && (
        <div>
          <div>
          <h5>{t('program_localization')}</h5>
          <ul className="settings__card-input-list" >
            <OperatingCountryInput
              project={project}
              disabled={disabled}
              readOnly={readOnly}
            />
            <ProgramLanguageInput
              project={project}
              disabled={disabled}
              readOnly={readOnly}
            />
            <ProgramCurrencyInput
              project={project}
              disabled={disabled}
              readOnly={readOnly}
            />
          </ul>
          </div>
          <div>
          <h5>
            <Trans i18nKey="project_goals" />
          </h5>
            <div>
              <ul className="settings__card-input-list" >
                <TargetDistributionAmountInput
                  project={project}
                  disabled={disabled || !(country && currency)}
                  readOnly={readOnly}
                />
                <TargetMerchantEnrollmentInput
                  project={project}
                  disabled={disabled || !(country && currency)}
                  readOnly={readOnly}
                />
                <TargetParticipantEnrollmentInput
                  project={project}
                  disabled={disabled || !(country && currency)}
                  readOnly={readOnly}
                />
                <TargetPercentFundsInput
                  project={project}
                  disabled={disabled || !(country && currency)}
                  readOnly={readOnly}
                />
                <ProjectCashOutInput defaultValue={project?.project_details?.cash_out_day} readOnly={readOnly} />
                <h5>
                  {
                    t('project_categories')
                  }
                </h5>
                <div style={{ height: "250px", marginBottom: "10px" }}>
                  <CategoryInput
                  disabled={categoriesState.length >= 16 ? true : false }
                  readOnly={readOnly}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  onRemove={onRemove}
                  fieldState={fieldState}
                  categoriesState={categoriesState}
                  duplicateIndex={duplicateIndex}
                  valueError={valueError}
                />
                </div>
              </ul>
            </div>
          </div>
          <div>
          </div>
          <div className="settings__card-step_button_wrapper">
            <p>
              {disabled
                ? `${t("complete_previous_steps")}`
                : `${t("continue_when_ready")}`}
            </p>
            <Button
              intent="primary"
              text={
                project?.project_onboarding_step > onboardingStep ? (
                  t('update')
                ) : (
                  <Trans i18nKey="next_step" />
                )
              }
              loading={saving}
              disabled={disabled || readOnly || categoriesState.length < 1}
              onClick={ProgramDetailsFormInstance.handleSubmit}
            />
          </div>
        </div>
      )}
    </ProgramDetailsFormInstance.Form>
  );
};

interface SettingsFormStatusIndicatorProps {
  project: OrganizationProject | null;
  onboardingStep: number;
}

export const ProjectDetailsFormStatusIndicator = ({
  project,
  onboardingStep,
}: SettingsFormStatusIndicatorProps) => {
  const { t } = useTranslation();
  if (!project) {
    return null;
  }

  return project.project_onboarding_step > onboardingStep ? (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="success"
    >
      {t("section_complete")}
    </Tag>
  ) : (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="warning"
    >
      {t("section_incomplete")}
    </Tag>
  );
};
