import { useForm } from "react-form";
import { useState } from "react";

import { Button } from "../../../../components";
import { CurrentPasswordInput } from "./inputs/CurrentPasswordInput";
import { NewPasswordInput } from "./inputs/NewPasswordInput";
import { ConfirmPasswordInput } from "./inputs/ConfirmPasswordInput";
import { UseQueryResult } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import ForgotPasswordDialog from "../../../ForgotPassword/ForgotPasswordDialog";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";

const {
  // REACT_APP_SETTINGS_ENDPOINT,
  REACT_APP_REQUEST_RESET_PASSWORD,
  REACT_APP_ACCOUNT_SETTINGS_ENDPOINT
} = process.env;

interface AccountInformationFormProps {
  account: UseQueryResult<any, unknown>;
  setEditPasswordMode: Function,
  editPasswordMode: boolean,
  setEditEmailMode: Function
}

export const ChangePasswordForm = ({
  account,
  editPasswordMode,
  setEditPasswordMode,
  setEditEmailMode
}: AccountInformationFormProps) => {
  // const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation()

  const { data } = account;
    
  const ChangePasswordFormInstance = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {  
      setSaving(true);
      const response = await fetch(
        `${REACT_APP_ACCOUNT_SETTINGS_ENDPOINT}/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            password: values.settings.new_password,
            current_password: values.settings.current_password
          }),
        }
      );    
      if (!response.ok) {
        UmojaToast.show({
            message: `${t("error_updating_password")}`,
            intent: "danger",
            icon: "issue",
          });
      } else {
        UmojaToast.show({
            message: `${t("successfully_updated_password")}`,
            intent: "success",
            icon: "tick",
        });
      }
      setSaving(false);
      setEditPasswordMode(false);
    },
    // debugForm: true,
  });

  const passwordResetRequest = async () => {
    setLoading(true);
    const response = await fetch(REACT_APP_REQUEST_RESET_PASSWORD as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: data.email, channel: "EMAIL" }),
    });

    if (response.ok) {
      setOpenDialog(true);
    }
    setLoading(false);
  };

  const { meta } = ChangePasswordFormInstance;

  let currentPasswordValue = ChangePasswordFormInstance.getFieldValue(
    "settings.current_password"
  );
  let newPasswordValue = ChangePasswordFormInstance.getFieldValue(
    "settings.new_password"
  );
  let confirmPasswordValue = ChangePasswordFormInstance.getFieldValue(
    "settings.confirm_password"
  );

  const formComplete =
    currentPasswordValue && newPasswordValue && confirmPasswordValue;

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <ForgotPasswordDialog
        showResetPasswordDialog={openDialog}
        toggleResetDialog={handleCloseDialog}
      />
      <ChangePasswordFormInstance.Form>
        <div className="settings__card-header">
          <div className="settings__card-title_wrapper">
            <h4 className="settings__card-title">
              <Trans i18nKey="change_your_password" />
            </h4>
            <p className="settings__card-subtitle">
              <Trans i18nKey="change_password_any_time" />
            </p>
          </div>
          {!editPasswordMode && (
            <Button
              intent="primary"
              text={<Trans i18nKey="edit" />}
              disabled={account?.data?.demo}
              loading={saving}
              onClick={() => {
                setEditPasswordMode(true)
                setEditEmailMode(false)
              }
              }
            />
          )}
          {editPasswordMode && (
            <Button
              type="submit"
              intent="primary"
              text={t("save")}
              loading={saving}
              disabled={!(meta.canSubmit && meta.isTouched && formComplete)}
            />
          )}
        </div>
        <ul className="settings__card-input-list">
          <CurrentPasswordInput
            disabled={editPasswordMode}
            forgotPassword={passwordResetRequest}
            loading={loading}
          />
          <NewPasswordInput disabled={editPasswordMode} newPasswordError={currentPasswordValue && newPasswordValue && currentPasswordValue === newPasswordValue} />
          <ConfirmPasswordInput disabled={editPasswordMode} />
        </ul>
      </ChangePasswordFormInstance.Form>
    </>
  );
};
