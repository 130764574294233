import React from "react";
import { Card, Elevation, H2, H3, H4 } from "@blueprintjs/core";
import { Button } from "../../components";
import "./NewProjectView.css";
import { useTranslation } from "react-i18next";
import { AtLeast } from "../../hooks/useRole";

interface NewProjectViewProps {
  role: string;
  openProject: any;
}

function NewProjectView({ role, openProject }: NewProjectViewProps) {
  const { t } = useTranslation();
  return (
    <Card
      interactive={false}
      elevation={Elevation.TWO}
      className="new_project_card"
    >
      <div className="new_project_card_header">
        <H2 style={{ color: "white" }}>{t("getting_started")}</H2>
        <H4 style={{ color: "white", fontSize: "20px", lineHeight: "30px" }}>
          {t("new_project_description")}
        </H4>
      </div>
      <div style={{ height: "150px", paddingLeft: "53px" }}>
        <H3>{t('create_project')}</H3>
        <ul className="new_project_card_body">
          <li>{t("create_first_project")}</li>
          <li>{t("setup_locality")}</li>
          <li>{t("define_product")}</li>
          <li>{t("enroll_participant")}</li>
        </ul>
        <Button
          text={t("create_project")}
          active={true}
          type={"button"}
          className="u-button--brand"
          disabled={!AtLeast.Admin(role)}
          large={true}
          onClick={openProject}
        />
      </div>
    </Card>
  );
}

export default NewProjectView;
