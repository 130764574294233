export async function getWallets(organizationId: string | number) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/wallets`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getWallet(
  organizationId: string | number,
  walletId: string | number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/wallets/${walletId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getOrganizationCards(organizationId: string | number) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/cards`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}


export async function getGroups(organizationId: string | number, projectId: string | number ) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/groups`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}


export async function getGroupWallet(organizationId: string | number, projectId: string | number | undefined, groupId: string | number | undefined ) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/group/${groupId}/wallets`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function searchParticipant(organizationId: string | number, projectId: string | number, search: string) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/wallets?search=${search}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}


export const deleteGroup = async (organizationId: string, projectId: string, groupId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/groups/${groupId}/wallets`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}
