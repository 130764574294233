export function formatMoney(amount: string, selectedCurrency: string) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: selectedCurrency || '',
        minimumFractionDigits: 0
    });

    return formatter.format(+amount);
};

export function formatDecimalNumber(amount: string) {
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2});
    return formatter.format(+amount);
};

export function formattedFloatNumberWith2Decimals(num: number | undefined) {
    if (!num) {
        return (0).toFixed(2);
    }
    return (Math.round(num * 100) / 100).toFixed(2);
}
