import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import { useTranslation } from "react-i18next";


interface ProjectCashOutInputProps {
  defaultValue: number;
  readOnly: boolean;
}

export const ProjectCashOutInput = ({ defaultValue, readOnly, }: ProjectCashOutInputProps) => {
  const { t } = useTranslation();

  const validateInput = (input: string) => {
    if (input.length < 1) {
      return t('cash_out_validation_error');
    }
    return false;
  };

  const CASHOUT_DAY = [
    { label: t('monday'), value: '1'},
    { label: t('tuesday'), value: '2'},
    { label: t('wednesday'), value: '3'},
    { label: t('thursday'), value: '4'},
    { label: t('friday'), value: '5'},
  ];

  const selectedDay = ( CASHOUT_DAY[defaultValue - 1] || CASHOUT_DAY[0] ) ;

  const ProjectCashOutFieldInstance = useField("cash_out_day", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: selectedDay.value
  });

  const { meta, getInputProps } = ProjectCashOutFieldInstance;

  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>{t('cash_out_day')}</label>
        <div>
          <TextInput
            type="text"
            placeholder={selectedDay.label}
            value={selectedDay.label}
            name="projectName"
            readOnly={readOnly}
            style={{ height: 40, width: 200 }}
          />
          {meta.isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {/* {meta.error} */}
            </p>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className="settings__card-input-list-item">
      <label>{t('cash_out_day')}</label>
      <div>
        <HTMLSelect
          {...getInputProps()}
          options={CASHOUT_DAY}
          required
          name="projectFocus"
          style={{ height: 30, width: 200 }}
        />
        {meta.isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {meta.error}
          </p>
        )}
      </div>
    </li>
  );
};


