import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { TextInput } from "../../../../../components";
import useCountries from "../../../../../hooks/useCountries";
import { OrganizationProject } from "../../../../Wallets/OrganizationWallets";
import { useTranslation } from "react-i18next";

interface OperatingCountryInputProps {
  disabled: boolean;
  readOnly: boolean;
  project: OrganizationProject;
}

export const OperatingCountryInput = ({
  disabled,
  readOnly,
  project,
}: OperatingCountryInputProps) => {
  const { t } = useTranslation();
  const countries = useCountries();

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("operation_country_validation");
  }
  return false;
  };
  
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("operating_country", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.operating_country || "",
  });


  if (countries.isLoading) {
    return null;
  }

  let countrySelections = countries.data.map((country: any, index: number) => {
    return {
      key: index,
      label: country.name,
      value: country.code,
    };
  });

  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>{t('operating_country')}</label>
        <div>
          <TextInput
            {...getInputProps()}
            type="text"
            placeholder={project?.project_details?.operating_country}
            name="projectName"
            readOnly={readOnly}
            style={{ height: 30, width: 200 }}
          />
          {isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {/* {meta.error} */}
            </p>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className="settings__card-input-list-item">
      <label>{t('operating_country')}</label>
      <div>
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: "Select Country", value: "" },
            ...countrySelections,
          ]}
          required
          name="projectFocus"
          disabled={disabled}
          style={{ height: 30, width: 200 }}
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

