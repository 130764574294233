import { useMemo, useState } from "react";
import { useForm } from "react-form";
import { Card } from "@blueprintjs/core";

import { Button, UmojaAlert } from "../../components";
import { EmailInput } from "./inputs/EmailInput";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import { Trans, useTranslation } from "react-i18next";

export const ForgotPassword = (props: any) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => ({
      credentials: {
        email: "",
      },
    }),
    []
  );

  const ForgotPasswordFormInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
       await fetch(
        process.env.REACT_APP_REQUEST_RESET_PASSWORD as string,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.credentials.email.toLowerCase(), channel: 'EMAIL' }),
        }
      );
      setOpenDialog(true);
      setLoading(false);
    },
    defaultValues,
    // debugForm: true,
  });

  let emailValue = ForgotPasswordFormInstance.getFieldValue("credentials.email");

  const { meta } = ForgotPasswordFormInstance;

  // function to close error alert modal
  const handleClose = () => {
    setError("");
    props.setForgotPasswordState(false)
  }

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
    props.setForgotPasswordState(false)
  }

  return (
    <Card className="login__card">
      {error && (
        <UmojaAlert
          handleClose={handleClose}
          cta="Close"
          message={error}
          intent="danger"
          icon="issue"
        />
      )}
      <ForgotPasswordDialog
        showResetPasswordDialog={openDialog}
        toggleResetDialog={handleCloseDialog}
      />
      <ForgotPasswordFormInstance.Form className="login__card-form">
        <h1>
          <Trans i18nKey="forgot_password" />
        </h1>
   
        <div className="login__card-input_label">{`${t('your_email')}`}</div>
        <EmailInput />
   
        <div className="login__card-input-wrapper">
          <Button
            className="login__button"
            type="submit"
            minimal
            disabled={
              !(meta.canSubmit && meta.isTouched && emailValue)
            }
            loading={loading}
            text={`${t('submit')}`}
            intent="primary"
          />
        </div>
      </ForgotPasswordFormInstance.Form>
    </Card>
  );
};
