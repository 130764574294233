import "./LoginLayout.css";
import { Card } from "@blueprintjs/core";

import { UmojaAltIcon } from "../../components/Icons/UmojaAltIcon";
import { LanguageSelector } from "../Header/UmojaHeader";
import { useTranslation } from "react-i18next";

type LoginLayoutProps = {
  children?: React.ReactChild | React.ReactChild[];
};

function LoginLayout({ children }: LoginLayoutProps) {
  const { t } = useTranslation();
  return (
    <div className="login__form-outer_wrapper">
      <div className="login__form-wrapper--dark">
        <div className="login__card-title_wrapper">
          <div className="login__card-title_wrapper--text">
            <div className="login__card-icon">
              <UmojaAltIcon />
              <h1>umoja</h1>
            </div>
            <p className="login__card-title_wrapper--content">
              {`${t('login_page_description')}`}
            </p>
          </div>
          <Card className="login__form-wrapper-infobox" interactive>
            <div className="login__form-wrapper-infobox_title_wrapper">
              <div className="activity__dot" />
              <h4 className="login__form-wrapper-infobox-title-text">
                {`${t('learn_more_about_umoja')}`}
              </h4>
            </div>
          </Card>
        </div>
        <div className="login__page-subtext_wrapper_one">
          <a href="/#" className="login__page-link-text--light">
            {`${t('privacy_policy')}`}
          </a>
          <br />
          <a href="/#" className="login__page-link-text--light">
            {`${t('terms_of_service')}`}
          </a>
        </div>
      </div>
      <div className="login__form-wrapper--light">
        <div className="login__page-language-selector">
          <LanguageSelector />
        </div>
        {children}
        <div className="login__page-subtext_wrapper_two">
          <div className="login__page-link-text">
            {`${t('having_trouble_signin')}`}
          </div>
          <a href="/#" className="login__page-link-text--purple">
            {`${t('contact_support')}`}
          </a>
        </div>
        <Card className="login__form-wrapper-infobox--responsive" interactive>
          <div className="login__form-wrapper-infobox_title_wrapper">
            <div className="activity__dot" />
            <h4 className="login__form-wrapper-infobox-title-text">
              {`${t('learn_more_about_umoja')}`}
            </h4>
          </div>
        </Card>
      </div>
      <div className="login__page-subtext_wrapper_three">
        <div>
          <a href="/#" className="login__page-link-text--purple">
            {`${t('privacy_policy')}`}
          </a>
          <br />
          <a href="/#" className="login__page-link-text--purple">
            {`${t('terms_of_service')}`}
          </a>
        </div>
        <div>
          <div className="login__page-link-text">
            {`${t('having_trouble_signin')}`}
          </div>
          <a href="/#" className="login__page-link-text--purple">
            {`${t('contact_support')}`}
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
