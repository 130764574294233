import UmojaFeaturedLineChartWidget from "../../../components/Widgets/ChartWidget/UmojaFeaturedLineChartWidget";

interface ChartAreaProps {
  graphData: any[];
  timeframe: any;
  theme: any;
  currency: string;
}

export const ChartArea = ({ graphData, timeframe, theme, currency }: ChartAreaProps) => {
  return (
    <div className="featured__charts-chart-area">
      <UmojaFeaturedLineChartWidget
        theme={theme}
        timeframe={timeframe}
        data={graphData.map((item: any) => item)}
        currency={currency}
      />
    </div>
  );
};
