import { useState } from "react";
import { useField, useFormContext } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components";
import { formatDecimalNumber } from "../../../../../utils";
import { mapCurrencySign } from "../../../../../utils/currencyMap";
import { OrganizationProject } from "../../../../Wallets/OrganizationWallets";

interface TargetDistributionAmountInputProps {
  project: OrganizationProject;
  readOnly: boolean;
  disabled: boolean;
}

export const TargetDistributionAmountInput = ({
  project,
  readOnly,
  disabled,
}: TargetDistributionAmountInputProps) => {

  const { t } = useTranslation();
  const validateInput = (input: string = "") => {
  if (input.length < 1 || !input.toString().match(/^[0-9]*$/)) {
    return t("only_valid_number_validation");
  }
  return false;
};

  
  const {
    meta: { error, isTouched },
    getInputProps,
    setValue,
  } = useField("target_distribution_amount", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: project?.project_details?.target_distribution_amount || "",
  });

  const [fieldState, setFieldState] = useState(
    `${project?.project_details?.target_distribution_amount || ""}`
  );
  const { values } = useFormContext();

  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/,/g, "");

    if (isNaN(value)) {
      setFieldState(value);
    } else {
      setFieldState(formatDecimalNumber(value));
    }
    setValue(value);
  };

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="target_disbursement_amount" />
      </label>
      <div>
        <TextInput
          {...getInputProps()}
          type="text"
          placeholder={
            readOnly
              ? project?.project_details?.target_distribution_amount
              : "380,000"
          }
          readOnly={readOnly}
          onChange={handleChange}
          value={fieldState}
          disabled={disabled}
          style={{ height: 40, width: 200 }}
          rightElement={
            <span className="settings__card-input-list-item__input-icon">
              {mapCurrencySign[values.selected_currency]}
            </span>
          }
        />
        {isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

// validate input as needed
