import { useField } from "react-form";
import { TextInput } from "../../../components";


interface CustomTextInputInputProps {
  defaultValue?: string;
  readOnly?: boolean;
  placeholder: string;
  validationError: string;
  valueName: string;
  name: string;
  label: string;
}

export const CustomTextInput = ({
  label,
  placeholder,
  validationError,
  valueName,
  name,
}: CustomTextInputInputProps) => {
 
  const validateInput = (input: string) => {
  if (input.length < 1) {
    return validationError;
  }
  return false;
};

  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField(valueName, {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });
 
  return (
    <li className="organization__form-input-list-item">
      <label>
        {label}
      </label>
      <div className="input_container">
        <TextInput
            {...getInputProps()}
            type="text"
            placeholder={placeholder}
            name={name}
            className="input_field"
        />
        {isTouched && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
      </div>
    </li>
  );
};

