import "./UmojaPaymentWidget.css";
import { useState } from "react";
import { UmojaPaymentWidgetFooter } from "./__internal/PaymentWidgetFooter";
import { DefaultWalletPanel } from "./panels/DefaultWalletPanet";

export interface UmojaPaymentWidgetProps {
  disabled: boolean;
  masterWalletBalance: string;
  currency: string;
}

export type UmojaEmptyPaymentWidgetModes = "empty";

const UmojaEmptyPaymentWidget = ({
  disabled,
  masterWalletBalance,
  currency
}: UmojaPaymentWidgetProps) => {
  const [mode, setMode] = useState<UmojaEmptyPaymentWidgetModes | "">("empty");


  return (
    <div className="payment__widget-wrapper">
      <div className="payment__widget-container">
        <DefaultWalletPanel balance={masterWalletBalance} currency={currency} />
      </div>
      <UmojaPaymentWidgetFooter
        mode={mode}
        setMode={setMode}
        disabled={disabled}
      />
    </div>
  );
};

export default UmojaEmptyPaymentWidget;
