import { useQuery } from "react-query";

import { getWallets } from "../services";

export default function useWallets(organizationId: string | number) {
  return useQuery(
    ["wallets", organizationId],
    () => getWallets(organizationId),
    { initialData: [], enabled: !!organizationId }
  );
}
