import { ReactNode } from "react";
import { Alert, IconName, Intent, MaybeElement } from "@blueprintjs/core";

interface UmojaAlertProps {
  handleClose: Function;
  cta: string;
  message: ReactNode;
  icon?: IconName | MaybeElement;
  intent?: Intent | undefined;
}

const UmojaAlert = ({
  handleClose,
  cta,
  message,
  icon,
  intent,
}: UmojaAlertProps) => {
  return (
    <Alert
      confirmButtonText={cta}
      isOpen={true}
      loading={false}
      icon={icon}
      onClose={() => handleClose()}
      intent={intent}
      style={{ background: "#EBF1F5" }}
    >
      {message}
    </Alert>
  );
};

export default UmojaAlert;
