export async function getTransactions(
  organizationId: number,
  walletId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/wallets/${walletId}/transactions`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  const data = await response.json();
  return data;
}

export async function getProgramTransactionsHistory(
  organizationId: number,
  programId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/programs/${programId}/transactions`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function getProgramDisbursementHistory(organizationId: number) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/disbursements`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function getProjectStats(
  organizationId: number,
  projectId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/main-stats`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function getAverageWalletBalance(
  organizationId: number,
  projectId: number
) {
  const response = await fetch(
    `${process.env.REACT_APP_UMOJA_MANAGEMENT_API_DOMAIN}/organizations/${organizationId}/projects/${projectId}/avg-wallet-balance`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
}
