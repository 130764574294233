import "../../UmojaPaymentWidget.css";
import { MasterWalletPanel } from "./MasterWalletPanel/MasterWalletPanel";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { RecipientWallet } from "../../../../../screens/Home/GlobalOperations";

interface UmojaWalletPanelProps {
  setMode: Function;
  balance: string;
  currency: string;
  wallets: RecipientWallet[]
}

export const WalletPanel = ({ setMode, balance, currency, wallets }: UmojaWalletPanelProps) => {
  const { t } = useTranslation();
  return (
    <div className="payment__widget-container--padding">
      <MasterWalletPanel balance={balance} title={t("master_wallet")} currency={currency} />
      <div className='payment__widget-wallet_wrapper'>
        <div
          className="wallet-panel_button wallet-panel_manage-wallet"
          onClick={() => setMode('')}
          style={{ border: "none" }} //TODO: remove this line when functionality is implemented
        >
          <ReactSVG src="/settings.svg" />
          <div>
            <p className="no-bottom-margin" style={{ fontWeight: 700 }}>Manage Wallet Group</p>
            <p className="no-bottom-margin">Create and edit wallet groups</p>
          </div>
        </div>
          <div className={`wallet-panel_button wallet-panel_disburse-fund`} onClick={() => setMode("disburse")}>
          <ReactSVG src="/disburse.svg" />
          <div>
            <p className="no-bottom-margin" style={{ fontWeight: 700 }}>{t("disburse_funds")}</p>
            <p className="no-bottom-margin">{t("send_cash_to_wallet")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
