import { NavLink } from "react-router-dom";
import { Icon } from "@blueprintjs/core";

import { Organization } from "./DashboardSidebar";

export interface SidebarLinkProps {
  selectedOrganization: Organization;
  config: Record<string, string>[];
}

export function SidebarLinks({
  selectedOrganization,
  config,
}: SidebarLinkProps) {
  return (
    <>
      {config.map((item: any, index: number) => (
        <div className="sidebar__item" key={index}>
          <NavLink
            to={`/dashboard/organizations/${selectedOrganization?.id}/${item.link}`}
            activeStyle={{ color: item.activeStyle }}
          >
            <Icon icon={item.icon} />
            {item.text}
          </NavLink>
        </div>
      ))}
    </>
  );
}
