import { useQuery } from "react-query";
import { getProgramTransactionsHistory } from "../services";

export default function useProjectTransactionsHistory(
  organizationId: string,
  walletId: string
) {
  return useQuery(
    ["transactions", organizationId, walletId],
    () =>
      getProgramTransactionsHistory(
        parseInt(organizationId),
        parseInt(walletId)
      ),
    {
      enabled: !!walletId,
      initialData: { transactions: [] },
    }
  );
}
