import { useField } from "react-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../components";

export const PasswordInput = () => {
  const { t } = useTranslation();
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("credentials.password", {
    validate: validatePassword,
    defaultIsTouched: false,
  });

  return (
    <div className="login__card-input-wrapper">
      <TextInput
        {...getInputProps()}
        required
        name="password"
        type="password"
        placeholder={`${t('password')}`}
      />
      {isTouched && <p style={{ color: "red" }}>
        {error?.includes("required") && t("password_required")}
        {error?.includes("characters") && t("password_length_validation")}
      </p>}
    </div>
  );
};

const validatePassword = (password: string) => {
  if (password.length < 1) {
    return "Password is required";
  }
  if (password.length < 8) {
    return "Password must be at least 8 characters";
  }
  return false;
};
