import { Button, Classes } from "@blueprintjs/core";
import { UmojaDialog } from "../../components";
import { useTranslation } from "react-i18next";

interface ForgotPasswordDialogProps {
  showResetPasswordDialog: boolean
  toggleResetDialog: Function
}



const ForgotPasswordDialog = ({ showResetPasswordDialog, toggleResetDialog }: ForgotPasswordDialogProps) => {
  const { t } = useTranslation()
  return (
  <UmojaDialog isOpen={showResetPasswordDialog} handleClose={toggleResetDialog}>
    <div className={Classes.DIALOG_BODY}>
    <div className="forgot-password__wrapper">
      <h1 className="forgot-password__wrapper-header">{t('forgot_password_header')}</h1>
      <p className="forgot-password__wrapper-text">{t('email_containing_a_link')}</p>
      <div className="forgot-password__wrapper__option">
        <h3 className="forgot-password__wrapper__option-header">{t('forgot_password_wrapper_text')}</h3>
        <p className="forgot-password__wrapper__option-text">
          {t('forgot_password_wrapper_option_header')}
        </p>
        <ol className="forgot-password__wrapper__option-list">
          <li className="mt-2">{t('forgot_password_wrapper_option_text_one')}</li>
          <li className="mt-2">
            {t('forgot_password__wrapper__option_text_two')}
          </li>
        </ol>
      </div>
    </div>

    </div>

    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={() => toggleResetDialog()} text={t('close')} />
      </div>
    </div>
  </UmojaDialog>
  )
}

export default ForgotPasswordDialog;
