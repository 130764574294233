import "./Analytics.css";
import { PropagateLoader } from "react-spinners";

type TransactionCardProps = {
  title: String;
  content: String;
  isLoading: Boolean;
}

const TransactionCard = ({ title, content, isLoading }: TransactionCardProps) => {
  return (
    <div className="transaction-card_content-wrapper">
      <p className="transaction-card_content-title">{title}</p>
      {isLoading
        ?
        <div className="transaction-card_content-loader">
          <PropagateLoader size={10} color={'#6200ee'} />
        </div>
        : <p className="analytics-card_content-count">{content}</p>}
    </div>
  );
}

export default TransactionCard