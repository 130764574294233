import { ReactNode } from "react";

import { Dialog, DialogProps } from "@blueprintjs/core";

interface UmojaDialogProps extends DialogProps {
  isOpen: boolean;
  handleClose: Function;
  children: ReactNode;
}

const UmojaDialog = ({
  isOpen,
  handleClose,
  children,
  ...rest
}: UmojaDialogProps) => {
  return (
    <Dialog isOpen={isOpen} onClose={() => handleClose()} {...rest}>
      {children}
    </Dialog>
  );
};

export default UmojaDialog;
