import { useState } from "react";
import { Checkbox, Tag } from "@blueprintjs/core";
import { useForm } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { UmojaToast } from "../../../../components/Popups/UmojaToast";
import { OrganizationProject } from "../../../Wallets/OrganizationWallets";

interface ReviewAndDeployFormProps {
  project: OrganizationProject;
  activeStep: any,
  setActiveStep: any,
  account: any
}

export const ReviewAndDeployForm = ({ project, activeStep, setActiveStep, account }: ReviewAndDeployFormProps) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [checked, setChecked] = useState(false);

  const onboardingStep = 4;

  const disabled = project
    ? Boolean(project.project_onboarding_step < onboardingStep)
    : true;
  const readOnly = Boolean(project?.published_at);

  const ReviewAndDeployFormInstance = useForm({
    onSubmit: async (values, instance) => {
      const data = new FormData();
      data.append("published_at", new Date().toISOString());
      setSaving(true);
      // handle api here
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT}/${project.id}/onboarding/${onboardingStep}` as string,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: data,
        }
      );
      // network/server failure
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_publishing_project")}`,
          intent: "danger",
          icon: "issue",
        });
        setSaving(false);
        return;
      }
      // success
      const json = await response.json();
      UmojaToast.show({
        message: json.message,
        intent: "success",
        icon: "tick",
      });
      account.refetch(); // refactor to useMutation
      setActiveStep(5);
      setSaving(false);

      // clear uploaded file names
      localStorage.removeItem('participant');
      localStorage.removeItem('merchant');
    },
    // debugForm: true,
  });

  return (
    <ReviewAndDeployFormInstance.Form>
      <div className="settings__card-header">
        <div className="settings__card-title_wrapper">
          <h2 className="settings__card-step">
            <Trans i18nKey="step_four" />
          </h2>
          <h4 className="settings__card-title">
            <Trans i18nKey="ready_to_deploy" />
            <ReviewAndDeployFormStatusIndicator
              project={project}
              onboardingStep={onboardingStep}
            />
          </h4>

          <p className="settings__card-subtitle">
            <Trans i18nKey="ready_to_deploy_subtitle" />
          </p>
        </div>
        <Button icon="double-caret-vertical" onClick={() => setActiveStep(4)} />
      </div>
      {activeStep === 4 && (
        <>
          <ul className="settings__card-input-list--two_col">
            <li className="settings__card-input-list-item">
              <label style={{ display: "flex" }}>
                <Trans i18nKey="ready_to_launch" />?
                <Checkbox
                  checked={checked}
                  label={t("everything_checks_out")}
                  onChange={() => setChecked(!checked)}
                  style={{ marginLeft: 20 }}
                  disabled={disabled || readOnly}
                />
              </label>
            </li>
          </ul>
          <div className="settings__card-step_button_wrapper">
            <p>
              <Trans i18nKey="ready_to_deploy" />?
            </p>
            <Button
              type="submit"
              intent="primary"
              text={t("launch_project")}
              loading={saving}
              disabled={!checked || readOnly}
            />
          </div>
        </>
      )}
    </ReviewAndDeployFormInstance.Form>
  );
};

interface SettingsFormStatusIndicatorProps {
  project: OrganizationProject | null;
  onboardingStep: number;
}

export const ReviewAndDeployFormStatusIndicator = ({
  project,
  onboardingStep,
}: SettingsFormStatusIndicatorProps) => {
  const { t } = useTranslation();
  if (!project) {
    return null;
  }

  return project?.published_at ? (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="success"
    >
      {t("section_complete")}
    </Tag>
  ) : (
    <Tag
      className="settings__status_indicator"
      htmlTitle="incomplete section"
      intent="warning"
    >
      {t("section_incomplete")}
    </Tag>
  );
};
