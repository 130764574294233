import "../../UmojaPaymentWidget.css";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { H3, H6 } from "@blueprintjs/core";
import { MasterWalletPanel } from "../WalletPanel/MasterWalletPanel/MasterWalletPanel";

interface DefaultWalletPanelProps {
  balance: string;
  currency: string;
}

export const DefaultWalletPanel = ({ balance, currency }: DefaultWalletPanelProps) => {
  const { t } = useTranslation();
  return (
    <div className={"default_wallet_container"}>
      { Number(balance) > 0 ?
        (
          <div className={"master_wallet_wrapper"}>
           <MasterWalletPanel balance={balance} title={t("master_wallet")} currency={currency} />
          </div>
        ) : (
          <H3 className={"master_wallet_title"}>{t('master_wallet')}</H3>
        )
      }
      <div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className={"master_wallet_svg_circle"} >
            <ReactSVG src="/widget-icon.svg" />
          </div>
        </div>
      </div>
      {
        Number(balance) <= 0 ? (
            <div className={"master_wallet_info_container"}>
              <div className={"master_wallet_info_up"}>
                <H6 style={{ width: "40%", textAlign: "center", fontWeight: 600}}>{t('no_funds')}</H6>
              </div>
              <div className={"master_wallet_info_down"}>
                <div style={{ display: "flex", justifyContent: "center", width: "75%", textAlign: "center", alignItems: "center" }}>{t('please_endure')}</div>
              </div>
            </div>
        ):
        (
          <div className={"master_wallet_info_container"}>
            <div className={"master_wallet_info_no_project"}>
               <H6 style={{ width: "50%", textAlign: "center", fontWeight: 700, fontSize: "15px" }}>{t('get_started')}</H6>
            </div>
         </div>)
      }
    </div>
  );
};
