import { H5, Icon } from "@blueprintjs/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export interface AddGroupButtonProps {
  setCreateView: Function,
  setEditView: Function
  setActiveGroup: Function
  setGroupName: Function
}

const AddGroupButton: FunctionComponent<AddGroupButtonProps> = ({ setCreateView, setEditView, setActiveGroup, setGroupName }) => {
  const { t } = useTranslation();

  const handleCreateGroup = () => {
    setCreateView(true)
    setActiveGroup(null)
    setEditView(false)
    setGroupName('')
  }

  return (
    <div
      className="add__group_button"
      onClick={handleCreateGroup}
      style={{ color: "white", backgroundColor: "#3A3A3A", display: "flex", height: "60px", alignItems: "center", justifyContent: "flex-start", paddingLeft: "20px" }}

    >
      <Icon icon={"add"} iconSize={30} color={"white"}  />
      <H5 style={{ color :"white", marginLeft: "10px", paddingTop: "10px"}}>{t('create_new_wallet')}</H5>
    </div>
  );
};

export default AddGroupButton
