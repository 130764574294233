import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { english } from "./localization/en";
import { spanish } from "./localization/es";
import { french } from "./localization/fr";
import { creole } from "./localization/hat";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      "en-US": {
        translations: english,
      },
      hat: {
        translations: creole,
      },
      fr: {
        translations: french,
      },
      es: {
        translations: spanish,
      },
    },
    fallbackLng: "en-US",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

// @ts-ignore
export default i18n;
