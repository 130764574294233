import { Table } from "../../../../components";

interface TeamMembersTableProps {
  data: Array<any>;
  columns: Array<any>;
  handleRowClick?: Function;
}

export const TeamMembersTable = ({
  data,
  columns,
}: TeamMembersTableProps) => {
  return (
    <div className="settings__card-team_management--team_wrapper">
      {data ? (
        <Table
          columns={columns}
          data={data}
        />
      ) : (
        <Table columns={columns} data={[]} />
      )}
    </div>
  );
};
