import { useAccount } from ".";
import { useOrganizationId } from "./useOrganizationId";

export enum AccountRole {
  SuperAdmin,
  Admin,
  Organizer,
  Viewer,
}
export namespace AtLeast {
  export function Role(currentRole: string, requiredRoles: string[]) {
    return requiredRoles.includes(currentRole); // Must be less or equal.
  }

  // Should be at least super-admin.
  export function SuperAdmin(currentRole: string) {
    const permission = ["SuperAdmin"]
    return Role(currentRole, permission);
  }

  // Should be at least admin.
  export function Admin(currentRole: string) {
    const permission = ["SuperAdmin", "Admin"]
    return Role(currentRole, permission);
  }

  // Should be at least organizer.
  export function Organizer(currentRole: string) {
    const permission = ["SuperAdmin", "Admin", "Organizer" ]
    return Role(currentRole, permission);
  }

  // Should be at least viewer.
  export function Viewer(currentRole: string) {
    const permission = ["SuperAdmin", "Admin", "Organizer", "VIEWER"]
    return Role(currentRole, permission);
  }
}

const useRole = () => {
  const user = useAccount();
  const selectedOrganizationId = useOrganizationId();
  const selectedOrganization = user?.data?.organizations.find(
    (data: any) => Number(data.id) === Number(selectedOrganizationId)
  );
  return selectedOrganization?.role_name;
};

export default useRole;
