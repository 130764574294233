import "../OrganizationWallets.css";
import { useState } from "react";

import { Button } from "@blueprintjs/core";
import { OrganizationWallet } from "../../../components/Widgets/PaymentWidget/UmojaPaymentWidget";
import { UmojaDialog } from "../../../components";
import { useForm } from "react-form";
import { CardSelectInput, UmojaCard } from "../forms/inputs/CardSelectInput";
import { useParams } from "react-router-dom";
import { useCards } from "../../../hooks";
import { UmojaToast } from "../../../components/Popups/UmojaToast";
import { Organization } from "../OrganizationWallets";
import { Trans,useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";


const { REACT_APP_WALLET_REGISTRATION_ENDPOINT } = process.env;

interface RegisterUmojaCardFormProps {
  isOpen: boolean;
  handleClose: Function;
  wallet: OrganizationWallet;
  organization: Organization;
  cards: UmojaCard[];
}

export const RegisterUmojaCardForm = ({
  isOpen,
  handleClose,
  wallet,
  organization,
  cards,
}: RegisterUmojaCardFormProps) => {
  const { t } = useTranslation();
  const { organizationId } = useParams<{
    organizationId: string;
    walletId: string;
  }>();
  const cardsQuery = useCards(organizationId);
  const [saving, setSaving] = useState(false);
  const QueryClient = useQueryClient()
  const RegisterUmojaCardFormInstance = useForm({
    onSubmit: async (values: Record<string, any>, instance) => {
      setSaving(true);
      // handle api here
      const response = await fetch(
        `${REACT_APP_WALLET_REGISTRATION_ENDPOINT}/${organizationId}/wallets/${wallet.id}/register`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            card_number: values.umoja_card_number,
          }),
        }
      );
      if (!response.ok) {
        UmojaToast.show({
          message: `${t("problem_registering_umoja_card")}`,
          intent: "danger",
          icon: "issue",
        });
      } else {
        const json = await response.json();
        UmojaToast.show({
          message: `${t("successfully_registered_card")} ${json.card_reference} -> ${wallet.name}!`,
          intent: "success",
          icon: "tick",
        });
        await QueryClient.invalidateQueries("wallet")
        // it's nice to have a little pause to read the toast, before closing the dialog
        // and mutating the cache
        setTimeout(() => {
          handleClose();
          cardsQuery.refetch();
        }, 200);
      }
      setSaving(false);
    },
    // debugForm: true,
  });

  let { values } = RegisterUmojaCardFormInstance;
  return (
    <UmojaDialog
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseButtonShown
      title={t('register_program_card')}
    >
      <RegisterUmojaCardFormInstance.Form>
        <div className="register_wallet__modal_wrapper">
          <Trans i18nKey="register_card_confirm"  >
            Click Register Card to link an Umoja Card to  <strong>{{ wallet: wallet?.name }}</strong> wallet
          </Trans>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px"
            }}
          >
            <CardSelectInput orgCards={cards} />
            <Button
              type="submit"
              intent="success"
              text={t('register_card')}
              loading={saving}
              disabled={!values.umoja_card_number}
            />
          </div>
        </div>
      </RegisterUmojaCardFormInstance.Form>
    </UmojaDialog>
  );
};
