import { Button, Icon } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { RecipientWallet } from "..";

export interface WalletSelectorInputProps {
  wallet: RecipientWallet;
  setValue: Function;
  activeGroup: RecipientWallet;
  setActiveGroup: Function;
  settings: boolean
  groupButton: boolean
}

export const WalletSelectorInput = ({
  wallet,
  setValue,
  activeGroup,
  setActiveGroup,
  settings,
  groupButton
}: WalletSelectorInputProps) => {
  const { t } = useTranslation();
  const toggleSelected = () => {
    let activeWalletGroup;
    if (activeGroup.groupId === wallet.groupId){
      activeWalletGroup = {}
      setActiveGroup(activeWalletGroup);
      setValue(() => {});
    }else {
      activeWalletGroup = wallet
      setActiveGroup(activeWalletGroup);
      setValue(() => wallet);
    }
  };

  const isActive = activeGroup?.groupName === wallet?.groupName ;
  return (
    <Button
      minimal
      className={`wallet__selector_input-wrapper ${ groupButton && isActive ? "group_button" : ""}`}
      onClick={toggleSelected}
      fill
      disabled={!wallet?.groupWalletCount}
      icon={
        <ReactSVG src="/disburseTo.svg"
          className={`${isActive
            ? "wallet__selector_input-wrapper_indicator--active"
            : ""
            }`}
        />
      }
      rightIcon={settings ? <Icon icon={"settings"} iconSize={30} />:
        <ReactSVG src={`${isActive
          ? "/toggle-active.svg"
          : "/toggle-inactive.svg"
          }`}
        />
      }
      alignText="left"
    >
      <p className="wallet__selector-group-name">
        {wallet?.groupTag ? wallet?.groupTag : wallet.groupName }
      </p>
      <p className="wallet__selector-wallet-count">
        {wallet?.groupWalletCount} {t("wallets")}
      </p>
    </Button>
  );
};
