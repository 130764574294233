import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { useTranslation } from "react-i18next";

interface ProgramCurrencyProps {
  disabled?: boolean;
}

export const ProgramCurrencyInput = ({
  disabled,
}: ProgramCurrencyProps) => {

   const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("program_currency_validation");
  }
  return false;
}; 
  const { meta: { error, isTouched }, getInputProps } = useField("selected_currency", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });
  const { t } = useTranslation();


  return (
    <li className="organization__form-input-list-item">
      <label>{t('project_currency')}</label>
      <div className="input_container">
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: t("select_currency"), value: "" },
            { label: "Kenyan Shillings", value: "KES" },
            { label: "Haitian Gourde", value: "HTG" },
            { label: "United States Dollar", value: "USD" },
            { label: "European Union Euro", value: "EUR" },
            { label: "Vatu", value: "VUV" },
            { label: "Mexican Peso", value: "MXN" },
          ]}
          required
          name="projectCurrency"
          disabled={disabled}
          className="input_field"
        />
        {isTouched && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
        </div>
    </li>
  );
};


