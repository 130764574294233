import { useField } from "react-form";
import { FormInputType, FormValuesType } from "../../../Settings";
import { TextInput } from "../../../../../components";
import { Trans, useTranslation } from "react-i18next";

interface PasswordInputProps {
  disabled: boolean;
}

export const ConfirmPasswordInput = ({ disabled }: PasswordInputProps) => {
  const { t } = useTranslation();
  const validateInput = (password: string, instance: FormInputType) => {
    const formValues = instance.form.values as FormValuesType;
    const { new_password } = formValues.settings;
      if (password.length < 1) {
        return t("password_required");
      }
      if (password.length < 8) {
        return t("password_length_validation");
      }
      if (password !== new_password) {
        return t("confirm_new_password");
      }
      return false;
    }
  const ConfirmPasswordInputInstance = useField("settings.confirm_password", {
    validate: validateInput ,
    defaultIsTouched: false,
    defaultValue: "",
  });

  const { meta: { error, isTouched }, getInputProps } = ConfirmPasswordInputInstance;

  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="confirm_password" />
      </label>
      <div style={{ width: 200 }}>
        <TextInput
          {...getInputProps()}
          type="password"
          placeholder={`${t('confirm_password')}`}
          disabled={!disabled}
        />
        {isTouched && disabled && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
    </li>
  );
};
