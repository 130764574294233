import { forwardRef } from "react";
import { useState } from "react";
import { useForm, useField } from "react-form";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  FileInput,
  FormGroup,
  HTMLSelect,
  Label,
} from "@blueprintjs/core";

import { Button, Layout, TextInput } from "../components";
import { useTranslation } from "react-i18next";

const PROJECT_TYPES = [
  "Humanitarian/Relief",
  "Development",
  "MicroFinance",
  "Private Sector (Business)",
];

const FOCUS_TYPES = [
  "Health",
  "Nutrition",
  "Food Security",
  "Cash Assistance",
  "Voucher Assistance",
  "Housing/Shelter",
  "Water and Sanitation",
  "Livelihood/Economic Support",
  "Education",
  "Gender Equity",
];

async function validateProjectName(name: string) {
  if (!name) {
    return "Name is required.";
  }
}

function validateProjectType(type: string) {
  return PROJECT_TYPES.indexOf(type) > -1 ? undefined : "Invalid type";
}

function validateProjectFocus(focus: string) {
  return FOCUS_TYPES.indexOf(focus) > -1 ? undefined : "Invalid type";
}

function validateParticipantsExcel(excel: string, instance: any) {
  if (!excel) {
    return "Invalid file content";
  }
}

function ProjectNameInput() {
  const { getInputProps } = useField("program.name", {
    validate: validateProjectName,
  });

  return (
    <FormGroup>
      <Label>
        Name
        <TextInput
          {...getInputProps()}
          required
          name="projectName"
          type="text"
          placeholder="Project Name"
        />
      </Label>
    </FormGroup>
  );
}

function ProjectTypeInput() {
  const { getInputProps } = useField("program.type", {
    validate: validateProjectType,
  });
  return (
    <FormGroup>
      <Label>
        Type
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: "Select type", disabled: true, value: "" },
            ...PROJECT_TYPES,
          ]}
          required
          name="projectType"
        />
      </Label>
    </FormGroup>
  );
}

function ProjectFocusInput() {
  const { getInputProps } = useField("program.focus", {
    validate: validateProjectFocus,
  });

  const { t } = useTranslation();


  const focus_types = [
    { label: t("health"), value: "health" },
    { label: t('nutrition'), value: 'nutrition' },
    { label: t('food_security'), value: 'food_security' },
    { label: t('cash_assistance'), value: 'cash_assistance' },
    { label: t('voucher_assistance'), value: 'voucher_assistance' },
    { label: t('housing_shelter'), value: 'housing_shelter' },
    { label: t('water_and_sanitation'), value: 'water_and_sanitation' },
    { label: t('livelihood_and_economic_support'), value:'livelihood_and_economic_support'  },
    { label: t('education'), value: 'education' },
    { label: t('gender_equality'), value: 'gender_equality'}
  ]
  return (
    <FormGroup>
      <Label>
        Focus
        <HTMLSelect
          {...getInputProps()}
          defaultValue=""
          options={[
            { label: "Select focus", disabled: true, value: "" },
            ...focus_types,
          ]}
          required
          name="projectFocus"
        />
      </Label>
    </FormGroup>
  );
}

const ProjectBeneficiariesInput = forwardRef<HTMLInputElement>((props, ref) => {
  const { getInputProps, value, setValue } = useField("program.beneficiaries", {
    validate: validateParticipantsExcel,
  });
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  //@ts-ignore
  const { ref: _ref } = getInputProps({ ref });

  return (
    <FormGroup>
      <Label>
        Participants
        <div>
          <FileInput
            text={fileName}
            hasSelection={!!value}
            inputProps={{
              ref: _ref,
              required: true,
              onChange: (event) => {
                const target = event.target as HTMLInputElement & EventTarget;
                const { files } = target;
                if (!files || files.length < 1) {
                  setFileName(undefined);
                  setValue("");
                  return;
                }
                const [file] = Array.from(files);
                setFileName(file.name);
                //@ts-ignore
                setValue(file);
              },
              accept:
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            }}
          />
        </div>
      </Label>
    </FormGroup>
  );
});

const ProjectMerchantsInput = forwardRef<HTMLInputElement>((props, ref) => {
  const { getInputProps, value, setValue } = useField("program.merchants", {
    validate: validateParticipantsExcel,
  });
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  // @ts-ignore
  const { ref: _ref } = getInputProps({ ref });

  return (
    <FormGroup>
      <Label>
        Merchants
        <div>
          <FileInput
            text={fileName}
            hasSelection={!!value}
            inputProps={{
              ref: _ref,
              required: true,
              onChange: (event) => {
                const target = event.target as HTMLInputElement & EventTarget;
                const { files } = target;
                if (!files || files.length < 1) {
                  setFileName(undefined);
                  setValue("");
                  return;
                }
                const [file] = Array.from(files);
                setFileName(file.name);
                //@ts-ignore
                setValue(file);
              },
              accept:
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            }}
          />
        </div>
      </Label>
    </FormGroup>
  );
});

type CreateProjectFormProps = {
  organization_id: string;
};

function CreateProjectForm(props: CreateProjectFormProps) {
  const history = useHistory();
  const {
    Form,
    meta: { canSubmit },
  } = useForm({
    onSubmit: async (values, instance) => {
      const data = new FormData();
      //@ts-ignore
      data.append("program.name", values.program.name);
      //@ts-ignore
      data.append("program.type", values.program.type);
      //@ts-ignore
      data.append("program.focus", values.program.focus);
      //@ts-ignore
      data.append("program.beneficiaries", values.program.beneficiaries);
      //@ts-ignore
      data.append("program.merchants", values.program.merchants);
      //@ts-ignore
      data.append("program.organization.id", props.organization_id);

      const response = await fetch(
        process.env.REACT_APP_CREATE_PROJECT_ENDPOINT as string,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: data,
        }
      );
      history.push(`/dashboard/organizations/${props.organization_id}/wallets`);
      if (!response.ok) {
        throw new Error("Please make sure your credentials are correct.");
      }
    },
    // debugForm: true,
  });

  return (
    <Card>
      <Form encType="multipart/form-data">
        <ProjectNameInput />
        <ProjectTypeInput />
        <ProjectFocusInput />
        <ProjectBeneficiariesInput />
        <ProjectMerchantsInput />
        <Button
          type="submit"
          disabled={!canSubmit}
          icon="plus"
          text="Create"
          intent="primary"
        />
      </Form>
    </Card>
  );
}

function OrganizationCreateScreen() {
  let { organizationId } = useParams<{ organizationId: string }>();
  return (
    <Layout.Container>
      <h1>Create a Project</h1>
      <CreateProjectForm organization_id={organizationId} />
    </Layout.Container>
  );
}

export default OrganizationCreateScreen;
