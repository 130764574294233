   import {
  NavLink,
  NavLinkProps,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Trans } from "react-i18next";
import { Layout, UmojaHeader, Breadcrumb } from "../../components";
import { useAccount, useOrganization, useProjects } from "../../hooks";
import { Card } from "@blueprintjs/core";
import "./OrganizationSettings.css";
import { AccountSettings } from "./AccountSettings";
import { OrganizationSettings } from "./OrganizationSettings";
import { ProjectManagement } from "./ProjectManagement";
import {
  UseFieldInstance,
  UseFieldInstanceMeta,
  UseFieldInstancePropsType,
  UseFormInstanceMeta,
} from "react-form";
import { useRole } from "../../hooks";
import { useOrganizationId } from "../../hooks/useOrganizationId";
import { ResourcesSettings } from "./ResourcesSettings";
import useApiVersion from "../../hooks/useApiVersion";
import { useState } from "react";
import WalletGroup from "./WalletGroup";
import useGroups from "../../hooks/useGroups";
import {
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult, QueryObserverSuccessResult
} from "react-query";
   import { AtLeast } from "../../hooks/useRole";

export type ButtonLinkProps = NavLinkProps;

export type FormValuesType = Record<string, any>;

export type FormInputType = UseFieldInstance<
  string,
  string,
  unknown,
  UseFieldInstanceMeta<string>,
  UseFormInstanceMeta<string>,
  UseFieldInstancePropsType<string>
>;

export function ButtonLink(props: ButtonLinkProps) {
  return <NavLink className="sidebar__link" {...props}></NavLink>;
}

function getProject(projects: QueryObserverIdleResult<any, unknown> | QueryObserverLoadingErrorResult<any, unknown> | QueryObserverLoadingResult<any, unknown> | QueryObserverRefetchErrorResult<any, unknown> | QueryObserverSuccessResult<any, unknown>, organizationId: string) {
  const project = projects?.data?.programs?.filter((data: any) => {
    return data.organization_id === Number(organizationId);
  });
  return project;
}

function DashboardSettingsScreen() {
  const account = useAccount();
  const organizationId = useOrganizationId();
  const organization = useOrganization(organizationId);
  const apiVersion = useApiVersion();
  const projects = useProjects();
  const project = getProject(projects, organizationId);
  const groups = useGroups(organizationId, project?.[0]?.id);


  const role = useRole();
  // there should only be one unpublished project at a time
  const unpublishedProject = account.data.draft_programs.find((data: any) => {
    // return the latest draft project
    return data[data.length - 1]?.organization_id === Number(organizationId);
  });

  const latestPublishedProject = account.data.published_programs.find((data: any) => {
    // return the latest published project
    return data[data.length - 1]?.organization_id === Number(organizationId);
  });
  const [openNewProjectView, setOpenNewProjectView] = useState(true);
  const [activeStep, setActiveStep] = useState(
    unpublishedProject?.project_onboarding_step || 1
  );

  const curHr = new Date().getHours();

  let greeting = curHr < 17 ? "good_day" : "good_evening";
  let greeting_emoji = curHr < 17 ? "☀️" : "🌙";
  
  if (account.status === "loading" || organization.status === "loading") {
    return null;
  }
  return (
    <Layout.Container style={{ display: "flex", flexDirection: "column" }}>
      <UmojaHeader title={<Trans i18nKey="settings" />} user={account.data} />
      <Breadcrumb />
      <Layout.Container style={{ flex: 1 }}>
        <div className="settings__container" style={{ height: "100%" }}>
          <div className="settings__sidebar">
            <div style={{ padding: "20px" }}>
              <ButtonLink to="/dashboard/settings/account">
                <Trans i18nKey="your_account_settings" />
              </ButtonLink>
              {AtLeast.Admin(role) && (
                <ButtonLink to="/dashboard/settings/organization">
                  <Trans i18nKey="organization_settings" />
                </ButtonLink>
              )}
              {AtLeast.Organizer(role) && (
                <ButtonLink to="/dashboard/settings/project" >
                  <Trans i18nKey="project_management" />
                </ButtonLink>
              )}
              {AtLeast.Admin(role) && !account?.data?.demo && (
                <ButtonLink to="/dashboard/settings/wallet-groups" >
                  <Trans i18nKey="wallet_groups" />
                </ButtonLink>
              )}
            </div>
            <div style={{ position: "absolute", width:"250px", bottom:"0px" }}>
              <Card style={{ height: "100px", display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: 0, }}>
                <h3>Build Version</h3>
                <div className="build__version">
                  <div>UM SUITE: <span>{process.env.REACT_APP_VERSION}</span></div>
                  <div>BACKEND: <span>{apiVersion?.data?.api_version}</span></div>
                </div>
              </Card>
            </div>
          </div>
          <div className="settings__content">
            <div className="settings__card-container">
              <h1>
                {greeting_emoji} <Trans i18nKey={greeting} />{" "}
                {account.data.first_name}!
              </h1>

              <Switch>
                <Route
                  exact
                  path="/dashboard/settings"
                  component={() => <AccountSettings account={account} />}
                />
                <Route
                  path="/dashboard/settings/account"
                  component={() => <AccountSettings account={account} />}
                />
                {AtLeast.Organizer(role) && (
                  <Route
                    path="/dashboard/settings/organization"
                    component={() => (
                      <OrganizationSettings
                        organization={organization.data}
                        userRole={role}
                        account={account}
                      />
                    )}
                  />
                )}
                {AtLeast.Organizer(role) && (
                  <Route
                    path="/dashboard/settings/project"
                    component={() =>
                      <ProjectManagement
                        account={account}
                        setOpenNewProjectView={setOpenNewProjectView}
                        openNewProjectView={openNewProjectView}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        role={role}
                        latestPublishedProject={latestPublishedProject}
                        unpublishedProject={unpublishedProject}
                        project={project}
                      />
                  }
                  />
                )}
                <Route
                  path="/dashboard/settings/resources"
                  component={() => <ResourcesSettings />}
                />
                <Route
                  path="/dashboard/settings/wallet-groups"
                  component={() => <WalletGroup groups={groups} organizationId={organizationId} programId={project?.[0]?.id} />}
                />
                <Route
                  path="/dashboard/settings/*"
                  render={(props) => <Redirect to="/error" />}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Layout.Container>
    </Layout.Container>
  );
}
export default DashboardSettingsScreen;
