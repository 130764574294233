import { useQuery } from "react-query";
import { getOrganizationCards } from "../services";

const useCards = (organizationId: number | string) => {
  return useQuery(
    ["cards", organizationId],
    () => getOrganizationCards(organizationId),
    { initialData: [] }
  );
};

export default useCards;
