import "./UmojaIconStyles.css";

export type UmojaAvatarIconProps = {
  src?: string;
  alt: string;
  fallbackLetter: string;
  fallbackTextColor?: string;
  fallbackBgColor?: string;
  height?: number;
  width?: number;
};

export const UmojaAvatarIcon = ({
  src,
  alt,
  fallbackLetter,
  fallbackTextColor,
  fallbackBgColor,
  height = 28,
  width = 28,
}: UmojaAvatarIconProps) => {
  return (
    <div className="avatar__icon" style={{ height, width }}>
      {src ? (
        <img src={src} alt={alt} />
      ) : (
        <div
          style={{
            color: fallbackTextColor,
            backgroundColor: fallbackBgColor,
            verticalAlign: "middle",
          }}
        >
          {fallbackLetter}
        </div>
      )}
    </div>
  );
};

export default UmojaAvatarIcon;
