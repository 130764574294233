import { CSSProperties } from "react";
import "./Container.css";

type ContainerProps = {
  children?: React.ReactChild | React.ReactChild[];
  style?: CSSProperties;
};

function Container({ children, style }: ContainerProps) {
  return (
    <div className="ud-layout-container" style={style}>
      {children}
    </div>
  );
}

export default Container;
