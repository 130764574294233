import "./__progressBar.css";
import { Button, ProgressBar } from "@blueprintjs/core";
import { useRef, useState } from "react";
import { useField } from "react-form";
import { UmojaToast } from "../../../../../components/Popups/UmojaToast";
import { ExcelPreviewPanel } from "./ExcelPreviewPanel";
import { useTranslation } from "react-i18next";

type ProjectParticipantsProps = {
    disabled: boolean;
    programId: string;
};

export const ProjectParticipantsInput = (props: ProjectParticipantsProps) => {
    const { t } = useTranslation();
    const ProjectMerchantInputFieldInstance = useField("project_recipients", {
        // validate: validateInput,
        defaultIsTouched: false,
        defaultValue: "",
    });
    const [showPreview, setShowPreview] = useState(false);
    const [excelJson, setExcelJson] = useState<any>([]);
    const [file, setFile] = useState<File | null>(null);
  const [disable, setDisable] = useState(false);


  const { setValue } = ProjectMerchantInputFieldInstance;

    const ref = useRef<HTMLInputElement | null>(null);

  const handleClose = () => {
    ref.current!.value = ""
    setFile(null)
    localStorage.removeItem("recipient")
    setShowPreview(false)
    setDisable(false)
  }

    const onChange = async (event: any) => {
            const target = event.target as HTMLInputElement & EventTarget;
            const formData = new FormData();
            const { files } = target;
            if (!files || files.length < 1) {
                setValue("");
                return;
            }
            const [file] = Array.from(files);

            UmojaToast.show({
                message: <ProgressBar className="popover__progress" />,
                intent: "primary",
                icon: "upload",
            });

            /* Parse data */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = async e => {
                /* Update state */
                setShowPreview(true);
                setFile(file);
                //@ts-ignore
                setValue(file);

                localStorage.setItem('recipient', files[0].name);

                formData.append("project.recipients", file);

                // API call to handle preflight request
                const response = await fetch(
                  `${process.env.REACT_APP_CREATE_PROGRAM_ENDPOINT}/${props.programId}/file_preflight_validation` as string,
                  {
                      method: "POST",
                      headers: {
                          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                      },
                      body: formData,
                  }
                );

                // Get response from API call
                const res = await response.json();

                // set filename locally
                setExcelJson(res?.beneficiaries?.rows);

                UmojaToast.clear();
                UmojaToast.show({
                    message: `${t("participants_uploaded_successfully")}`,
                    intent: "success",
                    icon: "tick",
                });
            };
            reader.onerror = e => {
                UmojaToast.clear();
                UmojaToast.show({
                    message: `${t("error_uploading_excel_file")}`,
                    intent: "danger",
                    icon: "error",
                });

            };
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    return (
        <div style={{ marginLeft: "20px"}}>
          <div style={{ display: "flex", width: "300px"}}>
            <label className="bp3-file-input bp3-fill" >
              <input type="file" onChange={onChange} ref={ref} accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"} disabled={props.disabled || disable} />
              <span bp3-button-text={t('browse')} className="bp3-file-upload-input bp3-file-upload-input-custom-text">{file ? file.name: t("participant_group")}</span>
            </label>
            <Button text={t('clear')} style={{ marginLeft: "5px", display: disable ? "block": "none", width: "70px", justifyContent: "center"}} onClick={handleClose} />
          </div>
            <span>{file?.name}</span>
            <ExcelPreviewPanel
                isOpen={showPreview}
                onClose={handleClose}
                setShowPreview={setShowPreview}
                data={excelJson}
                file={file}
                setValue={setValue}
                userType="recipient"
                setDisable={setDisable}
            />
        </div>
    );
};

