import { HTMLSelect } from "@blueprintjs/core";
import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../../../components";




interface ProjectTypeInputProps {
  defaultValue: string;
  readOnly: boolean;
}

export const ProjectTypeInput = ({
  defaultValue,
  readOnly,
}: ProjectTypeInputProps) => {

  const { t } = useTranslation()

  const validateInput = (input: string) => {
  if (input.length < 1) {
    return t("project_type_validation");
  }
  return false;
};

  const project_types = [
    { label: t("humanitarian_relief"), value: "humanitarian_relief" },
    { label: t("development"), value: "development" },
    { label: t("microFinance"), value: "microFinance" },
    { label: t("private_sector"), value: "private_sector" },
  ]
  const ProjectTypeInputFieldInstance = useField("project_type", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: defaultValue || "",
  });

  const projectType = project_types.filter(d => d.value === defaultValue)

  const { meta, getInputProps } = ProjectTypeInputFieldInstance;
  if (readOnly) {
    return (
      <li className="settings__card-input-list-item">
        <label>
          <Trans i18nKey="project_type" />
        </label>
        <div style={{ height: 50, width: 200 }}>
          <TextInput
            type="text"
            placeholder={projectType[0]?.label}
            name="projectName"
            value={t(`${projectType[0]?.value}`)}
            readOnly={readOnly}
          />
          {meta.isTouched && (
            <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
              {/* {meta.error} */}
            </p>
          )}
        </div>
      </li>
    );
  }
  return (
    <li className="settings__card-input-list-item">
      <label>
        <Trans i18nKey="project_type" />
      </label>
      <div style={{ height: 50, width: 200 }}>
        <HTMLSelect
          {...getInputProps()}
          options={[
            { label: projectType[0]?.label || t('select_type'), value: "" },
            ...project_types,
          ]}
          required
          name="projectType"
        />
        {meta.isTouched && (
          <p style={{ fontSize: 10, color: "red", padding: 0, margin: 0 }}>
            {meta.error}
          </p>
        )}
      </div>
    </li>
  );
};


