import "./UmojaChartWidget.css";
import { ReactNode } from "react";
import {
  VictoryLine,
  VictoryLegend,
  VictoryGroup,
  VictoryAxis,
  PaddingProps,
  DomainPaddingPropType,
} from "victory";

export interface UmojaLineChartWidgetProps {
  title?: ReactNode;
  data: Record<string, string | number>[];
  currency: string;
  theme: any;
  padding: PaddingProps;
  domainPadding: DomainPaddingPropType;
  tickValues: any[];
  tickFormat: any[];
  axisPadding: PaddingProps;
}

export const UmojaLineChartWidget = ({
  title,
  data,
  currency,
  theme,
  padding,
  domainPadding,
  tickValues,
  tickFormat,
  axisPadding,
}: UmojaLineChartWidgetProps) => {
  if (!data.length) {
    return <div className="featured__charts-chart-area"></div>;
  }

  return (
    <div>
      <VictoryGroup
        theme={theme}
        data={data}
        padding={padding}
        domainPadding={domainPadding}
      >
        <VictoryLegend x={0} y={10} title={`${title}`} />
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={tickFormat}
          padding={axisPadding}
          fixLabelOverlap
        />
        <VictoryLine />
      </VictoryGroup>
    </div>
  );
};

export default UmojaLineChartWidget;
