import { useQuery } from "react-query";
import { getAverageWalletBalance } from "../services";

export default function useWalletAvgBalance(
  organizationId: string,
  projectId: string
) {
  return useQuery(
    ["avgWalletBalance", organizationId, projectId],
    () =>
      getAverageWalletBalance(parseInt(organizationId), parseInt(projectId)),
    {
      initialData: {},
      enabled: !!projectId && !!organizationId,
    }
  );
}
