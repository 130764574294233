import { Tab, Tabs } from "@blueprintjs/core";
// import { Trans } from "react-i18next";
import { ReactSVG } from "react-svg";

export interface UmojaPaymentWidgetFooterProps {
  mode: string;
  setMode: Function;
  disabled?: boolean;
}

export const UmojaPaymentWidgetFooter = ({
  mode,
  setMode,
  disabled,
}: UmojaPaymentWidgetFooterProps) => {
  const handlePanelChange = (panel: string | number) => {
    if (!disabled) {
      setMode(panel);
    }
  };

  return (
    <Tabs
      id="PaymentWidgetFooter"
      defaultSelectedTabId="wallet"
      onChange={(newTabId) => handlePanelChange(newTabId)}
      selectedTabId={mode}
      className="payment__widget-footer"
      large
    >
      <Tab
        id="wallet"
        title={<ReactSVG src="/wallet2.svg" />}
        className="payment__widget-footer_button_wrapper"
        disabled={disabled}
      />
      <Tab
        id="disburse"
        title={<ReactSVG src="/disburse.svg" />}
        className="payment__widget-footer_button_wrapper"
        disabled={disabled}
      />
      <Tab
        id="history"
        title={<ReactSVG src="/history.svg" />}
        className="payment__widget-footer_button_wrapper"
        disabled={disabled}
      />
    </Tabs>
  );
};
