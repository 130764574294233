import { useField } from "react-form";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "../../../components";


export const EmailAddressInput = () => {
  const { t } = useTranslation();

  function validateInput(email: string) {
  if (!email) {
    return t("email_required_validation");
  }
  if (!isValidEmail(email)) {
    return t("valid_email_required_validation");
  }
  return false;
}
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField("email", {
    validate: validateInput,
    defaultIsTouched: false,
    defaultValue: "",
  });

  return (
    <li className="organization__form-input-list-item">
      <label>
        <Trans i18nKey="email_address" />
      </label>
      <div className="input_container">
        <TextInput
          {...getInputProps()}
          type="email"
          placeholder="info@example.com"
          name="Admin-email"
           className="input_field"
        />
        {isTouched  && (
          <p className="input_error_wrapper">
            {error}
          </p>
        )}
        </div>
    </li>
  );
};



// https://codesandbox.io/s/react-form-demo-q9mgm?file=/src/index.js:4838-5082
function isValidEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
