import { useForm } from "react-form";
import { useHistory } from "react-router-dom";
import { Card } from "@blueprintjs/core";

import { Button, UmojaAlert } from "../../components";
import { EmailInput } from "./inputs/EmailInput";
import { PasswordInput } from "./inputs/PasswordInput";
import { useMemo, useState } from "react";
import { UmojaToast } from "../../components/Popups/UmojaToast";
import { Trans, useTranslation } from "react-i18next";

export const LoginForm = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(
    () => ({
      credentials: {
        email: "",
        password: "",
      },
    }),
    []
  );

  const LoginFormInstance = useForm({
    onSubmit: async (values, instance) => {
      setLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_LOGIN_ENDPOINT as string,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        const json = await response.json();
        if (json.error === "DEACTIVATED") {
          setLoading(false);
          UmojaToast.show({
            message: t("account_deactivated_error"),
            intent: "danger",
            icon: "error",
          });
          return
        }
        if (!json.access_token) {
          setLoading(false);
          UmojaToast.show({
            message: `${t("invalid_credentials_error")}`,
            intent: "danger",
            icon: "error",
          });
          return;
        }
        localStorage.setItem("access_token", json.access_token);
        setLoading(false);
        history.push("/dashboard");
      } catch (error) {
        
        UmojaToast.show({
          message: `${t("signin_error")}`,
          intent: "danger",
          icon: "error",
        });
        setLoading(false);
      }
    },
    defaultValues,
    // debugForm: true,
  });

  let emailValue = LoginFormInstance.getFieldValue("credentials.email");
  let passwordValue = LoginFormInstance.getFieldValue("credentials.password");

  const { meta } = LoginFormInstance;

  return (
    <Card className="login__card">
      {error && (
        <UmojaAlert
          handleClose={() => setError("")}
          cta="Close"
          message={error}
          intent="danger"
          icon="issue"
        />
      )}
      <LoginFormInstance.Form className="login__card-form">
        <h1>
          <Trans i18nKey="sign_into_your_account" />
        </h1>
        <div>
          <div className="login__card-input_label">{`${t('your_email')}`}</div>
          <EmailInput />
        </div>
        <div>
          <div className="login__card-input_label">{`${t('password')}`}</div>
          <PasswordInput />
        </div>
        <div className="forgot-password__link-wrapper">
          <p
          className="forgot-password__link"
          onClick={() => props.setForgotPasswordState(true)}
          >
            {`${t('forgot_password')}`}
          </p>
        </div>
        <div className="login__card-input-wrapper">
          <Button
            className="login__button"
            type="submit"
            minimal
            disabled={
              !(meta.canSubmit && meta.isTouched && passwordValue && emailValue)
            }
            loading={loading}
            text={`${t('sign_in')}`}
            intent="primary"
          />
        </div>
      </LoginFormInstance.Form>
    </Card>
  );
};
