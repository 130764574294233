import { Button } from "@blueprintjs/core";
import { ReactSVG } from "react-svg";

export interface UmojaPaymentWidgetHeaderProps {
  mode: string;
  setMode: Function;
  disabled?: boolean;
  headerTitle: string;
}

export const UmojaPaymentWidgetHeader = ({
  mode,
  setMode,
  disabled,
  headerTitle
}: UmojaPaymentWidgetHeaderProps) => {
  const handlePanelChange = (panel: string | number) => {
    if (!disabled) {
      setMode(panel);
    }
  };

  return (
    <div className="payment__widget-header" onClick={() => handlePanelChange(mode)}>
      <Button minimal>
        <ReactSVG src="/backCaret.svg" />
      </Button>
      <div className="payment__widget-header--title">{headerTitle}</div>
    </div>
  );
};
