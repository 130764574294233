import { Card } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../components/Icons/arrow.svg";

interface ResourceSettingCardProps {
  title: string;
  resourceLink: {
    name: string;
    link: string;
  }[];
}

export const ResourceSettingCard = ({
  title,
  resourceLink
}: ResourceSettingCardProps) => {
  return (
    <Card className="setting__resources-card-wrapper">
      <h2 className="setting__resources-card-title">{title}</h2>

      {resourceLink.map((resource) => (
      <Link
        to={{ pathname: resource.link}}
        className="setting__resources-card__link-options"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="setting__resources-card__link">{resource.name}</p>

        <Arrow  className="setting__resources-card__arrow-icon"/>
      </Link>))}
    </Card>

  );
};
